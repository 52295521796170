.payment {
    max-width: 1170px;
    width: 100%;
    margin: 70px auto;
    display: flex;
    gap: 30px;
}

.payment-heading {
    border: solid 1px #e0e0e0;
    background-color: #f4f4f4;
    padding: 18px 22px;
    font-size: 20px;
}

.payment-method {
    flex: 670px;
    height: 100%;
}

.payment-order {
    flex: 470px;
}

.payment-method,
.payment-order {
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(131, 134, 163, 0.17);
    background-color: #ffffff;
    font-size: 20px;
    color: #0C4767;
    overflow: hidden;
}

.payment .tag-button-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 20px;
    border-bottom: 1px solid #E0E0E0;
}

.cards-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cards-icons .icon {
    cursor: pointer;
    padding-right: 10px;
}

.cards-icons .icon img {
    width: 48px;
    height: 30px;
}

.tag-button-label input[type=radio] {
    display: none;
}

.tag-button-label input[type=radio]:checked+.tag-button .unchecked {
    display: none;
}

.tag-button-label input[type=radio]:not(:checked)+.tag-button .checked {
    display: none;
}

.tag-button-label .tag-button {
    width: auto;
    height: 100%;
    display: inline-flex;
    justify-content: space-between;
    cursor: pointer;
    color: #0C4767;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
}

.tag-text {
    padding-top: 3px;
}

form .card-details {
    padding: 20px 80px 65px;
}

form .card-details input {
    width: 100%;
    height: 52px;
    font-size: 16px;
    font-weight: 500;
}

.form-three-inputs {
    display: grid;
    grid-template-columns: 48% 23% 23%;
    grid-gap: 15px;
}

.payment-order .payment-details {
    border-top: 1px solid #E0E0E0;
    margin: 0px 20px;
}

.payment-details .payment-details-btns {
    display: flex;
    margin: 20px 0px;
    grid-gap: 20px;
}

.payment-details-btns button.active {
    box-shadow: 0 6px 10px 0 rgba(12, 71, 103, 0.22);
    background-color: #0c4767;
    color: white;
}

.payment-details-btns button {
    width: 100% !important;
    background-color: white;
    border: solid 1px #e0e0e0;
    color: #626262;
    box-shadow: none;
    font-weight: 500;
    font-size: 14px;
    padding: 17px 0;
    border-radius: 5px;
}

.payment-details-heading {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    img {
        max-width: 45px;
        margin-right: 10px;
    }
}

.payment-details-heading h1 {
    color: #FE9920;
    font-size: 17px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.payment-order .radios {
    margin: 10px 0px 20px;
}

.payment-order .tag-button-label {
    border: 0;
    padding: 0px !important;
    margin: 12px 0px;
}

.payment-order .tag-icon {
    padding-bottom: 23px;
}

.payment-order .tag-button h1 {
    font-size: 16px;
    padding: 0;
    margin: 0;
}

.payment-order .tag-button p {
    font-size: 12px;
    color: #626262;
    padding: 0;
    margin: 0;
    padding-top: 5px;
}

.payment-order .tag-button-texts img {
    padding-left: 12px;
    max-width: 25px;
}

.payment-order .amount p {
    font-size: 20px;
    padding: 0;
    margin: 0;
    @media (max-width: 767px) {
        font-size: 16px;
    }
}

.payment-order .coupon-code {
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    padding: 0px 0px 15px 0px !important;
}

.payment-order .coupon-code-input {
    display: flex;
    gap: 15px;
}

.payment-order .coupon-code label {
    font-size: 16px;
}

.payment-order .coupon-code-input input {
    border-radius: 5px;
    height: 52px;
}

.submit-section .total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.submit-section .total h1 {
    font-size: 16px;
}

.submit-section .total p {
    font-size: 12px;
    color: #626262;
    margin: 0
}

.submit-section .total .total-amount {
    font-size: 20px;
    color: #FD9926;
    margin: 0;
}

.submit-section .query p {
    font-size: 16px;
    color: #626262;
    text-align: center;
    margin: 5px 0px 30px;
}

.submit-section .query p a {
    color: #0C4767;
}


/*TOGGLE*/

.toggle-section {
    display: flex;
    align-items: center;
    padding: 0px 20px;
}

.toggle-section p {
    color: #0C4767;
    font-size: 16px;
    margin: 0;
}

.toggle-section p:last-child {
    color: #626262;
}

.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 24px;
    margin: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: #FE9920;
    -webkit-transition: .4s;
    transition: .4s;
}

.payment-order input:checked+.slider {
    background-color: rgba(254, 153, 32, .25);
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/*****RESPONSIVE REQUIREMENTS*****/

@media screen and (max-width: 1220px) {
    .payment {
        display: block;
        padding: 0 15px;
        margin: 20px auto;
    }
    .payment-method {
        height: 563px;
    }
    .payment-order {
        margin-top: 25px;
    }
    form .card-details {
        padding: 20px 15px;
    }
}

@media screen and (max-width: 650px) {
    .cards-icons .icon img {
        width: 29px;
        height: 18px;
    }
    .tag-text {
        font-size: 13px;
    }
    .payment-order .tag-button p {
        padding-top: 2px;
    }
    .payment-order .coupon-code {
        padding: 0 0 20px 0 !important;
    }
    .submit-section .submit-btn button {
        padding: 17px 0;
        margin: 10px 0;
    }
    .submit-section .query p {
        text-align: start;
        margin: 5px 0px 20px;
    }
}