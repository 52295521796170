/*
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Sofia Pro Reg" !important;
}

img {
  width: 100%;
  height: auto;
}
*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    margin: 0;
    padding: 0;
}

a,
a:hover,
button,
button:hover {
    transition: 0.3s all ease;
    text-decoration: none;
}

@font-face {
    font-family: "Sofia Pro Light";
    src: url("../fonts/sofia-pro/SofiaProLight.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Sofia Pro Reg";
    src: url("../fonts/sofia-pro/SofiaProRegular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Sofia Pro Reg Italic";
    src: url("../fonts/sofia-pro/SofiaProRegular-Italic.ttf");
    font-style: italic, oblique;
}

@font-face {
    font-family: "Sofia Pro medium";
    src: url("../fonts/sofia-pro/SofiaProMedium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Sofia Pro Bold Italic";
    src: url("../fonts/sofia-pro/SofiaProBold-Italic.ttf");
    font-weight: bold;
    font-style: italic, oblique;
}

@font-face {
    font-family: "Sofia Pro Bold";
    src: url("../fonts/sofia-pro/SofiaProBold.ttf");
    font-weight: bold;
}

// Declaring Variables
$ui-font-light: "Sofia Pro Light";
$ui-font-regular: "Sofia Pro Reg";
$ui-font-medium: "Sofia Pro medium";
$ui-font-bold: "Sofia Pro Bold";
$ui-blue: #0c4767;
$ui-brand: #fe9920;
$blue-btn-shadow: 0 6px 10px rgba(12,
71,
103,
0.22);
$ui-grey-border: #e0e0e0;
// Page Header
header nav.calender-integ {
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding: 12px 27px;
    @media (max-width: 767px) {
        padding: 12px 15px;
        .sidebar-toggler {
            width: 11%;
            border: none;
            background: transparent;
            span {
                background: $ui-blue;
                display: block;
                height: 2.36px;
                border-radius: 10px;
                width: 77%;
                margin-right: auto;
                &:nth-of-type(2) {
                    margin: 5px auto;
                    width: 76%;
                }
            }
        }
        a.navbar-brand {
            margin-right: 0;
            img {
                max-width: 140px;
                padding: 0;
            }
        }
    }
    .nav-right-component {
        justify-content: space-between;
        align-items: center;
        button {
            padding: 0;
            &:focus {
                box-shadow: none;
            }
        }
        .noti-bell {
            button {
                position: relative;
                font-size: 20px;
                &::after {
                    display: none;
                }
            }
            button.is-notify {
                &::before {
                    content: "";
                    position: absolute;
                    border-radius: 50%;
                    background: $ui-brand;
                    margin: auto;
                    width: 6px;
                    height: 6px;
                    bottom: 0;
                    right: 0;
                    top: -11px;
                }
            }
            .dropdown-menu {
                left: unset;
                right: 0;
            }
        }
        .profile-btn {
            padding: 0 10px 0 19px;
            margin-left: 25px;
            position: relative;
            @media (max-width: 767px) {
                padding: 0;
                p {
                    i {
                        font-size: 30px;
                    }
                }
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 1.5px;
                height: 17px;
                background: #000;
                @media (max-width: 767px) {
                    display: none;
                }
            }
            button {
                text-align: left;
                &::after {
                    height: fit-content;
                    position: absolute;
                    right: -10px;
                    margin: auto;
                    top: 0;
                    bottom: -20px;
                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }
            .img {
                background: #fff;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                border-radius: 50%;
                margin-right: 7px;
                img {
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                }
            }
            .profile-img {
                .name {
                    p {
                        font-size: 14px;
                        line-height: 1;
                        font-family: $ui-font-bold;
                    }
                    span {
                        font-size: 12px;
                        line-height: 1;
                        display: block;
                        margin-top: 4px;
                    }
                }
            }
            .dropdown-menu {
                left: unset;
                right: -22px;
                @media (max-width: 767px) {
                    right: -16px;
                }
            }
        }
    }
}

// Main Section
main {
    margin: 20px 0 0;
    padding-bottom: 30px;
    position: relative;
    .main-row {
        min-height: calc(100vh - 146px);
    }
    // SideBar
    aside {
        position: absolute;
        top: 0;
        left: 15px;
        width: fit-content;
        height: 98%;
        background-color: #fff;
        border-radius: 5px;
        padding: 31px 15px 10px;
        z-index: 99;
        transition: 0.3s all ease;
        width: 85px;
        overflow-x: hidden;
        @media (max-width: 767px) {
            left: 0;
            top: -20px;
            width: 0;
            height: calc(100vh - 86px);
            padding: 30px 0 10px;
            transition: 0.3s all ease;
        }
        .calender-sidebar {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        ul {
            list-style: none;
            li {
                a {
                    font-size: 25px;
                    text-align: center;
                    color: $ui-blue;
                    padding: 12px 13px;
                    margin-bottom: 17px;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    @media (max-width: 767px) {
                        width: calc(100vw - 30px);
                    }
                    svg {
                        width: 25px;
                        vertical-align: unset;
                        path {
                            fill: #0c4767;
                        }
                    }
                    span {
                        margin-left: 10px;
                        font-size: 16px;
                        color: $ui-blue;
                        transition: 0.3s all ease;
                        @media (min-width: 768px) {
                            display: none;
                        }
                    }
                }
                a.calendar {
                    font-size: 32px;
                }
                a.workflow {
                    img {
                        max-width: 29px;
                        &:last-of-type {
                            display: none;
                        }
                    }
                    &:hover {
                        img {
                            &:first-of-type {
                                display: none;
                            }
                            &:last-of-type {
                                display: inline-block;
                            }
                        }
                    }
                }
                a.active,
                a:hover {
                    background: $ui-brand;
                    color: #fff;
                    span {
                        color: #fff;
                        font-family: $ui-font-medium;
                        transition: 0.3s all ease;
                    }
                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }
        @media (min-width: 768px) {
            &:hover {
                transition: 0.3s all ease;
                width: 212px;
                ul {
                    li {
                        a {
                            width: 180px;
                            transition: 0.3s all ease;
                            span {
                                display: block;
                                transition: 0.3s all ease;
                            }
                        }
                    }
                }
            }
        }
        ul.logout {
            li {
                a {
                    margin: 0;
                    background: transparent !important;
                    &:hover {
                        color: $ui-blue !important;
                    }
                    img {
                        max-width: 29px;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        aside.is-show {
            // display: block;
            padding: 31px 15px 10px;
            width: 100%;
            transition: 0.3s all ease;
        }
    }
}

// Calender Integretion
section.calend-integ {
    margin-left: auto;
    @media (min-width: 768px) {
        width: 85%;
    }
    @media (min-width: 992px) {
        width: 89%;
    }
    @media (min-width: 1200px) {
        width: 91%;
    }
    @media (min-width: 1330px) {
        width: 92%;
    }
    @media (min-width: 1500px) {
        width: 93%;
    }
    .page-title {
        background: #f4f4f4;
        border: 1px solid $ui-grey-border;
        border-radius: 5px;
        padding: 26px 17px;
        h1 {
            font-size: 28px;
            font-family: $ui-font-bold;
            color: $ui-blue;
            font-size: 17px;
        }
    }
    // Calender Clients and options
    .calender-clients {
        margin: 24px 0 0;
        padding: 0 8px 0;
        h2 {
            color: $ui-blue;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 18px;
        }
        [class*="calender-client-"] {
            position: relative;
            margin-bottom: 26px;
            input {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
            input:checked+label {
                border: 2px solid $ui-blue;
            }
            label {
                display: block;
                text-align: center;
                background: #fff;
                border-radius: 5px;
                padding: 31px 10px;
                min-height: 238px;
                border: 2px solid transparent;
                @media (max-width: 767px) {
                    min-height: 175px;
                }
                img {
                    max-width: 80px;
                    @media (max-width: 767px) {
                        max-width: 50px;
                    }
                }
                span {
                    display: block;
                    font-size: 32px;
                    margin: 23px 0 0;
                    color: #0c4767;
                    @media (max-width: 767px) {
                        font-size: 24px;
                    }
                }
            }
        }
        .calender-client-apple {
            label {
                img {
                    max-width: 130px;
                    @media (max-width: 767px) {
                        max-width: 72px;
                    }
                }
            }
        }
        .calender-client-office {
            label {
                img {
                    max-width: 67px;
                    @media (max-width: 767px) {
                        max-width: 40px;
                    }
                }
            }
        }
        .calender-client-exchange {
            label {
                img {
                    max-width: 89.5px;
                    @media (max-width: 767px) {
                        max-width: 54px;
                    }
                }
            }
        }
        .calender-client-outlook {
            label {
                cursor: not-allowed;
                pointer-events: none;
                img {
                    max-width: 96.34px;
                    @media (max-width: 767px) {
                        max-width: 54px;
                    }
                }
                @media (max-width: 767px) {
                    span {
                        color: #7d7d7d;
                    }
                }
                span.label {
                    font-size: 12px;
                    margin: 0;
                }
            }
        }
    }
    .sub-btns {
        margin-top: 14px;
        button {
            background-color: $ui-blue;
            color: #fff;
            font-family: $ui-font-medium;
            margin-left: 23px;
            padding: 13px 23px;
            box-shadow: $blue-btn-shadow;
        }
    }
}

//=================================== All Integrations Page ========================================
@media (min-width: 768px) {
    body.all-party-integretions {
        aside {
            height: 96%;
        }
    }
}

section.integreted-calenders {
    .bg-div {
        background: #fff;
        border-radius: 5px;
        padding: 17px 19px;
        @media (max-width: 767px) {
            padding: 17px 0;
        }
    }
    @media (min-width: 768px) {
        .temp-row.d-flex {
            min-height: calc(100vh - 230px);
        }
    }
    .section-title {
        @media (max-width: 767px) {
            flex-direction: column;
        }
        h2 {
            font-size: 24px;
            font-weight: 700;
            color: $ui-blue;
            @media (max-width: 767px) {
                margin-bottom: 20px;
            }
            span {
                font-size: 16px;
                font-family: $ui-font-light;
                margin-left: 2px;
                span {
                    color: $ui-brand;
                    margin: 0;
                }
            }
        }
    }
    .btn-calender-account {
        color: #fff;
        font-size: 16px;
        background: $ui-blue;
        font-family: $ui-font-medium;
        box-shadow: $blue-btn-shadow;
        padding: 16px 35px;
        line-height: 1;
    }
    .active-calenders {
        margin: 31px 0 0;
    }
    .calender-party-col {
        background: #fff;
        border-radius: 5px;
        border: 1px solid $ui-grey-border;
        padding: 24px 12px 27px 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 767px) {
            // flex-direction: column;
            align-items: flex-start;
            padding: 24px 10px 27px 10px;
        }
        .calender-party-details {
            display: flex;
            @media (max-width: 767px) {
                margin-bottom: 20px;
                align-items: flex-start;
            }
            img {
                max-width: 83px;
                @media (max-width: 767px) {
                    max-width: 26px;
                    margin: 6px 8px 0 0;
                }
            }
            .calender-name {
                margin-left: 20px;
                @media (max-width: 767px) {
                    margin: 0;
                }
                p {
                    line-height: 1;
                    font-weight: 400 !important;
                    font-size: 18px;
                    color: #b0b0b0;
                    @media (max-width: 767px) {
                        word-break: break-word;
                        font-size: 15px;
                    }
                    @media (max-width: 350px) {
                        font-size: 14px;
                    }
                    span {
                        margin: 0 0 0 8px;
                    }
                }
                ul {
                    padding-left: 20px;
                    li {
                        margin-top: 5px;
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                }
                span {
                    line-height: 1;
                    color: #326483;
                    font-size: 16px;
                    margin: 12px 0;
                    display: inline-block;
                    @media (max-width: 767px) {
                        margin: 8px 0;
                        font-size: 14px;
                    }
                    @media (max-width: 350px) {
                        font-size: 14px;
                    }
                }
            }
        }
        .btn-diconnect {
            border: 0.5px solid #707070;
            color: $ui-blue;
            padding: 4px 18px 6px;
            img {
                width: 13px;
                margin-left: 2px;
            }
        }
    }
}

//=================================== Check Conflicts Template ========================================
body.check-conflicts-template {
    section.integreted-calenders {
        .bg-div {
            padding-bottom: 1px;
            .calender-party-col {
                .calender-party-details {
                    img {
                        max-width: 56px;
                        @media (max-width: 350px) {
                            max-width: 40px;
                        }
                    }
                }
            }
        }
    }
}

section.check-conflicts-sec {
    margin: 22px 0 0;
    .bg-div {
        padding-bottom: 20px !important;
    }
    .border-row {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        @media (max-width: 1199px) {
            padding-top: 20px;
        }
        .active-calenders {
            margin-top: 18px;
        }
    }
    .check-conflict-col {
        h2 {
            font-size: 20px;
            font-family: $ui-font-bold;
            color: $ui-blue;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            font-family: $ui-font-regular;
            color: #326483;
            line-height: 1;
        }
    }
    .modal {
        .modal-header {
            padding-top: 21px;
            h2 {
                margin-bottom: 22px;
            }
            span {
                img {
                    margin-right: 11px;
                    max-width: 28px;
                }
            }
        }
        .modal-body {
            padding: 19px 20px 19px 47px;
            @media (max-width: 767px) {
                padding-left: 20px;
            }
            h3 {
                color: $ui-blue;
                font-size: 18px;
                font-family: $ui-font-bold;
                margin-bottom: 24px;
            }
        }
        .form-check {
            margin-bottom: 7px;
            label {
                margin-left: 7px;
                font-size: 16px;
                color: #326483;
            }
        }
        .modal-footer {
            border: none;
            padding-top: 0;
            padding-bottom: 29px;
            button {
                width: 100%;
                max-width: 190px;
                font-size: 16px;
                font-family: $ui-font-bold;
                line-height: 1;
                padding: 16px 10px;
            }
            .btn-cancel {
                background: #ececec;
                border-radius: 5px;
                border: 0.5px solid #707070;
                color: #7d7d7d;
            }
            .btn-update {
                background: $ui-brand;
                color: #fff;
            }
        }
    }
    .addcal-modal {
        .modal-header {
            padding-left: 50px;
            padding-bottom: 7px;
            align-items: flex-start;
            border: none;
            @media (max-width: 767px) {
                padding-left: 20px;
            }
            h2 {
                max-width: 375px;
                margin-bottom: 16px;
            }
        }
        .modal-body {
            background: #f4f4f4;
            h3 {
                margin-bottom: 13px;
            }
            select {
                width: 93%;
                height: 54px;
                margin-bottom: 23px;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        .modal-footer {
            background: #f4f4f4;
        }
    }
}