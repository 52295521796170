.rbc-month-row {
  min-height: 110px;
}

.rbc-event {
  /*background-color: var(--main_orange) !important;*/
  font-family: "Sofia Pro";
}

.rbc-show-more {
  /*color: var(--main_orange) !important;*/
}

.rbc-btn-group {
  button {
    height: 55px;
    font-family: "Sofia Pro";
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    box-shadow: none;
    text-decoration: none;
    background-color: var(--main_orange) !important;
    color: white !important;
  }
}
