// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../core/variables/components-variables";

.dropzone {
  min-height: 100px;
  border: 2px dashed $primary;
  background: $body-bg;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: 0;
  }
}
.dz-thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.thumb-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.dz-thumb-inner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}
.dz-img {
  display: block;
  width: 100%;
  height: 100%;
}
