.profile-step .by-step {
    background-image: url('../images/border.png');
    background-repeat: no-repeat;
    background-size: 90%;
    padding-top: 45px;
    background-position: center;
    display: flex;
    justify-content: space-between;
}

.profile-step .complete-steps {
    background-image: url("../images/filled-border.png");
    background-repeat: no-repeat;
    padding-top: 45px;
    background-position: center;
    display: flex;
    justify-content: space-between;
}

.profile-step .step .current-step {
    color: #FD9926
}

.profile-step {
    // width: 870px;
    padding: 50px 0;
    margin: 50px auto;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(131, 134, 163, 0.17);
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-step .step-1 {
    text-align: center;
    width: 100%;
    @media (max-width: 767px) {
        padding: 0 20px !important;
    }
}

.profile-step form {
    width: 100%;
}

.profile-step h1 {
    color: #0C4767;
    font-weight: bold;
    font-size: 40px;
}

.profile-step p {
    font-size: 20px;
    color: #626262;
}

.profile-step .company-logo-input {
    display: flex;
}

.profile-step .company-logo-input input {
    border-radius: 0 !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.company-logo .btn {
    width: auto;
    height: 66px;
    display: flex;
    align-items: center;
    background-color: #F4F4F4;
    color: #626262;
    border-radius: 0 !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    padding: 0px 25px;
    border: solid 1px #e0e0e0;
}

.profile-step .submit-btns {
    display: flex;
    gap: 20px;
    margin: 40px 0px 20px 0px;
}

.profile-step .submit-btns button {
    width: 100% !important;
    border-radius: 5px;
    border: solid 1px #0c4767;
    background-color: #ffffff;
    padding: 20px 0;
}

.profile-step .submit-btns button a {
    color: #626262
}

.profile-step .submit-btns .next {
    background-color: #FD9926;
    box-shadow: 0 6px 10px 0 rgba(253, 153, 38, 0.22);
    border: 0;
    color: white;
    font-size: 16px;
}

.profile-step .submit-btns .next a {
    color: white
}

.profile-step .submit-btns .skip-btn {
    width: 100%;
}

.profile-step .submit-btns .last-step {
    width: 100%;
}

@media screen and (max-width: 950px) {
    .profile-step {
        width: 100%;
        box-shadow: none;
        padding: 0 15px;
    }
}

@media screen and (max-width: 650px) {
    .profile-step .by-step {
        padding-top: 35px
    }
    .profile-step h1 {
        font-size: 28px;
    }
    .profile-step p {
        font-size: 16px !important;
    }
    .profile-step .submit-btns {
        gap: 15px;
    }
    .profile-step .submit-btns button {
        padding: 17px 0;
    }
    .profile-step .submit-btns button:first-child {
        padding: 17px 2px;
    }
}