body {
    background: #7d7d7d08;
}

.top_nav {
    background: white;
    margin-bottom: 6px;
}


/* .main_noti {
  padding: 0px !important;
  background: white;
  margin-bottom: 9px;
}

.noti_side {
  background-color: #ffebd2;
} */

.sub_div {
    margin: 0px;
    margin-top: 5px;
}

.events_name {
    margin: 12px 0;
}

.events_name>h2 {
    font-size: 20px;
    color: #0c4767;
}

.main_add {
    background: white;
    padding: 0px;
    border-right: 12px solid #fafafa;
    margin-top: 15px;
}

.add_buttons {
    margin-top: 10px;
    margin-bottom: 15px;
}

.btn-primary {
    width: 89%;
    height: 95%;
    background: #f4f4f4;
    border-color: #f4f4f4;
    color: black;
}

.btn-primary:hover {
    background: #0c9aa3;
    border-color: #0c9aa3;
}

.btn-default {
    background: #f4f4f4;
    margin-left: -40px;
    height: 52px;
    width: 125px;
}

.btn-default>img {
    margin-right: 5px;
}

@media (min-width: 320px) {
    .noti_side {
        text-align: center;
    }
    .main_add .add_pic {
        width: auto;
    }
    .events_name>h2 {
        text-align: center;
    }
    .sechulde_d {
        font-size: 13px;
        margin-top: 3px;
    }
    .btn-default {
        background: #f4f4f4;
        margin-left: -40px;
        height: 44px;
        width: 105px;
    }
    .main_add {
        background: white;
        padding: 0px;
        border-right: 12px solid #fafafa;
        margin-top: 15px;
        margin-left: 8px;
    }
}

@media (min-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
    .main_add {
        background: white;
        padding: 0px;
        border-right: 12px solid #fafafa;
        margin-top: 15px;
        /*max-width: 48%; */
    }
    .main_add .add_pic {
        width: 100%;
    }
}

@media (min-width: 961px) {
    .main_add .add_pic {
        width: 100%;
    }
    .events_name>h2 {
        text-align: left;
    }
    .sechulde_d {
        font-size: 14px;
        margin-top: 2px;
    }
    .btn-default {
        background: #f4f4f4;
        margin-left: -40px;
        height: 44px;
        width: 125px;
    }
    .main_add {
        background: white;
        padding: 0px;
        border-right: 12px solid #fafafa;
        margin-top: 15px;
        margin-left: 0px;
    }
}

@media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
    /* hi-res laptops and desktops */
}