// Colors
$brandPrimary: #FE9920;
$brandPrimaryHov: #F29224;
$brandDark: #0C4767;
$brandLightDark: #59849C;
$brandWhite: #FFFFFF;
$brandOffWhite: #FFF5E8;
$brandLight: #F4F4F4;
$brandDisable: #E0E0E0;
$brandErrClr: #FD2626;
$brandSucsClr: #45C48A;