.exampleSuperAdmin {
    width: 400px;
    height: 500px;
  
    .contentSuperAdmin {
     // background: green;
      width: 800px;
      height: 480px;
    }
  }

  .BooingSalesSuperAdmin{
    height: 450px;
  }

  .TopPortionSuperAdmin{
    height: 300px;
  }

  .SbLeft{
    height: 960px;
  }