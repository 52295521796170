.meeting-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 90px !important;
}
.meeting-details h1 {
  font-size: 40px;
  color: #0c4767;
  font-weight: bold;
  text-align: start;
}
.meeting-details p {
  font-size: 20px;
  color: #626262;
  padding-top: 10px;
  font-weight: 500;
}
