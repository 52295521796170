// Create Components Mixins
@mixin btnStyle($borderClr, $bgClr, $txtClr, $disbBordClr, $disBgClr, $distxtClr, $hovBgClr, $hovBordClr, $hovtxtClr) {
    font-size: 16px !important;
    font-weight: 500 !important;
    border: 1px solid $borderClr !important;
    background-color: $bgClr !important;
    color: $txtClr !important;
    border-radius: 5px !important;
    padding: 14px 15px !important;
    text-align: center;
    transition: .3s all ease;
    &:disabled {
        pointer-events: none !important;
        border-color: $disbBordClr !important;
        background-color: $disBgClr !important;
        color: $distxtClr !important;
    }
    &:hover {
        transition: .3s all ease;
        background-color: $hovBgClr !important;
        border-color: $hovBordClr !important;
        color: $hovtxtClr !important;
    }
}