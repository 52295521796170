@import "../fonts/sofia-pro/index.css";
@import "./ribbash/variables.scss";
@import "./ribbash/mixins.scss";
// Resetting Behavior
* {
    box-sizing: border-box;
}

body,
html {
    overflow-x: hidden;
}

body {
    font-family: "Sofia Pro", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
    margin: 0;
    padding: 0;
    font-family: "Sofia Pro", sans-serif !important;
}

img {
    width: 100%;
    height: auto;
}

input:not([type="checkbox"], [type="radio"]),
select,
textarea {
    height: 54px !important;
    background: #fff;
    border: 1px solid $brandDisable;
    color: #818181;
    padding: 16px 16px !important;
    &:disabled {
        pointer-events: none;
        background: $brandLight;
        color: #B8B8B8;
    }
    &:focus {
        border-color: $brandDark !important;
        color: $brandDark !important;
        box-shadow: none !important;
    }
    &.errState {
        border-color: $brandErrClr;
        color: $brandErrClr;
    }
    &.successState {
        border-color: $brandSucsClr;
        color: $brandSucsClr;
    }
}

select {
    padding: 13px 16px !important;
    -webkit-appearance: auto !important;
    background-image: none !important;
    // -moz-appearance: none !important;
    // background-image: url(../icons/select_arrow.svg) !important;
    // background-position: 97% 50% !important;
    // background-size: 10px !important;
    // background-repeat: no-repeat !important;
    @media (max-width: 767px) {
        padding: 9px 12px !important;
        font-size: 14px !important;
    }
}

input[type="radio"] {
    border-color: #626262 !important;
    width: 16px;
    height: 16px;
    &+label {
        color: #626262 !important;
        @media (max-width: 767px) {
            font-size: 14px !important;
        }
    }
    &:checked {
        background-image: url('data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%272%27 fill=%27%23fe9920%27/></svg>') !important;
        background-color: transparent !important;
        border-color: $brandPrimary !important;
        &+label {
            color: $brandDark !important;
        }
    }
}

input[type="checkbox"] {
    width: 16px;
    height: 16px;
    &+label {
        font-size: 16px;
        @media (max-width: 767px) {
            font-size: 14px !important;
        }
    }
}

textarea {
    height: auto;
}

// Appy Font Sizes
h1 {
    font-size: 40px;
    font-weight: 700;
}

h2 {
    font-size: 26px;
    font-weight: 500;
    @media (max-width: 767px) {
        font-size: 20px;
    }
}

h3 {
    font-size: 20px;
    font-weight: 400;
}

h4 {
    font-size: 18px;
    font-weight: 400;
}

p {
    font-size: 16px;
}

p.small {
    font-size: 14px;
}

.password-group img {
    max-width: 34px;
}