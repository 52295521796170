@import "../scss/ribbash/variables.scss";
// Variables
$primarycolor: white;
$fontcolor: #0c4767;
$subfontcolor: #0c4767;
// Page Css
body {
    background: #7d7d7d08;
}

// Sidebar
.side_navbar {
    background: $primarycolor;
    padding: 0px;
    align-items: flex-start;
    height: 100%;
    border-radius: 5px;
    background-image: url(../fe-images/side_nav_bg.png);
    // background-size: 100% 100%;
    // background-repeat: no-repeat;
}


/* .sidebar_main{
    max-width: 28.333333%!important;
} */

.side_bar_ul {
    width: 100%;
}

.side_bar_ul>li {
    padding: 25px 20px;
    width: 100%;
    border-bottom: 1px solid $brandDisable;
    &.logo {
        padding-bottom: 0;
        border-bottom: 0;
        img {
            max-width: 185px;
        }
    }
    &.comp_logo {
        img {
            max-width: 185px;
        }
    }
    &.featured {
        img {
            min-height: 200px;
            object-fit: cover;
            border-radius: 5px;
        }
    }
}

.nav-item>h2 {
    font-size: 20px;
    color: $fontcolor;
    text-transform: capitalize;
}

.nav-item>p {
    margin-bottom: 0rem;
    line-height: 26px;
    color: $subfontcolor;
}

.nav-item>.sub_head {
    line-height: 22px;
    color: $fontcolor;
    margin-top: 25px;
}

.sub_head>span {
    font-size: 14px;
    color: rgba($color: $subfontcolor, $alpha: 0.7);
}

.side_bar_foot_img_div {
    margin-top: 60%;
}

.side_bar_foot_logo_img {
    text-align: center;
}

.left_div {
    width: 28%;
}

.right_div {
    margin-left: 10px;
}

.main_div {
    margin-left: 17px;
    margin-right: 17px;
    display: flex;
}

.right_div h1 {
    font-size: 25px;
    color: #0c4767;
}

@media (min-width: 320px) {
    .side_navbar {
        width: 100%;
    }
    .main_div {
        flex-direction: column;
    }
    .left_div {
        width: 100%;
    }
    .right_div {
        margin-left: 0px;
        width: 100%;
    }
    .right_div h1 {
        text-align: center;
    }
    .side_bar_foot_img_div {
        margin-top: 30%;
    }
}

@media (min-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {}

@media (max-width: 991px) and (min-width: 768px) {
    .main_div {
        justify-content: space-between;
        flex-direction: row;
    }
}

@media (min-width: 961px) {
    .side_navbar {
        width: 100%;
    }
    .main_div {
        margin-left: 17px;
        margin-right: 17px;
        display: flex;
        flex-direction: row;
    }
    .left_div {
        width: 28%;
    }
    .right_div {
        margin-left: 10px;
    }
    .right_div h1 {
        text-align: left;
    }
    .side_bar_foot_img_div {
        margin-top: 60%;
    }
}

@media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
}

@media (min-width: 1281px) {
    /* hi-res laptops and desktops */
}

// Main Notification
.main_noti {
    padding: 13px 0 !important;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 18px;
    @media (max-width: 1199px) {
        padding-right: 10px !important;
        .noti_side {
            img {
                width: 50px;
            }
        }
    }
    @media (max-width: 767px) {
        .navbar-nav {
            flex-direction: row;
            align-items: center;
        }
    }
}

.noti_side {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main_add>.row,
.main_add>.row>.col-12:not(.events_name, .add_buttons) {
    margin: 0;
    padding: 0;
}


/* For Free and Paid html pages */

.left_noti {
    margin: 0;
}

.left_noti>span {
    font-size: 16px;
    font-weight: 500;
    color: $fontcolor;
}

.sub_div {
    margin: 0px;
    margin-top: 5px;
}

.events_name {
    margin: 12px 0;
}

.events_name>h2 {
    font-size: 20px;
    color: #0c4767;
    text-align: left;
    @media (max-width: 767px) {
        text-align: center;
    }
}

.main_add {
    background: white;
    padding: 0px;
    border-right: 12px solid #fafafa;
    margin-top: 15px;
    @media (min-width: 767px) {
        /*max-width: 48%; */
    }
    &.schedule-event-form {
        border-right: none !important;
        margin-left: 0 !important;
    }
}

.add_buttons {
    margin-top: 10px;
    margin-bottom: 15px;
}

.main_add>.row,
.main_add>.row>.col-12:not(.events_name, .add_buttons) {
    margin: 0;
    padding: 0;
}

.main_add .col-6>.row {
    align-items: center;
    margin: 0;
}

.main_add .sechulde_s,
.main_add .sechulde_d {
    padding: 0;
    width: auto;
}

.main_add .sechulde_s img,
.main_add .sechulde_d img {
    width: 100%;
    max-width: 19px;
}

.main_add .sechulde_d {
    font-size: 14px;
    margin-top: 2px;
    @media (max-width: 767px) {
        font-size: 13px;
        margin-top: 3px;
    }
}

.main_add .sechulde_s {
    margin-right: 15px;
}

.main_add .col-6:nth-last-of-type(2)>.row {
    justify-content: flex-end;
}

.add_buttons .row {
    justify-content: space-between;
}

.add_buttons .col-8 {
    width: 56%;
    padding-right: 0;
}

.add_buttons .col-4 {
    width: 40%;
    padding-left: 0;
}

.btn-primary {
    width: 100%;
    padding: 9px 6px;
    background: #f4f4f4;
    border-color: #f4f4f4;
    color: black;
}

.btn-primary:hover {
    background: #0c9aa3;
    border-color: #0c9aa3;
}

.btn-default {
    width: 100%;
    padding: 9px 6px;
    background: #f4f4f4;
}

.btn-default>img {
    margin-right: 5px;
}

.noti_side {
    text-align: center;
}

.noti_side>img {
    padding: 0 15px;
    margin: 0;
    @media (max-width: 767px) {
        padding: 15px;
    }
}

.main_add .add_pic {
    width: 100%;
}


/* For USer Side Html */

body.user_side_template {
    background-color: #efefef;
}

body.user_side_template .noti_side {
    background-color: white;
}

body.user_side_template .left_noti>span {
    color: #a5c0d3;
}

body.user_side_template .main_add #sub_head {
    font-size: 20px;
}

body.user_side_template .main_add {
    max-width: 100%;
    padding: 16px !important;
}

body.user_side_template .sechulde_s {
    text-align: center;
}

body.user_side_template .add_buttons {
    margin-left: 10px;
}

body.user_side_template .main_button {
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #efefef;
    color: black;
    border-color: #efefef;
}

body.user_side_template .main_time {
    cursor: pointer;
    background: #efefef;
    border-radius: 5px;
}

body.user_side_template .time_active {
    background-color: #0c4767 !important;
    color: white;
}

body.user_side_template .rd-container {
    width: 100% !important;
}

body.user_side_template .main_staff {
    padding: 0;
    position: relative;
    @media (min-width: 768px) {
        width: 19.666667%;
    }
    input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        &:checked+label {
            background: #e0e0e0;
        }
    }
    label {
        padding: 15px;
        border-radius: 5px;
    }
}

body.user_side_template .confirm_button_div {
    padding: 0 15px;
}

.btn-block {
    width: 100%;
}

@media (min-width: 320px) {
    body.user_side_template .confirm_button_div {
        background-color: white;
        border-top: 1px solid #c9bcbc;
        height: 21%;
    }
    body.user_side_template .sub_div {
        margin: 0px;
        margin-top: 5px;
        margin-left: -5px;
    }
    body.user_side_template .arrow_head {
        margin-left: -124px;
    }
    body.user_side_template .input-group {
        flex-wrap: unset;
    }
}

@media (min-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}

@media (min-width: 641px) {
    body.user_side_template .sub_div {
        margin: 0px;
        margin-top: 5px;
        margin-left: -5px;
    }
    body.user_side_template .arrow_head {
        margin-left: -341px;
    }
    body.user_side_template .input-group {
        flex-wrap: unset;
    }
}

@media (min-width: 961px) {
    body.user_side_template .main_staff {
        width: 10.666667%;
    }
    body.user_side_template .confirm_button_div {
        background-color: white;
        border-top: 1px solid #c9bcbc;
        height: 17%;
    }
    body.user_side_template .sub_div {
        margin: 0px;
        margin-top: 5px;
        margin-left: 1px;
    }
    body.user_side_template .arrow_head {
        margin-left: -80px;
    }
    body.user_side_template .input-group {
        flex-wrap: wrap;
    }
    body.user_side_template .add_cal {
        padding-left: 37px;
    }
}

.top_nav {
    background: white;
    margin-bottom: 6px;
}

.top_nav img {
    width: 100%;
    @media (max-width: 767px) {
        max-width: 160px;
    }
}

nav {
    width: 100%;
    height: auto;
    margin-top: 0;
    border-radius: 0;
}

.btn-go-back {
    color: #0c4767;
    font-size: 20px;
    padding: 0;
    height: fit-content;
    margin-right: 10px;
}

.btn-go-back,
.btn-go-back+h1 {
    margin-bottom: 10px;
    width: auto;
    padding: 0;
}

.sub-desc {
    font-size: 20px !important;
    margin-top: 30px;
}

.main_time_div {
    margin: 0;
    padding: 20px 38px;
    justify-content: space-between;
}

.main_time {
    padding: 15px 5px;
    text-align: center;
    margin: 8px 0 0;
    max-width: 48%;
    flex: 0 0 48%;
}

.main_time_div,
.confirm_button_div {
    background-color: #fff;
}


/* User Side 3 */

.main_staff {
    text-align: center;
}

.main_staff span {
    font-size: 14px;
}

@media (max-width: 991px) {
    .main_staff_div {
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .main_staff {
        width: 30%;
        margin-bottom: 10px;
    }
    .main_staff_div {
        justify-content: center;
    }
}

@media (max-width: 390px) {
    .main_staff {
        width: 50%;
        flex: 0 0 50%;
    }
}


/* For USer Side 5 */

.form-group {
    margin-bottom: 1rem;
}

.event-detail-info .sechulde_d {
    color: #0c4767;
}

.schedule-event-form {
    label,
    select,
    option {
        color: #0c4767;
    }
    label {
        margin-bottom: 8px;
    }
    .error-msg {
        color: rgb(220, 53, 69);
        margin-top: 5px;
        font-weight: 400;
    }
}

.schedule-event-form input,
.schedule-event-form select {
    border: 1px solid #edeff2;
}

.btn-info {
    background: #fe9920 !important;
    border-color: #fe9920 !important;
}

.btn-info:hover {
    color: #fff !important;
    background-color: #138496 !important;
    border-color: #117a8b !important;
}

.form-check-input:checked {
    background-color: #fe9920;
    border-color: #febb6b;
}

#country_input {
    margin-left: 0;
}

.input-group>.form-control,
.input-group>.form-select {
    padding: 0;
}

.main_add.add-style-2 .addon_btn .input-group-append button {
    padding: 6px 6px;
    border-radius: 0;
}

.main_add.add-style-2 .addon_btn .input-group-append:last-of-type button {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}


/* For Userside 6 */

.schedule-event-form {
    .row-split {
        border-top: 1px solid #e6e6e6;
        padding-top: 27px;
        @media (max-width: 767px) {
            margin: 0;
        }
    }
}


/* For Userside 7 */

.main_add.add-style-2 {
    padding-top: 0;
    padding: 16px !important;
}

.main_add.add-style-2>.col-12:first-of-type {
    background: #fafafa;
    padding-top: 8px;
}

.main_add.add-style-2>.col-12 {
    border-bottom: 1px solid #d9d9d9;
}

.main_add.add-style-2 form {
    width: 100%;
}

.main_add.add-style-2 form>.col-12 {
    border-bottom: 1px solid #d9d9d9;
    padding: 20px 0 0 3px;
}

.main_add.add-style-2 form>.col-12>.row {
    margin-bottom: 8px;
}

.main_add.add-style-2 form input,
.main_add.add-style-2 form label,
.main_add.add-style-2 form .price {
    color: #0c4767;
}

.main_add.add-style-2 form input {
    text-align: center;
}

.main_add.add-style-2 form .price {
    padding-top: 3px;
}

.main_add.add-style-2 .addon_btn .input-group-append:first-of-type button {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.main_add.add-style-2 form .btn-outline-secondary {
    background: #f2efef;
    width: 30px;
    text-align: center;
    padding: 0;
}

.main_add.add-style-2 form .btn-outline-secondary:hover {
    background: #6c757d;
}

.main_add.add-style-2 .cart-btn {
    padding-top: 20px;
}

@media (max-width: 1300px) and (min-width: 1041px) {
    .item_check {
        width: 50%;
    }
    .addon_btn {
        width: 33%;
        flex: unset;
    }
}

@media (max-width: 1040px) and (min-width: 992px) {
    .item_check {
        width: 43%;
    }
    .addon_btn {
        width: 40%;
        flex: unset;
    }
}

@media (max-width: 767px) {
    .item_check {
        width: 36%;
    }
    .addon_btn {
        width: 46%;
        flex: unset;
    }
}


/* USer Side 8 */

.sub_div.confirm-book {
    background: #fff;
    border-radius: 5px;
    margin-top: 15px;
}

.sub_div.confirm-book .main_add {
    background: transparent;
}

.confirm-book #sub_head {
    font-weight: 600;
    letter-spacing: 1px;
}

.confirm-book .main_add .col-12:nth-of-type(3) p {
    color: #000;
}

.confirm-book .row.add_cal {
    padding-left: 0;
}

.confirm-book .sechulde_d {
    color: #0c4767;
}

.confirm-book .for-border {
    border-top: 1px solid #dddddd;
}

.confirm-book .snd_text_head h1 {
    font-size: 20px;
}

.confirm-book .snd_text_head,
.confirm-book .snd_input {
    margin: 0;
}


/**
* Custom CSS By Mansoor
*/

.react-calendar {
    width: 100%;
}

.react-calendar__month-view__weekdays__weekday {
    abbr {
        text-decoration: none;
        color: #212529;
        font-size: 1rem;
    }
}

.react-calendar__month-view__days__day {
    abbr {
        color: #212529;
        font-size: 1rem;
        font-weight: 400;
    }
}

.react-calendar__tile.react-calendar__tile--active {
    background-color: #0c4767;
    border-radius: 50%;
    abbr {
        color: #fff;
    }
}

.react-calendar__tile:enabled:hover {
    background-color: #fff;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background-color: #0c4767;
}

.react-calendar__tile--now {
    background: #fff;
}

.meeting-data {
    padding: 16px !important;
}

.event-desc {
    font-weight: 300 !important;
    color: #b3b3b3;
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}

.form-group-label {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #0c4767;
}

.addon-form-check {
    font-size: 1rem;
    padding-left: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
}

.pricing-container {
    border-bottom: 1px solid #d9d9d9;
    padding: 20px 0 0 3px;
}

.addon-btn {
    width: 30px;
    text-align: center;
    background: #f2efef;
}

.add-time-slot-container {
    .slot {
        background: #efefef;
        padding: 15px 5px;
        text-align: center;
        margin: 8px 0 0;
        border-radius: 5px;
        cursor: pointer;
    }
}