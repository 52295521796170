.demo-editor {
  min-height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.rdw-editor-wrapper {
  .custom-tool {
    margin-bottom: 6px;
    cursor: pointer;
  }
}
