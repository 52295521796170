.main-login-row {
  padding-top: 8rem !important;
}

.RequiredMark {
  color: red;
}

textarea.form-control {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 115px !important;
}

.row.vdivide [class*="col-"]:not(:last-child):after {
  background: #e0e0e0;
  width: 1px;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  min-height: 70px;
}

.payment-config-box-content {
  padding: 7px 7px !important;

  label {
    font-size: 14px;
  }
}

.event-edit-details-btn {
  padding: 15px !important;
}

.staff-step-completed {
  background-color: var(--main_orange) !important;
  color: white;
}

.progress-points {
  .progress-point {
    .staff-step-completed::after {
      background: var(--main_orange);
    }
  }
}
