@font-face {
    font-family: "Sofia Pro";
    src: url("../../fonts/sofia/sofia-pro-black-Az.otf") format("opentype");
}

@font-face {
    font-family: "Sofia Light";
    src: url("../../fonts/sofia/SofiaProLight.ttf") format("truetype");
}

$app-color: #fd9926;
$primary-color: #0c4767;
$white-color: #ffffff;
$border-color: #b75d69;
$font-family-sofia: "Sofia Pro";
$font-family-sofia-light: "Sofia Light";
.event-title {
    font-family: "Sofia Pro";
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    //   line-height: 5.73;
    letter-spacing: normal;
    text-align: left;
    color: $primary-color;
}

.event-wrapper {
    @media (max-width: 767px) {
        padding: 0 10px;
    }
}

.event-type-wrapper {
    margin-top: 60px;
    .card {
        width: 100%;
        background-color: $white-color;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(131, 134, 163, 0.06);
        text-align: center;
        padding: 50px 15px;
        .image-wrapper {
            margin-bottom: 15px;
            img {
                max-width: 150px;
            }
        }
        p {
            font-family: "Sofia Pro";
            font-size: 24px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: $primary-color;
        }
    }
}

.event-card {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(131, 134, 163, 0.06);
    background-color: $white-color;
    margin-bottom: 40px;
    .event-card-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border: 1px solid #e0e0e0;
        background-color: #f4f4f4;
        padding: 15px 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .event-title {
            font-family: "Sofia Pro";
            font-size: 20px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: $primary-color;
            margin-bottom: 0px;
        }
    }
    .event-card-body {
        padding: 49px 18px 40px 20px;
        width: 100%;
        .form-group {
            label {
                font-family: "Sofia Pro";
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #0c4767;
                display: block;
                width: 100%;
                margin-bottom: 15px;
                word-break: break-word;
            }
            .form-control {
                width: 100%;
                height: 55px;
                padding: 15.5px 20px 17.5px 20px;
                border-radius: 5px;
                border: 1px solid #e0e0e0;
                background-color: #ffffff;
                outline: none;
                box-shadow: none;
            }
            input[type="textarea"] {
                min-height: 200px;
            }
            .input-group {
                .input-group-prepend {
                    .input-group-text {
                        border: 1px solid #e0e0e0;
                        background-color: #f4f4f4;
                        font-size: 12px;
                    }
                }
                .form-control {
                    width: auto;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }
            }
            .number-input-field {
                display: flex;
                justify-content: start;
                width: 100%;
                max-width: 100%;
                span {
                    border: 1px solid #e0e0e0;
                    background-color: #f4f4f4;
                    text-align: center;
                    padding: 15px 20px;
                    max-height: 54px;
                    cursor: pointer;
                    &:first-child {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                    &:last-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
                input {
                    width: 125px;
                    border-left: 0px;
                    border-right: 0px;
                    border-top: 1px solid #e0e0e0;
                    border-bottom: 1px solid #e0e0e0;
                    text-align: center;
                    background: #ffffff;
                }
                &.interval-field {
                    input {
                        width: 225px;
                    }
                }
            }
        }
        .btn-wrapper {
            margin-top: 40px;
            @media (max-width: 991px) {
                &.no_mar {
                    margin-top: 15px;
                }
            }
            @media (max-width: 767px) {
                button {
                    width: 100%;
                }
            }
        }
        .nav.nav-tabs {
            box-shadow: none;
            border: none;
            margin-bottom: 0px;
            position: relative;
            border-radius: 0;
            display: flex;
            justify-content: center;
            .nav-item {
                .nav-link {
                    padding: 0px 30px 15px 30px;
                    font-family: $font-family-sofia;
                    font-size: 16px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #626262;
                    @media (max-width: 767px) {
                        padding: 0px 15px 15px 15px;
                    }
                    @media (max-width: 360px) {
                        padding: 0px 12px 15px 12px;
                        font-size: 14px;
                    }
                    &:after {
                        content: "";
                        background: #e0e0e0 !important;
                        box-shadow: none !important;
                        height: 2px;
                        width: 100%;
                        left: 0;
                        position: absolute;
                        bottom: 0px;
                    }
                    &.active {
                        color: $primary-color;
                    }
                }
            }
        }
        .tab-content {
            padding-top: 35px;
        }
    }
    .event-card-footer {
        padding: 49px 18px 40px 20px;
    }
}

.btn {
    &.availability-btn-active {
        border: 4px solid #fd9926 !important;
    }
    &.btn-app-secondary {
        border: 1px solid #e0e0e0 !important;
        background-color: #f4f4f4 !important;
        color: #626262 !important;
    }
    &.btn-icon {
        height: 50px;
        svg {
            width: 30px;
            height: 30px;
        }
    }
}

.progress-points {
    width: 100%;
    padding: 0px;
    margin: 0px;
    .progress-point {
        list-style: none;
        width: 100%;
        margin-bottom: 40px;
        font-family: "Sofia Pro";
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: rgba(98, 98, 98, 0.7);
        span {
            border: 1px solid #e0e0e0;
            background-color: #f4f4f4;
            font-size: 20px;
            width: 43px;
            height: 43px;
            border-radius: 50%;
            margin-right: 18px;
            position: relative;
            &::after {
                content: "";
                width: 1px;
                height: 40px;
                background: #e0e0e0;
                position: absolute;
                bottom: -40px;
                left: 20px;
            }
        }
        &:last-child {
            span {
                &:after {
                    display: none;
                }
            }
        }
    }
}

.event-form-group {
    label {
        font-family: "Sofia Pro";
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #0c4767;
        display: block;
        width: 100%;
        margin-bottom: 15px;
        &.form-check-label {
            color: #626262;
            font-weight: 400;
        }
    }
    .form-control {
        width: 100%;
        height: 55px;
        padding: 15.5px 20px 17.5px 20px;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        background-color: #ffffff;
        outline: none;
        box-shadow: none;
        color: #676767;
        font-size: 16px;
        font-family: $font-family-sofia;
    }
    textarea {
        // min-height: 150px;
        resize: none;
    }
    .input-group {
        .input-group-prepend {
            .input-group-text {
                border: 1px solid #e0e0e0;
                background-color: #f4f4f4;
                font-size: 12px;
            }
        }
        .form-control {
            width: auto;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
    .number-input-field {
        display: flex;
        // justify-content: space-evenly;
        width: 100%;
        max-width: 100%;
        span {
            border: 1px solid #e0e0e0;
            background-color: #f4f4f4;
            text-align: center;
            padding: 15px 20px;
            max-height: 54px;
            cursor: pointer;
            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
        input {
            width: 125px;
            border-left: 0px;
            border-right: 0px;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            text-align: center;
            background: #ffffff;
        }
    }
    .event-form-check {
        margin-bottom: 15px;
        label {
            display: inline-block;
            margin-bottom: 0px;
            // vertical-align: middle;
            span {
                width: 16px;
                height: 16px;
                border: 1px solid $primary-color;
                border-radius: 50%;
                display: inline-block;
                margin-right: 10px;
                vertical-align: middle;
            }
        }
        input[type="checkbox"] {
            appearance: none;
            display: none;
            &:checked+label {
                color: $app-color;
                span {
                    border-color: $app-color;
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        background-color: $app-color;
                        border-radius: 50%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
        input[type="radio"] {
            appearance: none;
            display: none;
            &:checked+label {
                color: $app-color;
                span {
                    border-color: $app-color;
                    position: relative;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        background-color: $app-color;
                        border-radius: 50%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
        input[type="number"] {
            width: 50px;
            height: 50px !important;
            border-radius: 5px;
            text-align: center;
            border: 1px solid #e0e0e0;
            color: #626262;
            padding: 12px 0px !important;
            @media (max-width: 767px) {
                width: 45px;
                height: 45px !important;
            }
        }
        &.event-cus-form {
            span.tooltip-wrapper {
                border: none;
                vertical-align: unset;
            }
            input[name="host_phone_no"] {
                width: 100% !important;
            }
        }
        .check-field {
            margin-left: 30px;
            margin-top: 10px;
            label {
                color: #626262;
                margin-bottom: 15px;
            }
        }
        .help-block {
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #626262;
            font-weight: 400 !important;
            font-family: $font-family-sofia-light;
            margin-left: 30px;
        }
    }
    .form-check-box {
        input[type="checkbox"] {
            appearance: none;
            display: none;
            &:checked+label {
                color: $app-color;
                span {
                    border-color: $app-color;
                    position: relative;
                    background-color: $app-color;
                    &::after {
                        content: "";
                        position: absolute;
                        left: 3px;
                        top: 0px;
                        width: 5px;
                        height: 10px;
                        background-color: $app-color;
                        border: solid $white-color;
                        border-width: 0px 3px 3px 0px;
                        transform: rotate(45deg);
                    }
                }
            }
        }
        input[type="radio"] {
            appearance: none;
            display: none;
            &:checked+label {
                color: $app-color;
                span {
                    border-color: $app-color;
                    position: relative;
                    background-color: $app-color;
                    &::after {
                        content: "";
                        position: absolute;
                        left: 3px;
                        top: 0px;
                        width: 5px;
                        height: 10px;
                        background-color: $app-color;
                        border: solid $white-color;
                        border-width: 0px 3px 3px 0px;
                        transform: rotate(45deg);
                    }
                }
            }
        }
        label {
            span {
                width: 15px;
                height: 15px;
                border: 2px solid $primary-color;
                border-radius: 3px;
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
    .help-block {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #626262;
        font-weight: 400 !important;
        font-family: $font-family-sofia-light;
        margin-left: 30px;
    }
    .toggle-field {
        span {
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
        }
        // .react-switch-handle {
        //   background: $app-color !important;
        // }
        .react-switch-bg {
            //background: rgba(254, 153, 32, 0.3) !important;
            // svg {
            //   display: none !important;
            // }
        }
    }
    .color-selector-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-around;
        @media (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        .event-form-check {
            margin-bottom: 0px;
            @media (max-width: 767px) {
                margin-bottom: 10px;
            }
            input[type="radio"] {
                display: none;
                &:checked+label {
                    span {
                        &:after {
                            content: "";
                            position: absolute;
                            left: 14px;
                            top: 2px;
                            width: 15px;
                            height: 30px;
                            border: solid $white-color;
                            border-width: 0px 3px 3px 0px;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            label {
                margin-bottom: 0px;
            }
            span {
                width: 50px;
                height: 50px;
                border-radius: 5px;
                @media (max-width: 767px) {
                    width: 43px;
                    height: 43px;
                }
                &::before {
                    display: none;
                }
            }
        }
    }
    .time-selector-wrapper {
        display: flex;
        width: 100%;
        justify-content: start;
        // width: 150px;
        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
        .event-form-check {
            margin-bottom: 0px;
            input[type="radio"] {
                display: none;
                &:checked+label {
                    span {
                        &:after {
                            content: "";
                            position: absolute;
                            border: 0px;
                            background-color: rgba(252, 152, 52, 0.1);
                            width: 100%;
                            height: 100%;
                            left: 0px;
                            top: 0px;
                        }
                    }
                }
            }
            label {
                margin-bottom: 0px;
            }
            span {
                width: 50px;
                height: 50px;
                border-radius: 5px;
                text-align: center;
                border: 1px solid #e0e0e0;
                color: #626262;
                padding: 12px 0px;
                &::before {
                    display: none;
                }
                &:after {
                    content: "";
                }
                &.day-selector {
                    width: 80px;
                }
                @media (max-width: 767px) {
                    width: 43px;
                    height: 43px;
                }
            }
        }
    }
    .css-yk16xz-control {
        width: 100%;
        height: 55px;
    }
}

.event-modal {
    .modal-content {
        padding: 45px 50px;
        .modal-header {
            background-color: transparent;
            text-align: center;
            width: 100%;
            .modal-title {
                color: $primary-color;
                width: 100%;
                text-align: center;
                font-family: $font-family-sofia;
                font-size: 30px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
            }
            .close {
                background: transparent;
                box-shadow: none;
                right: 60px;
                position: absolute;
                top: 40px;
                span {
                    color: $border-color;
                    font-size: 3rem;
                }
            }
        }
        .modal-footer {
            border: 0px;
            display: block;
            width: 100%;
        }
    }
}

.event-form-group {
    display: block;
    position: relative;
    .event-dropdown {
        margin-bottom: 10px;
        display: block;
        position: relative;
        .dropdown-toggle {
            background-color: $white-color !important;
            border: 1px solid #e0e0e0 !important;
            font-family: $font-family-sofia;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: #626262;
            &:hover {
                box-shadow: none;
                color: #626262 !important;
            }
        }
        .dropdown-menu {
            top: -10px !important;
            width: 100%;
            border: 1px solid #e0e0e0;
            border-radius: 0px;
            box-shadow: 0 8px 19px 0 rgba(0, 0, 0, 0.1);
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 0px 15px;
            .dropdown-item {
                width: 100%;
                padding: 20px 0px;
                border-bottom: 1px solid #e0e0e0;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                font-family: $font-family-sofia;
                color: #626262;
                &:last-child {
                    border-bottom: 0px;
                }
                &:hover {
                    background-color: transparent;
                    color: #626262 !important;
                }
                &:focus {
                    outline: none;
                }
            }
            &::before {
                display: none;
            }
        }
        .dropdown-icon {
            position: absolute;
            right: 30px;
        }
        &.show {
            .dropdown-toggle {
                background-color: $white-color;
                color: #626262;
                &:hover {
                    color: #626262;
                }
            }
        }
    }
}

.event-field-group {
    padding: 30px 0px;
    border-bottom: 1px solid #e0e0e0;
    .field-title {
        font-family: $font-family-sofia;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $primary-color;
        margin-bottom: 15px;
    }
    .event-form-group {
        margin-bottom: 0px;
        .event-form-check {
            margin-bottom: 10px;
            label {
                margin-bottom: 0px;
            }
        }
    }
    .event-field-groups {
        margin-left: 20px;
        .form-group {
            label {
                font-size: 14px;
            }
            .input-group {
                .input-group-prepend {
                    .input-group-text {
                        border: 1px solid #e0e0e0;
                        background-color: #f4f4f4;
                        font-size: 12px;
                    }
                }
                .form-control {
                    width: 100px;
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px;
                }
            }
        }
    }
    .btn-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        .btn {
            &:first-child {
                margin-right: 8px;
            }
        }
    }
    .availbility-container {
        width: 100%;
        // max-width: 65%;
        margin: 0px auto;
        .availbility-container-wrapper {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #e0e0e0;
            min-height: 400px;
            margin: 20px 0px 45px 0px;
            padding: 30px 60px;
            .calendar-wrapper {
                margin-top: 35px;
                .react-calendar {
                    width: 100% !important;
                    border: 0px !important;
                    .react-calendar__tile {
                        padding: 15px;
                        font-size: 14px;
                        font-weight: 500;
                        font-stretch: normal;
                        font-style: normal;
                        letter-spacing: normal;
                        text-align: center;
                        font-family: $font-family-sofia;
                        text-decoration: none;
                        color: #626262;
                    }
                    .react-calendar__tile--active {
                        background-color: $primary-color;
                        color: $white-color;
                    }
                    .react-calendar__month-view__weekdays__weekday {
                        margin: 15px;
                        abbr {
                            color: $primary-color;
                            font-size: 14px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            letter-spacing: normal;
                            text-align: center;
                            font-family: $font-family-sofia;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    &:last-child {
        margin-bottom: 30px;
    }
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.d-flex {
    display: flex !important;
    width: 100%;
    &.justify-content-around {
        justify-content: space-around !important;
    }
    &.justify-content-between {
        justify-content: space-between !important;
    }
}

.text-right {
    text-align: right !important;
}

.jodit-toolbar-editor-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal {
    background: $white-color !important;
}

.jodit-container {
    border-color: #e0e0e0 !important;
}

.border-zero {
    border: 0px !important;
}

.padding-zero {
    padding-top: 0px !important;
}

.full-calendar-wrapper {
    width: 100%;
    height: 600px;
}

.pricing {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0px;
    }
}

.ad-hoc-container {
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    padding: 30px 50px 50px 50px;
    .react-calendar {
        border: 0px !important;
        width: 100% !important;
        font-family: $font-family-sofia;
        .react-calendar__tile--active:enabled:hover,
        .react-calendar__tile--active:enabled:focus,
        .react-calendar__tile--now:enabled:hover,
        .react-calendar__tile--now:enabled:focus,
        .react-calendar__tile--active {
            background: $primary-color !important;
        }
        .react-calendar__tile--now {
            background: $app-color !important;
            color: #ffffff !important;
        }
        .react-calendar__tile {
            padding: 20px 5px;
        }
    }
}

.event-form-group {
    .append-field {
        .form-control {
            border-top-left-radius: 5px !important;
            border-top-right-radius: 0px !important;
            border-bottom-left-radius: 5px !important;
            border-bottom-right-radius: 0px !important;
        }
        .input-group-append {
            .input-group-text {
                width: 70px !important;
                justify-content: center;
            }
        }
    }
}

.success-wrapper {
    width: 100%;
    display: table;
    height: 80vh;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(131, 134, 163, 0.06);
    background-color: $white-color;
    position: relative;
    z-index: 1;
    .success-container {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        text-align: center;
        img {
            max-width: 300px;
        }
        p {
            font-family: $font-family-sofia;
            font-size: 22px;
            color: $primary-color;
            letter-spacing: 2px;
            margin-top: 30px;
            font-weight: 300;
            width: 100%;
            display: block;
            margin: 30px auto;
            line-height: 35px;
            max-width: 40%;
        }
        a {
            background-color: $app-color;
            width: 300px;
            padding: 20px 0px;
            text-align: center;
            display: inline-block;
            font-size: 16px;
            font-family: $font-family-sofia;
            color: $white-color;
            border: 1px solid $app-color;
            border-radius: 5px;
            // margin-top: 30p;
        }
    }
}

.event-field-inline {
    display: flex;
    justify-content: start;
    @media (max-width: 767px) {
        flex-direction: column;
    }
    .input-field {
        width: 100px;
        margin-left: 10px;
        @media (max-width: 767px) {
            margin: 0;
        }
        input {
            max-width: 100%;
            border: 0px;
            border-radius: 0;
            border-bottom: 1px solid #0c4767;
            outline: none;
        }
    }
}

.event-field-inline-range {
    display: flex;
    justify-content: start;
    @media (max-width: 767px) {
        margin-top: 15px;
        flex-direction: column;
    }
    .range-field {
        // width: 100px;
        margin-left: 10px;
        @media (max-width: 767px) {
            margin: 0;
        }
        input {
            // max-width: 100%;
            border: 0px;
            border-radius: 0;
            border-bottom: 1px solid #0c4767;
            outline: none;
            font-family: $font-family-sofia;
        }
    }
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
    font-family: $font-family-sofia;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
    width: 40px !important;
    height: 40px !important;
}

.btn-add-question {
    &.btn-outline {
        border: 0px !important;
        color: $primary-color !important;
        text-align: left !important;
        text-decoration: underline;
        padding: 0px !important;
        span {
            margin-right: 10px;
        }
        &:hover {
            box-shadow: none;
        }
    }
}

.question-section {
    margin-bottom: 15px;
}

.removeOption {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.answer_options {
    margin-bottom: 15px;
}

.invite-wrapper {
    background-color: #e6e6e6;
    padding: 20px;
    h3 {
        color: $primary-color;
        margin-bottom: 30px;
        font-size: 22px;
        font-family: $font-family-sofia;
    }
}

.group-field {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    select {
        border: 1px solid #e0e0e0;
        background-color: #f4f4f4;
        font-size: 12px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 0px 10px;
        color: #4e5154;
    }
    input {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

.add-question-section-modal {
    margin-top: -40px;
}

.question-section {
    padding: 20px 10px;
    background-color: #f9f9f9;
    margin: 0px 0px 20px 0px;
    cursor: pointer;
    position: relative;
    span {
        position: absolute;
        right: 0px;
        top: 0px;
    }
    label {
        font-family: $font-family-sofia;
        font-size: 14px;
        color: #4d5055;
        letter-spacing: 2px;
        margin-bottom: 15px;
        font-weight: 300;
    }
    .text-field-preview {
        height: 55px;
        border-radius: 0px;
        border: #dadada solid 1px;
        background-color: #ffffff;
    }
    .textarea-field-preview {
        height: 100px;
        border-radius: 0px;
        border: #dadada solid 1px;
        background-color: #ffffff;
    }
    .checkbox-field-preview {
        ul {
            padding-left: 0px;
            li {
                list-style: none;
                position: relative;
                padding: 10px 10px 10px 30px;
                font-family: $font-family-sofia;
                font-size: 14px;
                color: #4d5055;
                &:before {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    border: #dadada solid 1px;
                    background-color: #ffffff;
                    left: 0px;
                }
            }
        }
    }
    .radio-field-preview {
        ul {
            padding-left: 0px;
            li {
                list-style: none;
                position: relative;
                padding: 10px 10px 10px 30px;
                font-family: $font-family-sofia;
                font-size: 14px;
                color: #4d5055;
                word-break: break-all;
                &:before {
                    content: "";
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    border: #dadada solid 1px;
                    background-color: #ffffff;
                    border-radius: 50%;
                    left: 0px;
                }
            }
        }
    }
    .select-field-preview {
        select {
            height: 55px;
            border-radius: 0px;
            border: #dadada solid 1px;
            background-color: #ffffff;
            font-family: $font-family-sofia;
            font-size: 14px;
            color: #4d5055;
            width: 100%;
            padding: 10px;
        }
    }
}

.tooltip-wrapper {
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    &:hover {
        // display: block;
        .tooltip-section {
            display: block;
        }
    }
    .tooltip-section {
        display: none;
        width: 300px;
        min-height: 150px;
        position: absolute;
        z-index: 100;
        background-color: #232323;
        color: #fff;
        border: 0;
        border-radius: 0;
        padding: 30px 20px;
        box-shadow: none;
        // top: 0px;
        // right: 20px;
        img {
            max-width: 100%;
        }
        &.left {
            right: 0px;
        }
    }
}

.form-field {
    &.text-danger {
        font-size: smaller;
        font-weight: 400;
    }
}

.btn.btn-danger.btn-remove-question {
    margin-top: 20px;
    background-color: transparent !important;
    border: 0px !important;
    color: #818181 !important;
    text-decoration: underline;
}

.list-view {
    .top-header-section {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        h1 {
            color: $primary-color;
            font-size: 35px;
            // text-transform: uppercase;
            letter-spacing: 2px;
            font-family: $font-family-sofia;
        }
        a {
            display: inline-block;
            width: auto !important;
            height: auto !important;
        }
    }
    .content-container {
        margin-top: 30px;
        .card {
            border: 1px solid #f7f7f7;
            width: 100%;
            min-height: 200px;
            .card-header {
                width: 100%;
                background-color: #f7f7f7;
                padding: 20px 15px;
                display: flex;
                justify-content: space-between;
                border: 1px solid #f7f7f7;
                p {
                    margin-bottom: 0px;
                    font-size: 14px;
                    font-family: $font-family-sofia;
                }
                .event-color {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #b8c2cc;
                    background-color: #b8c2cc;
                    border-radius: 50%;
                }
            }
            .card-body {
                padding: 15px;
                .image-wrapper {
                    max-width: 100%;
                    max-height: 300px;
                    text-align: center;
                    margin-bottom: 30px;
                    img {
                        max-width: 100%;
                        max-height: 300px;
                    }
                }
                h3 {
                    font-size: 26px;
                    font-family: $font-family-sofia;
                    color: $primary-color;
                    margin-bottom: 20px;
                }
                p {
                    font-size: 16px;
                    color: #4d5055;
                    margin-bottom: 20px;
                    svg {
                        margin-right: 8px;
                        color: $primary-color;
                    }
                }
            }
        }
    }
}

.rbc-calendar>.rbc-toolbar {
    .rbc-btn-group {
        //display: none !important;
    }
}

.event-check-with-field {
    display: flex;
    .datepicker-wrapper {
        margin-left: 8px;
        .react-date-picker__wrapper {
            border-top: 0px !important;
            border-left: 0px !important;
            border-right: 0px !important;
            input {
                outline: none;
            }
        }
    }
}

.time-selector-wrapper {
    &.day-selector {
        span {
            width: 80px !important;
        }
    }
}

.tabs-wrapper {
    &.event-availbility {
        width: 100%;
        .tabs-container {
            width: 100%;
            .nav.nav-tabs {
                display: flex;
                justify-content: flex-end;
                @media (max-width: 767px) {
                    justify-content: space-between;
                    margin-top: 20px;
                }
                span.icon {
                    margin-right: 10px;
                }
            }
            .list-view-wrapper {
                width: 100%;
                .list-view {
                    width: 100%;
                    display: flex;
                    padding: 20px 16px;
                    border-bottom: 1px solid rgba(77, 80, 85, 0.1);
                    justify-content: space-evenly;
                    align-items: center;
                    .title-section {
                        display: flex;
                        align-items: center;
                        .form-check-box {
                            input[type="checkbox"] {
                                appearance: none;
                                display: none;
                                &:checked+label {
                                    color: $app-color;
                                    span {
                                        border-color: $app-color;
                                        position: relative;
                                        background-color: $app-color;
                                        &::after {
                                            content: "";
                                            position: absolute;
                                            left: 4px;
                                            top: 1px;
                                            width: 5px;
                                            height: 10px;
                                            background-color: $app-color;
                                            border: solid $white-color;
                                            border-width: 0px 3px 3px 0px;
                                            transform: rotate(45deg);
                                        }
                                    }
                                }
                            }
                            label {
                                span {
                                    width: 18px;
                                    height: 18px;
                                    border: 2px solid $primary-color;
                                    border-radius: 3px;
                                    display: inline-block;
                                    margin-right: 10px;
                                }
                            }
                        }
                        span.day-title {
                            margin-left: 30px;
                            font-size: 18px;
                            font-family: $font-family-sofia-light;
                            font-weight: bold;
                            color: #000000;
                            letter-spacing: 2px;
                            text-transform: uppercase;
                        }
                    }
                    .action-wrapper {
                        span.icon {
                            margin-right: 15px;
                            cursor: pointer;
                            // display: block;
                            position: relative;
                            .schedule-dropdown {
                                width: 200px;
                                min-height: 200px;
                                background: #ffffff;
                                border: 1px solid transparent;
                                border-radius: 5px;
                                position: absolute;
                                left: 0px;
                                top: 30px;
                                box-sizing: border-box;
                                // padding: -px 12;
                                background: #ffffff;
                                border: 1px solid rgba(0, 0, 0, 0.2);
                                border-radius: 6px;
                                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
                                z-index: 100;
                                .schedule {
                                    padding: 15px;
                                    display: flex;
                                    width: 100%;
                                    border-bottom: 1px solid rgba(77, 80, 85, 0.1);
                                    align-items: center;
                                    .form-check-box {
                                        display: flex;
                                        align-items: center;
                                        input[type="checkbox"] {
                                            appearance: none;
                                            display: none;
                                            &:checked+label {
                                                color: $app-color;
                                                span {
                                                    border-color: $app-color;
                                                    position: relative;
                                                    background-color: $app-color;
                                                    &::after {
                                                        content: "";
                                                        position: absolute;
                                                        left: 3px;
                                                        top: 1px;
                                                        width: 5px;
                                                        height: 8px;
                                                        background-color: $app-color;
                                                        border: solid $white-color;
                                                        border-width: 0px 3px 3px 0px;
                                                        transform: rotate(45deg);
                                                    }
                                                }
                                            }
                                        }
                                        label {
                                            display: flex;
                                            span {
                                                width: 15px;
                                                height: 15px;
                                                border: 2px solid $primary-color;
                                                border-radius: 3px;
                                                display: inline-block;
                                                margin-right: 10px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .schedule-section {
                        min-width: 70%;
                        display: flex;
                        // justify-content: space-between;
                        align-items: center;
                        .time-dropdown {
                            min-width: 40%;
                        }
                        span {
                            margin: 0px 20px;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}

.availbility-container-wrapper {
    position: relative;
}

.event-day {
    .card {
        padding: 20px 0px;
        width: 200px;
        border-radius: 8px;
        p {
            margin: 0px;
        }
        ul {
            padding: 0px;
            margin: 0px;
            li {
                list-style: none;
                padding: 10px 15px;
                width: 100%;
                font-size: 16px;
                display: flex;
                justify-content: start;
                align-items: center;
                font-weight: 500;
                font-family: "Sofia Light";
                cursor: pointer;
                &:hover {
                    background-color: #e0e0e0;
                }
                svg {
                    margin-right: 5px;
                }
            }
        }
    }
}

.event-modal {
    &.modal-sm {
        .modal-content {
            padding: 15px 0px;
            .modal-title {
                font-size: 20px;
            }
            .modal-body {
                padding-left: 0px;
                padding-right: 0px;
            }
            .close {
                display: none;
            }
        }
        .modal-footer {
            width: 100%;
            .action-items {
                display: flex;
                justify-content: space-evenly;
                button {
                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.calendar-wrapper {
    font-family: "Sofia Light";
    font-size: 16px;
    width: 100%;
    text-align: left;
    .DayPicker {
        width: 100%;
        .DayPicker-Caption {
            font-family: "Sofia Pro";
            font-size: 16px;
        }
        .DayPicker-Months {
            justify-content: start !important;
        }
        .DayPicker-Month {
            width: 100%;
        }
        .DayPicker-Weekday {
            font-size: 18px;
        }
        .DayPicker-Day {
            width: 40px;
            height: 40px;
            margin-bottom: 10px;
            padding: 12px;
            font-size: 18px;
            // display: inline-block;
        }
    }
}

.time-interval-wrapper {
    width: 100%;
    background-color: #f9f9f9;
    border: 1px solid #dadada;
    padding: 20px 10px;
    label {
        font-size: 15px;
        font-family: "Sofia Pro";
        margin-bottom: 15px;
    }
    .form-group {
        font-family: "Sofia Light";
        margin-bottom: 0px;
    }
    .row {
        align-items: center;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    .icons {
        width: 100%;
        // display: flex;
        align-items: center;
        max-width: 100%;
        text-align: left;
        margin-right: 10px;
        span {
            padding-right: 5px;
            cursor: pointer;
        }
    }
}

.event-form-label {
    font-family: "Sofia Pro";
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0c4767;
    display: block;
    width: 100%;
}