.profile-step {
    padding: 0 150px !important;
    @media (max-width: 1199px) {
        padding: 0 60px !important;
    }
    @media (max-width: 992px) {
        padding: 0 20px !important;
    }
    @media (max-width: 767px) {
        padding: 0 0px !important;
    }
}

.profile-step-three .form-checks {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: #0C4767;
    @media (max-width: 991px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.profile-step-three .flex.events {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-left: 7px;
    @media (max-width: 767px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.profile-step-three .flex.events .form-check,
.profile-step-three .flex.events label {
    height: auto;
    min-height: auto;
}

.profile-step-three .form-checks label,
.profile-step-three .flex.events label {
    width: 100%;
    color: #0C4767 !important;
}

.profile-step-three .form-check {
    padding-bottom: 15px;
    cursor: pointer;
}

.profile-step-three .form-check label {
    color: #0C4767;
    font-size: 16px;
    font-weight: 500;
}

.profile-step-three .tag-button-label {
    height: 50px;
}

.profile-step-three .same-label {
    margin-bottom: 25px;
}

// .profile-step-three .tag-button-label input[type=checkbox] {
//     display: none;
// }
.profile-step-three .tag-button-label input[type=checkbox]:checked+.tag-button .unchecked {
    display: none;
}

.profile-step-three .tag-button-label input[type=checkbox]:not(:checked)+.tag-button .checked {
    display: none;
}

.profile-step-three .tag-button-label {
    width: 50%;
}

.profile-step-three .tag-button-label .tag-button {
    height: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #0C4767;
    gap: 10px;
    font-weight: 500;
    font-size: 16px;
}