@import "../scss/ribbash/variables.scss";
@import "../scss/ribbash/mixins.scss";
// Signup Form
.signup-form .more-opt {
    align-items: center;
    margin-top: 20px;
    label {
        margin: 0 !important;
    }
}

// Header
header {
    .logo {
        img {
            max-width: 175px;
            @media (max-width: 1250px) {
                max-width: 155px;
            }
            @media (max-width: 1099px) {
                max-width: 120px;
            }
            @media (max-width: 1020px) {
                max-width: 120px;
            }
            @media (max-width: 991px) {
                max-width: 150px;
            }
        }
    }
    .myBookingLink {
        padding-left: 20px;
        margin-left: 20px;
        display: flex;
        align-items: flex-end;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 18px;
            background: rgba($color: $brandDark, $alpha: 0.3);
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        p {
            font-size: 14px;
            color: $brandDark;
            line-height: 1;
        }
        a {
            line-height: 1;
            color: $brandPrimary;
            text-decoration: underline !important;
        }
        .copy_btn {
            margin-left: 20px;
        }
    }
    .customizer {
        position: relative;
        display: inline-block !important;
        background-color: transparent;
        width: auto;
        height: auto;
        right: unset;
        top: unset;
        .customizer-toggle {
            position: relative;
            left: 0;
            top: 0;
            width: 25px;
            height: 25px;
            padding: 0 0;
            line-height: 1;
            background: transparent;
            box-shadow: none;
        }
        .open-icon {
            stroke: $brandDark;
            stroke-width: 1px;
            top: 0;
            circle {
                stroke: $brandPrimary;
            }
        }
        .outer {
            position: fixed;
            top: -100%;
            left: 0;
            right: 0;
            width: 100%;
            height: auto;
            background: #fff;
            overflow: hidden;
            transition: 0.3s all ease;
        }
        &.open {
            .outer {
                top: 0;
                transition: 0.3s all ease;
            }
        }
        .header {
            background: #f8f8f8;
            padding: 10px 20px !important;
            h4 {
                padding: 0;
                font-weight: 600;
                color: $brandDark;
                text-transform: capitalize;
            }
        }
        .customizer-content {
            height: calc(100vh - 45px);
            overflow-x: hidden !important;
            padding: 20px 20px !important;
            .fonticon-container {
                padding: 0 !important;
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                .fonticon-wrap {
                    height: auto;
                }
            }
            .fonticon-classname {
                text-align: left;
                margin: 0;
            }
        }
    }
}

.copy_btn {
    img {
        max-width: 17px;
    }
}

.rd_flexrowitem {
    @media (max-width: 1199px) {
        margin-right: 0;
    }
}

.sidemenu {
    max-height: unset;
    @media (min-width: 768px) {
        min-height: calc(100vh - 152px);
    }
    .ul-listitemmenu {
        max-height: unset;
    }
}

.buttonnotgcont {
    a {
        @media (max-width: 1199px) {
            padding: 0;
        }
    }
    &.react-quicktips {
        svg {
            position: relative;
            z-index: 1;
            @media (max-width: 1099px) {
                width: 19.489px;
                height: 25.903px;
            }
        }
        .rd_quicktips {
            padding: 3px 12px 3px 18px !important;
            background-color: #dedede;
            border-radius: 5px;
            width: 90px;
            margin-left: -13px;
            @media (max-width: 1099px) {
                padding: 3px 8px 3px 14px !important;
            }
        }
        &.active {
            svg {
                path {
                    fill: $brandDark;
                }
            }
            .rd_quicktips {
                background-color: rgba($color: $brandDark, $alpha: 0.15);
                color: $brandDark;
            }
        }
    }
}

.mobile_left_topbar {
    background: #f8f8f8;
    padding: 16px 15px;
    .logo_icon {
        max-width: 51px;
    }
    .rd_quicktips {
        border: none;
        font-size: 11px;
        color: #8e8e8e !important;
    }
}

// Dashbaord Css
.dash_filter_bar {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        padding: 10px;
    }
    &.main_dash {
        @media (max-width: 767px) {
            justify-content: space-between;
            .toggle_field {
                margin-left: -15px;
            }
            label {
                padding: 0 !important;
                width: 100%;
                max-width: 120px;
            }
        }
    }
}

.rd_contentchart1thign p {
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0;
    }
}

.rd_crmspdivcon {
    p {
        font-weight: 500 !important;
        margin-bottom: 5px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    span {
        font-weight: 700 !important;
    }
}

// Dahsboard Graph
.db_chart {
    @media (max-width: 1199px) {
        flex-direction: column;
        .rd_contentchart1thign {
            display: flex;
            padding: 0;
        }
    }
}

// Calendar
.react-date-picker__inputGroup__leadingZero {
    padding-right: 8px;
    &+input {
        padding-left: 0 !important;
    }
}

.rd_calendarpafilterthitemsitem {
    p {
        margin-bottom: 10px;
    }
}

// .rbc-row-segment {
//     .rbc-event-content {
//         text-align: center;
//     }
// }
// Calender Event Details
.vd-title {
    font-weight: 700;
}

.vd-description {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: #626262;
}

.rd_inputselectheadermodalsetting {
    svg {
        margin-right: 15px !important;
    }
}

.copy_link_btn {
    text-align: center;
    @include btnStyle( $brandPrimary, $brandPrimary, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimaryHov, $brandPrimaryHov, $brandWhite);
}

.dashboarditemthing {
    box-shadow: 0px 3px 6px 0px rgba($color: #8386a3, $alpha: 6%);
}

.rd_carditem {
    border-width: 3px;
    box-shadow: 0px 3px 6px 0 rgba($color: #8386a3, $alpha: 6%);
    position: relative;
    .draft_eve {
        background-color: rgba($color: #626262, $alpha: 0.7);
        color: #fff;
        position: absolute;
        transform: rotate(-90deg);
        bottom: 0;
        left: unset;
        width: max-content;
        height: max-content;
        margin: auto;
        border-radius: 5px 5px 0 0;
        padding: 3px 11px;
        right: -14.58px;
        top: -27%;
        &.no_show {
            right: -25.58px;
            top: 0%;
            background-color: #fe9920;
        }
        &.resc {
            font-size: 11px;
            right: -56.38px;
        }
    }
    h5 {
        width: 100%;
        max-width: 198px;
        display: flex;
        align-items: center;
    }
    &.rd_carditemnoback {
        a {
            font-size: 20px;
            font-weight: 700;
            text-transform: capitalize;
            margin-right: 4px;
            max-width: 175px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: $brandDark;
            @media (max-width: 991px) {
                max-width: 90px;
            }
            &:hover {
                color: $brandPrimary;
            }
        }
    }
    .rd_cardheaderevenpasc {
        align-items: center;
        padding-right: 16px;
        margin-bottom: 20px;
        h3 {
            width: 50%;
            color: $brandDark;
            font-weight: 700;
            text-transform: capitalize;
        }
        p {
            font-weight: 700 !important;
            text-align: right;
        }
    }
    .rd_cardbdyevenpasc {
        p {
            display: flex;
            align-items: center;
            color: $brandDark;
            margin-bottom: 6px;
            img {
                max-width: 16px;
                margin-right: 10px;
            }
        }
        .rd_timeiconthin {
            width: 16px;
            height: 16px;
            background-size: 14px;
            @media (max-width: 991px) {
                background-size: 13px;
            }
        }
        .rd_timelefticon {
            background: url(../icons/time_left.svg) no-repeat;
            background-size: 100% 100%;
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
        .rd_archiveiconth {
            background: url(../icons/call_icon.svg) no-repeat;
            background-size: 100% 100%;
            width: 16px !important;
            height: 16px !important;
        }
    }
    &.schedule-load-more-dd.dropdown {
        margin: 10px;
        max-width: 245px;
        a {
            max-width: 100%;
            padding: 11px 0;
        }
    }
    button.view-detail-btn {
        font-size: 16px;
    }
    .bottom_btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        margin-top: 6px;
    }
    .event_label {
        border-radius: 11px;
        background-color: rgba($color: #45c48a, $alpha: 0.1);
        color: #45c48a;
        font-size: 12px;
        line-height: 1;
        padding: 4px 10px 6px;
        display: flex;
        align-items: center;
        img {
            max-width: 15px;
            margin-left: 10px;
        }
        &.invite {
            color: #de5347;
            background-color: rgba($color: #de5347, $alpha: 0.1);
        }
    }
}

.rd_clobuttontheven,
.rd_parameterbuttontheven,
.rd_eyebuttontheven,
.rd_deletebuttontheven {
    margin-left: 10px;
}

.rd_sahredownbtn button {
    justify-content: center;
}

.rdmodaldashicontim {
    width: 15px;
    height: 20px;
    margin-right: 5px;
}

.rd_zoomicon {
    margin-right: 5px;
}

.progress-points {
    @media (max-width: 991px) {
        display: flex;
        overflow-x: scroll;
    }
    @media (max-width: 991px) and (min-width: 768px) {
        justify-content: center;
    }
    .progress-point {
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
            flex-direction: column;
            position: relative;
            min-width: 125px;
            white-space: nowrap;
            padding: 0 0px 15px 0;
            margin-bottom: 0 !important;
            &::after {
                content: "";
                position: absolute;
                background: $brandDisable;
                width: 100%;
                height: 1px;
                top: 22px;
                left: 35%;
            }
            &:last-of-type {
                padding-right: 0;
                &::after {
                    content: none;
                }
            }
        }
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 991px) {
                z-index: 3;
                margin: 0 0 8px 0 !important;
                &::after {
                    content: none !important;
                }
            }
        }
        &.active {
            span {
                background-color: #fff;
            }
        }
    }
}

.react-calendar__tile {
    padding: 16px 6.6667px !important;
}

.add-time-slot-container,
.rs-timeslot-container {
    height: 445px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    align-items: flex-start;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: $brandPrimary;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(12, 71, 103, 0.2);
        border-radius: 10px;
    }
    .slot {
        height: max-content !important;
    }
}

.rs-timeslot-container {
    height: 390px;
}

.rd_emebedparinmodl {
    .add-time-slot-container {
        height: max-content;
        overflow: auto;
    }
}

.add-time-slot-container {
    &.custom_slot {
        .time_active {
            background-color: #fff !important;
            color: $brandPrimary;
            border: 1px solid $brandPrimary;
        }
    }
    &.addTimes {
        height: 500px;
    }
}

// Quick Setup Screen
.profile-step {
    label {
        font-size: 16px;
        font-weight: 500;
        color: $brandDark;
    }
    .step {
        img {
            max-width: 20px;
        }
    }
    &.signup_proc {
        padding-top: 30px !important;
        padding-bottom: 10px !important;
        max-width: 1170px;
        @media (max-width: 767px) {
            form {
                padding: 25px 20px 0;
            }
        }
        h1 {
            margin-bottom: 15px;
        }
        .by-step {
            padding-top: 16px;
            margin-top: 20px;
            background-size: 95%;
        }
        .company-logo .btn {
            height: 54px;
        }
        .meetocto-link {
            input {
                height: 72px !important;
            }
        }
        .invite-friend {
            p {
                display: flex;
                align-items: center;
            }
        }
        .submit-btns {
            justify-content: flex-end;
            @media (max-width: 767px) {
                flex-direction: column;
            }
            button,
            a {
                max-width: 234px;
                @media (max-width: 767px) {
                    max-width: 100%;
                }
            }
        }
    }
}

// Scheduled Events
.rd_selectheaderrdt2 {
    button {
        padding-bottom: 8px;
        &.active {
            font-weight: 700;
        }
    }
}

.rd_pasevencontth {
    h2 {
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        color: $brandDark;
        span {
            width: 25px;
            height: 21px;
            margin-right: 5px;
        }
    }
}

.rd_eventcardscontainer {
    margin-top: 10px;
    align-items: center;
}

.card-action {
    .collapse {
        label {
            font-size: 16px;
            font-weight: 500;
            color: $brandDark;
        }
    }
}

// Profile
.rd_vacationflex2 p,
.rd_vacationflex3 p,
.rd_imagethincontg p {
    margin-bottom: 12px;
}

.rd_vacationflex2 {
    .badge-sd {
        display: flex;
        width: max-content;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
    }
}

.rd_vacationflex3 {
    .rd_domainunofi {
        padding: 15.5px 10px;
        border-radius: 5px;
    }
    .rd_sahredownbtn {
        button {
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
        }
    }
}

.submit_btn {
    padding: 0 20px;
    text-align: right;
}

.rd_profilethingco {
    .ant-space {
        width: 100%;
        .ant-space-item {
            width: 33%;
        }
    }
    .ant-input-number {
        width: 100%;
        border: none;
        .ant-input-number-input-wrap {
            input {
                cursor: not-allowed;
                @media (min-width: 767px) {
                    pointer-events: none;
                }
            }
        }
        .ant-input-number-handler-wrap {
            opacity: 1;
            border: 1px solid #e0e0e0;
            border-left: 0;
        }
        .ant-input-number-handler-up,
        .ant-input-number-handler-down,
        .ant-input-number-handler-up:hover,
        .ant-input-number-handler-down:hover {
            height: 50% !important;
        }
    }
    select {
        height: 54px;
    }
    input.noshadfoc {
        padding-right: 5px !important;
    }
    .rd_dropdownbtn,
    .rd_sahredownbtn {
        button {
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
        }
    }
}

.settings_uploader {
    max-width: 350px;
}

.sort-dropdown {
    select {
        text-align: center;
        padding: 0 10px !important;
    }
}

.profile-step-three {
    .same-label {
        color: $brandPrimary;
    }
    .form-checks {
        padding-left: 8px;
    }
}

.event-modal {
    &.reschedule_popup {
        max-width: 870px !important;
        .rs-timeslot-container {
            max-height: 499px;
        }
        // .react-calendar {
        //     border: none;
        //     box-shadow: 0px 3px 6px 0px rgb(131 134 163 / 6%);
        // }
    }
    .modal-content {
        padding: 0 !important;
        .rd_modalheadertext {
            text-align: left;
        }
        @media (max-width: 767px) {
            .col-sm-6 {
                padding: 0;
            }
        }
    }
    .upcoming_details_bottom {
        margin-top: 12px;
        button {
            max-width: 170px;
            margin: 0 !important;
            @media (max-width: 991px) {
                max-width: 100%;
                width: 49%;
                margin-top: 10px !important;
            }
        }
        a,
        u {
            transition: 0.3s all ease;
            &:hover {
                color: $brandPrimary;
                transition: 0.3s all ease;
            }
        }
    }
}

.rd_modalheadertext {
    text-align: left;
}

.list-view-pagination,
.ant-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border-color: #e0e3e7 !important;
        @media (max-width: 767px) {
            width: 36px;
            height: 36px;
        }
        &.ant-pagination-options {
            width: auto;
            height: auto;
        }
        &:hover {
            a {
                color: $brandPrimary;
                transition: all 0.3s;
            }
        }
        a {
            padding: 0;
            transition: all 0.3s;
            color: $brandDark !important;
            &:hover {
                color: $brandPrimary !important;
            }
        }
        &.ant-pagination-item-active {
            a {
                color: $brandPrimary !important;
            }
        }
        button {
            display: flex !important;
            justify-content: center;
            align-items: center;
            border-radius: 6px !important;
            background: transparent;
        }
    }
}

.daterangepicker {
    td.active {
        background: $brandDark !important;
    }
    .drp-buttons {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: space-between;
        span {
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
        }
        button {
            display: inline-block;
            width: 48% !important;
            margin: 0 !important;
            &.applyBtn {
                @include btnStyle( $brandPrimary, $brandPrimary, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimaryHov, $brandPrimaryHov, $brandWhite);
            }
            &.cancelBtn {
                @include btnStyle( rgba($color: $brandDark, $alpha: 0.3), $brandWhite, $brandDark, $brandDisable, $brandLight, transparent, rgba($color: $brandDark, $alpha: 0.3), rgba($color: $brandDark, $alpha: 0.3), $brandDark);
                height: auto;
            }
        }
    }
}

.react-calendar {
    .react-calendar__tile {
        border-radius: 0 !important;
        margin-top: 5px;
    }
}

.rd_dashbackbutton {
    background-size: 9px;
    background-position: 12% 53%;
}

.rd_dashboardmodalfooterpartb {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button {
        width: 100%;
        max-width: 120px;
        &:first-of-type {
            @include btnStyle( $brandDark, $brandWhite, $brandDark, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandDark, $brandDark, $brandWhite);
        }
        &:last-of-type {
            @include btnStyle( $brandPrimary, $brandPrimary, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimaryHov, $brandPrimaryHov, $brandWhite);
        }
    }
}

.progress-points {
    li {
        &.active {
            color: $brandPrimary;
            span {
                color: $brandPrimary;
                border-color: $brandPrimary;
                background: transparent;
            }
        }
    }
}

.event-card {
    .event-card-body {
        .nav.nav-tabs {
            .nav-item {
                .nav-link {
                    border: none !important;
                    transform: translateY(-1px) !important;
                    &::after {
                        top: unset;
                    }
                    &.active {
                        &::after {
                            top: unset;
                            background: $brandPrimary !important;
                            transform: none !important;
                        }
                    }
                }
            }
        }
    }
}

.ant-table-cell {
    a {
        color: $brandDark !important;
    }
}

.rd_timeorderitem {
    align-items: center;
    .event-form-group {
        margin-bottom: 0 !important;
    }
}

// All Buttons
.buttonnotgcont {
    .rd_addneewnottopba {
        @include btnStyle( $brandPrimary, $brandPrimary, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimaryHov, $brandPrimaryHov, $brandWhite);
        padding: 5px 40px 5px 20px !important;
        @media (max-width: 1250px) {
            font-size: 12px !important;
            padding: 5px 34px 5px 15px !important;
        }
        a {
            line-height: 1;
        }
    }
}

// Outline Btn
.btn {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    padding: 14px 5px !important;
    // Primary Btn
    &.btn-app {
        @include btnStyle( $brandPrimary, $brandPrimary, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimaryHov, $brandPrimaryHov, $brandWhite);
        &.btn-secondary {
            @include btnStyle( $brandPrimary, $brandPrimary, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimaryHov, $brandPrimaryHov, $brandWhite);
        }
    }
    &.btn-outline {
        @include btnStyle( rgba($color: $brandDark, $alpha: 0.3), $brandWhite, $brandDark, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandDark, $brandDark, $brandWhite);
        &.brand {
            @include btnStyle( $brandPrimary, $brandWhite, $brandPrimary, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimary, $brandPrimary, $brandWhite);
        }
        &.btn-secondary {
            @include btnStyle( rgba($color: $brandDark, $alpha: 0.3), $brandWhite, $brandDark, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandDark, $brandDark, $brandWhite);
        }
    }
    &.btn-primary {
        @include btnStyle( $brandDark, $brandDark, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), #0f5880, #0f5880, $brandWhite);
    }
    &.btn-danger {
        border-color: #fc9834 !important;
        background-color: rgba(252, 152, 52, 0.1) !important;
        color: #fe9920 !important;
        padding: 14px 15px !important;
        font-size: 16px !important;
        &:hover {
            color: #fe9920 !important;
            border-color: #fc9834 !important;
        }
    }
    &.btn-success {
        border: solid 1px #45c48a !important;
        background-color: rgba(69, 196, 138, 0.1) !important;
        color: #45c48a !important;
        font-size: 16px !important;
        &:hover {
            color: #45c48a !important;
        }
    }
    &.btn_success {
        @include btnStyle( rgba($color: #00c875, $alpha: 0.1), rgba($color: #00c875, $alpha: 0.1), #00c875, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), #00c875, #00c875, $brandWhite);
    }
    &.btn-grey {
        @include btnStyle( $brandDisable, $brandLight, #626262, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), #0f5880, #0f5880, $brandWhite);
    }
    &.btn_no_show {
        border-radius: 50px !important;
    }
    &.btn_clear {
        padding: 0 !important;
        margin-top: 10px;
        color: #579cfb;
    }
    &.btn-14 {
        @media (max-width: 767px) {
            font-size: 13px !important;
        }
    }
}

.CustomButton {
    width: 100%;
    max-width: 180px;
    @include btnStyle( $brandDark, $brandDark, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), #0f5880, #0f5880, $brandWhite);
}

.rd_imagethincontgbtnt2,
.rd_svaebtn {
    button {
        @include btnStyle( $brandDark, $brandDark, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), #0f5880, #0f5880, $brandWhite);
    }
}

.rd_cancel {
    @include btnStyle( $brandDark, $brandDark, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandWhite, $brandDark, $brandDark);
}

.rd_capply,
.rd_searchButton {
    @include btnStyle( $brandPrimary, $brandPrimary, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimaryHov, $brandPrimaryHov, $brandWhite);
}

.rd_oranbuthth {
    @include btnStyle( $brandPrimary, $brandPrimary, $brandWhite, $brandDisable, $brandLight, rgba($color: $brandDark, $alpha: 0.4), $brandPrimaryHov, $brandPrimaryHov, $brandWhite);
}

select.form-control:not([multiple="multiple"]) {
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    color: #818181;
}

.rd_flexrowitem {
    .profile_dropdown {
        @media (max-width: 991px) {
            margin-top: 4px;
        }
        .dropdown-toggle {
            background-color: transparent !important;
            border: none !important;
            padding: 25px 0 0 !important;
            font-size: 12px;
            color: #626262 !important;
            @media (max-width: 991px) {
                padding: 0 !important;
                span {
                    display: inline-block;
                    width: 30px;
                    background-size: auto 20px;
                }
            }
            @media (max-width: 767px) {
                span {
                    width: 20px;
                    background-size: auto 18px;
                }
            }
            &:hover {
                color: #626262 !important;
                box-shadow: none !important;
            }
            &.inner_drop_toggle {
                padding: 0 !important;
            }
            .notifiexist {
                &::after {
                    right: 20px;
                }
            }
        }
        .noti_num {
            position: absolute;
            background: $brandPrimary;
            color: #fff;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            z-index: 1;
            right: 17px;
            top: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            line-height: 1;
            @media (max-width: 991px) {
                width: 14px;
                height: 14px;
                right: 5px;
                top: 2px;
                font-size: 10px;
            }
            @media (max-width: 767px) {
                width: 12px;
                height: 12px;
                right: 7px;
                top: 1px;
                font-size: 8px;
            }
        }
        .dropdown-menu:not(.inner_drop) {
            width: 550px;
            inset: 0px 0 auto -445% !important;
            .infinite-scroll-component {
                height: 500px !important;
                overflow-y: auto;
                padding-bottom: 0;
                &::-webkit-scrollbar {
                    width: 7px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $brandPrimary;
                    border-radius: 10px;
                }
            }
            @media (max-width: 991px) {
                position: fixed !important;
                width: 100%;
                height: calc(100vh - 104px);
                inset: 103px 0 0 0 !important;
                transform: none !important;
            }
            .drop_options {
                h2 {
                    color: $brandDark;
                    font-size: 20px;
                    @media (max-width: 767px) {
                        font-size: 18px;
                    }
                }
                label {
                    color: $brandDark;
                    font-size: 14px;
                    @media (max-width: 767px) {
                        font-size: 12px !important;
                    }
                }
            }
            .day {
                font-size: 16px;
                font-weight: 700;
                color: #626262;
                padding-bottom: 11px;
            }
            .notification-item {
                position: relative;
                padding: 1rem 1.428rem;
                &.unread {
                    &::after {
                        content: "";
                        position: absolute;
                        background: $brandPrimary;
                        border-radius: 50%;
                        width: 10px;
                        height: 10px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        right: 20px;
                    }
                }
                &:hover {
                    background: $brandLight;
                }
                .user_img {
                    width: 51px;
                    height: 51px;
                    border-radius: 50%;
                    overflow: hidden;
                    background: #f7f7f7;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 767px) {
                        width: 45px;
                        height: 45px;
                    }
                }
                .noti_icon {
                    width: 27px;
                    height: 27px;
                    background: #faf1e5;
                    border-radius: 50%;
                    border: 0.5px solid $brandPrimary;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 6px;
                    margin: -17px 0 0px auto;
                    position: relative;
                }
                .noti_txt {
                    max-width: 385px;
                    white-space: normal;
                    @media (max-width: 767px) {
                        width: calc(100% - 55px);
                    }
                    h4 {
                        font-weight: 600;
                        color: $brandDark;
                        font-size: 14px;
                    }
                    p {
                        font-size: 14px;
                        color: $brandDark;
                        margin: 5px 0;
                    }
                    .time {
                        font-size: 14px;
                        font-weight: 300 !important;
                        color: $brandPrimary;
                        margin: 0;
                    }
                    .noti_action {
                        display: flex;
                        margin-top: 10px;
                        button {
                            font-size: 14px !important;
                            max-width: 112px;
                            padding: 8px 15px !important;
                        }
                    }
                }
            }
            .view_all {
                text-align: center;
                a {
                    color: $brandPrimary;
                    font-size: 16px;
                    font-weight: 600;
                    display: block;
                    padding: 0.714rem 1.428rem;
                    transition: 0.3s all ease;
                    &:hover {
                        background: #f4f4f4;
                        transition: 0.3s all ease;
                    }
                }
            }
            .dropdown-divider {
                margin: 0;
            }
        }
    }
}

.accountdiccon {
    display: flex;
    align-items: center;
    .dropdown-menu {
        left: unset !important;
        right: 0px !important;
        top: 100% !important;
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
        transform: none !important;
        &::before {
            content: none !important;
        }
    }
    .rd_menuthingcont {
        width: auto;
    }
}

// Filters
.filter_bar {
    border-radius: 5px;
    background: #fff;
    border: 1px solid #e0e3e7;
    padding: 21px 20px;
    margin-bottom: 16px;
    &.with_btn {
        padding: 9px 20px;
        @media (max-width: 767px) {
            padding: 9px 15px;
            h3 {
                font-size: 18px;
            }
            .btn-add {
                width: 96px !important;
                padding: 14px 5px !important;
            }
        }
    }
    h3 {
        color: $brandDark;
        font-weight: 700;
    }
    // .btn-add {
    //     padding: 9px 15px !important;
    // }
    .filter_backdrop {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #000;
        opacity: 0.75;
        z-index: 1060;
        display: none;
        transition: 0.2s all ease;
        &.active {
            display: block;
            // left: 0;
            transition: 0.2s all ease;
            // transition-delay: .3s;
        }
    }
    .filter_content {
        .type_filt {
            label.form-check-label {
                font-size: 14px !important;
            }
        }
        position: fixed;
        right: -400px;
        top: 0;
        bottom: 0;
        width: 400px;
        height: 100%;
        background: #fff;
        z-index: 1060;
        padding: 18px 20px;
        overflow-y: auto;
        transition: 0.4s all ease;
        @media (max-width: 767px) {
            right: -100%;
            width: 100%;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: $brandPrimary;
            border-radius: 10px;
        }
        &.active {
            right: 0;
            transition: 0.4s all ease;
        }
        .top_bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid $brandDisable;
            h3 {
                font-weight: 600;
            }
            button {
                color: $brandPrimary;
                width: auto;
            }
        }
        .filter_items_container {
            padding: 25px 0 100px;
            label {
                color: $brandDark !important;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 14px;
            }
            .form-check {
                padding-left: 1.5rem;
            }
        }
        .bottom_bar {
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 0px;
            right: 0px;
            bottom: 25px;
            button {
                width: 48%;
            }
        }
    }
    &.dark {
        background: #f4f4f4;
        border-color: #e0e0e0;
    }
}

.itemcomcon {
    color: $brandDark !important;
    &:hover {
        color: $brandDark !important;
    }
}

.ag-grid-actions {
    width: max-content;
    @media (max-width: 767px) {
        margin: auto;
    }
    select {
        height: 34px !important;
    }
    span {
        font-size: 16px;
        color: $brandDark;
    }
}

.name_initial {
    font-size: 16px;
    border-radius: 50%;
    background: $brandPrimary;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    width: 45px;
    height: 45px;
    margin: -3px 0 0 -3px;
}

.ant-table-content {
    @media (max-width: 1199px) {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgba($color: $brandDark, $alpha: 0.2);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $brandPrimary;
            border-radius: 10px;
        }
    }
    @media (max-width: 767px) {
        white-space: nowrap;
    }
    // head
    tr {
        th,
        td {
            padding: 12px 16px;
        }
        th {
            font-size: 16px;
            background: rgba($color: $brandDark, $alpha: 0.1) !important;
            color: $brandDark !important;
            .ant-table-column-sorter {
                .ant-table-column-sorter-up,
                .ant-table-column-sorter-down {
                    color: #b0b0b0 !important;
                    &.active {
                        color: $brandDark !important;
                    }
                }
            }
        }
        td {
            font-size: 14px;
            color: $brandDark;
            border-bottom: 1px solid #e0e3e7 !important;
            @media (max-width: 767px) {
                font-size: 12px;
            }
            a.name {
                display: flex;
                align-items: center;
                width: max-content;
            }
            .name_initial {
                font-size: 14px;
                width: 33px;
                height: 33px;
                margin: 0 10px 0 0;
                display: flex;
                @media (max-width: 767px) {
                    font-size: 11px;
                    width: 26px;
                    height: 26px;
                }
            }
            .prof_img {
                width: 33px;
                height: 33px;
                border-radius: 50%;
                border: 1px solid $brandPrimary;
                margin-right: 10px;
                @media (max-width: 767px) {
                    width: 26px;
                    height: 26px;
                }
            }
            .actions {
                padding-left: 25%;
                .ant-dropdown-trigger {
                    max-width: 115px;
                }
            }
        }
    }
    .ant-dropdown-menu {
        a {
            display: flex;
            align-items: center;
        }
        li {
            .ant-dropdown-menu-title-content {
                color: #000;
                // span {
                //     display: flex;
                //     align-items: center;
                // }
            }
        }
    }
}

table {
    span.status {
        display: inline-block;
        line-height: 1;
        padding: 5px 7px;
        border-radius: 5px;
        &.deactive {
            color: #b75d69;
            background: rgba($color: #b75d69, $alpha: 10%);
        }
        &.active {
            background: rgba($color: #fd9926, $alpha: 10%);
            color: #fd9926;
        }
        &.pending {
            background: rgba($color: $brandDark, $alpha: 10%);
            color: $brandDark;
        }
    }
}

.ant-dropdown-menu {
    a {
        color: $brandDark !important;
    }
    li {
        &:hover {
            a {
                color: $brandDark !important;
            }
        }
    }
}

section.all_noti {
    background: #fff;
    padding: 22px 30px;
    @media (max-width: 767px) {
        padding: 20px;
    }
    .day {
        display: block;
        font-size: 16px;
        font-weight: 700;
        color: #626262;
        margin-bottom: 28px;
    }
    .notification-item {
        position: relative;
        padding: 20px 0 15px;
        .user_img {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            background: #f7f7f7;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 991px) {
                width: 55px;
                height: 55px;
            }
        }
        .noti_icon {
            width: 33px;
            height: 33px;
            background: #faf1e5;
            border-radius: 50%;
            border: 0.5px solid #fe9920;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px;
            margin: -20px 0 0px auto;
            position: relative;
            @media (max-width: 991px) {
                width: 27px;
                height: 27px;
            }
        }
        .noti_txt {
            width: calc(100% - 80px);
            @media (max-width: 991px) {
                width: calc(100% - 65px);
            }
            white-space: normal;
            h4 {
                font-weight: 600;
                color: #0c4767;
                font-size: 18px;
                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
            p {
                font-size: 16px;
                color: #0c4767;
                margin: 5px 0;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
            .time {
                font-size: 14px;
                font-weight: 300 !important;
                color: #fe9920;
                margin: 0;
            }
            .noti_action {
                display: flex;
                margin-top: 20px;
                button {
                    max-width: 150px;
                    padding: 8px 15px !important;
                }
            }
        }
    }
    .divider {
        border-top: 1px solid #ededed;
    }
    .no_more_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
            width: 50px;
            height: 50px;
            background: #faf1e5;
            border-radius: 50%;
            border: 0.5px solid #fe9920;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 20px;
            }
        }
        p {
            font-size: 18px;
            color: $brandDark;
            margin-top: 16px;
            max-width: 407px;
            text-align: center;
        }
    }
    .more_container {
        .btn {
            width: auto;
            color: $brandPrimary;
        }
    }
}

.toggle_field {
    display: flex;
    align-items: center;
    label.cus_label {
        font-size: 14px;
        color: #707070;
        margin-right: 30px;
        span {
            display: block;
            font-size: 12px;
            font-weight: 300;
        }
    }
    input[type="checkbox"] {
        background-color: rgba($color: #777778, $alpha: 0.25) !important;
        background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27%23777778%27/%3E%3C/svg%3E") !important;
        &:checked {
            background-color: rgba($color: $brandPrimary, $alpha: 0.25) !important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3E%3Ccircle r=%273%27 fill=%27%23FE9920%27/%3E%3C/svg%3E") !important;
        }
    }
    .box_content {
        padding: 0;
    }
    &.un-checked+label.cus_label {
        color: #000;
    }
    &.big {
        margin-bottom: 15px;
        label {
            font-size: 16px;
            font-weight: 500;
            color: $brandDark;
            margin-bottom: 0;
            span.tooltip-wrapper {
                display: inline-block;
            }
        }
        // .form-check-input {
        //     width: 3.2em;
        //     height: 1.8em;
        // }
    }
}

section.notif_settings {
    padding: 20px 20px;
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba($color: #8386a3, $alpha: 6%);
    border-radius: 5px;
    .setting_field {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
            flex-direction: column;
        }
        .setting_name {
            max-width: 585px;
            @media (max-width: 991px) {
                max-width: 100%;
                width: calc(100% - 195px);
            }
            @media (max-width: 767px) {
                width: 100%;
                margin-bottom: 15px;
            }
            h4 {
                font-weight: 600;
                color: $brandDark;
                margin-bottom: 10px;
            }
            p {
                font-size: 16px;
                color: #707070;
                font-weight: 400 !important;
                @media (max-width: 767px) {
                    font-size: 14px;
                }
            }
        }
        .toggle_field {
            justify-content: flex-end;
            @media (max-width: 991px) {
                width: 189px;
            }
            @media (max-width: 767px) {
                width: 100%;
                justify-content: flex-start;
                .box_content {
                    width: 100%;
                }
            }
        }
    }
    .divider {
        border-top: 1px solid #ededed;
    }
}

// Dynamic Toggle Field Settings
.toggle-field {
    .react-switch-bg {
        background-color: rgba($color: #626262, $alpha: 0.25) !important;
    }
    .react-switch-handle {
        background: #7c7c7c !important;
    }
    &.checked {
        .react-switch-bg {
            background-color: rgba($color: $brandPrimary, $alpha: 0.25) !important;
        }
        .react-switch-handle {
            background: $brandPrimary !important;
        }
    }
}

// error Pages
section.error_page {
    background-color: #fff;
    .row {
        height: 100vh;
    }
    .error_column {
        text-align: center;
        img {
            max-width: 369px;
            &.img_404 {
                max-width: 275px;
            }
        }
        h1 {
            color: $brandPrimary;
            font-size: 38px;
            margin: 30px 0 15px;
        }
        p {
            font-size: 16px;
            font-weight: 400 !important;
            color: $brandDark;
        }
    }
}

// Event Summary Page
.event_summarry {
    h2 {
        font-size: 20px;
        color: $brandDark;
        font-weight: 700;
        display: flex;
        align-items: center;
    }
    .eve_status {
        font-size: 14px;
        border-radius: 2px;
        color: #fff;
        line-height: 1;
        padding: 1px 10px 4px;
        margin-left: 10px;
        &.free {
            background: $brandPrimary;
        }
        &.paid {
            background: #45c48a;
        }
    }
    .more_btn {
        color: rgba($color: #707070, $alpha: 0.86);
        padding: 0 !important;
        width: max-content;
    }
    .ant-collapse {
        border: none;
        .ant-collapse {
            background: #fff;
            // .ant-collapse-item {
            //     border-radius: 5px 5px 0 0 !important;
            // }
        }
    }
    .ant-collapse-item {
        background: #fff;
        margin-bottom: 20px;
        border: 1px solid $brandDisable !important;
        border-radius: 5px !important;
    }
    .ant-collapse-header {
        align-items: center;
        h2 {
            color: $brandPrimary;
            display: flex;
            align-items: center;
            line-height: 1;
            a {
                margin-left: 6px;
                margin-top: -2px;
            }
        }
        .ant-collapse-arrow {
            vertical-align: 1px !important;
        }
    }
    .ant-collapse-content {
        border-top: 1px solid $brandDisable !important;
        border-radius: 0 0 5px 5px;
        .ant-collapse-content-box {
            padding: 25px 25px 30px;
            .ant-collapse-content-box {
                @media (max-width: 767px) {
                    padding: 25px 10px;
                }
            }
        }
        .eve_url {
            @media (max-width: 991px) {
                order: 1;
                margin-top: 18px;
            }
        }
        h3 {
            font-size: 16px;
            color: rgba($color: #707070, $alpha: 0.86);
            margin-bottom: 10px;
            @media (max-width: 767px) {
                font-size: 15px;
                &.payment_req {
                    width: 100%;
                    margin-bottom: 12px;
                }
            }
        }
        p {
            color: $brandDark;
            font-weight: 400 !important;
            word-break: break-word;
            @media (max-width: 767px) {
                font-size: 15px;
            }
            &.event_des {
                color: #626262;
                word-break: break-word;
            }
            &.prof_img {
                span {
                    width: 26px;
                    height: 26px;
                    img {
                        border-radius: 50%;
                    }
                }
            }
            span {
                display: inline-block;
                margin-right: 8px;
                img {
                    max-width: 20px;
                }
            }
            .custom_circle {
                border-radius: 50%;
                background: #45c48a;
                width: 10px;
                height: 10px;
            }
            a {
                color: $brandDark;
                text-decoration: underline !important;
                word-wrap: break-word;
            }
        }
        .main_row {
            border-bottom: 1px solid rgba($color: #707070, $alpha: 0.1);
            padding-bottom: 20px;
            margin-bottom: 20px;
            &:last-of-type {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: 0;
            }
            &:first-of-type {
                border-bottom: 1px solid rgba($color: #707070, $alpha: 0.1);
                padding-bottom: 20px;
                margin-bottom: 20px;
            }
        }
        .event_img {
            img {
                max-width: 130px;
                border-radius: 5px;
                min-height: 130px;
                object-fit: cover;
                @media (max-width: 767px) {
                    display: block;
                    margin: auto;
                    max-width: 80px;
                    min-height: 80px;
                }
            }
        }
        .event_clr {
            span {
                display: block;
                border-radius: 5px;
                width: 130px;
                height: 100%;
                // max-width: 130px;
                max-height: 130px;
                @media (max-width: 767px) {
                    width: 80px;
                    height: 80px;
                    margin: auto;
                }
            }
        }
        .allow_row {
            margin-top: 20px;
            p {
                span {
                    margin-left: 15px;
                    margin-right: 0;
                    vertical-align: 1px;
                }
            }
        }
        .price_row {
            .col-md-6 {
                @media (min-width: 768px) {
                    max-width: 47%;
                }
            }
        }
        .custom_tabs {
            background: transparent;
            box-shadow: none;
            margin-bottom: 30px;
            button {
                background: transparent;
                border: none;
                border-radius: 0;
                width: auto;
                font-weight: 500;
                color: #626262;
                padding: 0 25px 17px !important;
                border-bottom: 2px solid $brandDisable;
                &.active {
                    color: $brandDark;
                    border-color: $brandPrimary;
                }
            }
        }
        .advance_tab {
            margin-top: -24px;
            .col-lg-3 {
                margin-top: 24px;
                @media (max-width: 767px) {
                    margin-top: 16px;
                }
            }
            p {
                color: rgba($color: #707070, $alpha: 0.86);
                span {
                    color: rgba($color: $brandDark, $alpha: 0.86);
                }
            }
        }
        .week_days {
            p {
                &:not(:last-of-type) {
                    margin-bottom: 12px;
                }
                &:not(.week_title) {
                    color: rgba($color: #626262, $alpha: 0.85);
                }
            }
        }
    }
    .availability_tab {
        .main_row {
            margin-bottom: 0;
            border-bottom: 0;
        }
        .week_days {
            margin-bottom: 15px;
            @media (max-width: 1600px) {}
        }
    }
}

.rd_octolink {
    background-image: url(../icons/copy_icon.svg);
}

// Calender
// .rbc-calendar {
//     .rbc-toolbar {
//         .rbc-btn-group {
//             button {
//                 font-size: 16px;
//                 background-color: transparent !important;
//                 color: $brandDark !important;
//                 height: auto;
//                 border: none;
//                 border-radius: 0;
//                 box-shadow: none;
//                 border-bottom: 1px solid $brandDisable;
//                 padding-bottom: 12px;
//                 &.rbc-active {
//                     border-bottom: 2px solid $brandPrimary;
//                 }
//             }
//         }
//     }
//     .rbc-row-segment {
//         padding-right: 0 !important;
//     }
//     .rbc-event {
//         padding: 0 !important;
//         background: transparent !important;
//         border-radius: 0 !important;
//         .rbc-event-label {
//             display: none;
//         }
//     }
//     .rbc-month-header {
//         .rbc-header {
//             padding: 14px 3px;
//             font-size: 14px;
//             font-weight: 400;
//             text-transform: uppercase;
//         }
//     }
//     .rbc-date-cell {
//         &.rbc-now {
//             button {
//                 color: #fff;
//                 background: $brandDark;
//                 border-radius: 50%;
//                 width: 26px;
//                 height: 26px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//             }
//         }
//     }
//     // Week and Day Views
//     .rbc-time-view {
//         .rbc-row {
//             min-height: 60px;
//             align-items: center;
//         }
//         .rbc-time-header {
//             .rbc-time-header-content {
//                 border: none;
//             }
//             .rbc-allday-cell {
//                 display: none;
//             }
//             .rbc-header {
//                 border: none;
//                 button {
//                     font-size: 14px;
//                     color: $brandDark;
//                 }
//                 &.rbc-today {
//                     background-color: transparent;
//                     button {
//                         background: $brandDark;
//                         color: #fff;
//                         padding: 5px 10px;
//                         border-radius: 15px;
//                     }
//                 }
//             }
//         }
//         .rbc-time-content {
//             .rbc-today {
//                 background-color: transparent;
//             }
//             .rbc-current-time-indicator {
//                 height: 3px;
//                 background-color: $brandPrimary;
//                 &::before {
//                     content: '';
//                     position: absolute;
//                     top: 0;
//                     bottom: 0;
//                     right: 99%;
//                     margin: auto;
//                     width: 11px;
//                     height: 11px;
//                     background: $brandPrimary;
//                     border-radius: 50%;
//                 }
//             }
//         }
//         .cal_event_detail {
//             align-items: flex-start;
//             max-width: 184px;
//         }
//         .rbc-events-container {
//             margin-right: 0;
//             .rbc-event {
//                 border: none;
//             }
//         }
//         .rbc-timeslot-group {
//             min-height: 90px;
//         }
//     }
// }
.cal_event_detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 7px;
    width: 100%;
    // height: 100%;
    margin-bottom: 5px;
    cursor: pointer;
    @media (max-width: 991px) {
        flex-direction: column;
    }
    @media (max-width: 767px) {
        padding: 6px 3px;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
    &:not(.rbc-time-view) {}
    h3 {
        font-size: 11px;
        font-weight: 400;
        margin-bottom: 5px;
        max-width: 118px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media (max-width: 767px) {
            font-size: 11px;
        }
    }
    p {
        font-size: 9px;
        line-height: 1;
        font-weight: 600 !important;
        text-align: left;
        margin: 0;
        @media (max-width: 767px) {
            font-size: 10px;
        }
    }
    .author_img {
        @media (max-width: 991px) {
            margin-top: 10px;
        }
        @media (max-width: 767px) {
            margin: 0 8px 0 0;
        }
        img.eve_img {
            border-radius: 50%;
            width: 36px;
            height: 36px;
            @media (max-width: 767px) {
                width: 28px;
                height: 28px;
            }
        }
        img.icon {
            max-width: 12px;
            margin-right: 5px;
        }
    }
}

// Calendar
@media (max-width: 1600px) {
    .rd_calendartabthing2 {
        &>.box_content {
            padding: 0;
        }
    }
}

.custom_calendar {
    padding: 30px;
    background: #fff;
    @media (max-width: 1600px) {
        padding: 30px 0;
    }
    &.adhoc_calendar {
        .toolbar {
            .calendar_view {
                display: none;
                &:last-of-type {}
            }
            .calnder_month {
                @media (max-width: 991px) and (min-width: 768px) {
                    padding-left: 35px;
                }
            }
        }
    }
    .toolbar {
        margin-bottom: 20px;
        .calnder_month {
            @media (max-width: 991px) and (min-width: 768px) {
                padding: 0;
                .col-auto {
                    &:first-of-type,
                    &:last-of-type {
                        padding: 0;
                    }
                }
            }
            @media (max-width: 767px) {
                margin: 15px 0;
                width: 100%;
                .col-auto {
                    &:nth-of-type(2) {
                        width: calc(100% - 124px);
                        text-align: center;
                    }
                }
            }
            button {
                padding: 0 !important;
                line-height: 1;
                border-radius: 2px;
                background: none;
                svg {
                    @media (max-width: 991px) {
                        // transform: scale(0.6);
                        width: 30px;
                        height: 30px;
                    }
                }
            }
            h3 {
                font-weight: 700;
                color: $brandDark;
                @media (max-width: 991px) {
                    font-size: 16px;
                }
            }
        }
        .calendar_view {
            @media (max-width: 767px) {
                width: 100%;
            }
            button {
                font-size: 16px;
                font-weight: 500;
                color: $brandDark !important;
                width: auto;
                border: none;
                border-radius: 0;
                box-shadow: none;
                line-height: 1;
                border-bottom: 1px solid $brandDisable;
                padding: 14px 15px !important;
                &.active {
                    border-bottom: 2px solid $brandPrimary;
                }
                @media (max-width: 991px) {
                    font-size: 13px;
                    padding: 14px 10px !important;
                }
                @media (max-width: 767px) {
                    width: 33.33%;
                }
            }
        }
    }
    .calendar_wrapper {
        border: 1px solid #ddd;
        .calendar_header {}
        .calendar_head {
            width: 14.286%;
            font-size: 14px;
            color: #92929d;
            text-transform: uppercase;
            text-align: center;
            padding: 13px 5px;
            @media (max-width: 767px) {
                font-size: 10px;
            }
        }
        .head_empty {
            width: 95.54px;
            @media (max-width: 991px) {
                width: 70px;
            }
            @media (max-width: 767px) {
                width: 58px;
            }
        }
        .calendar_head_main {
            display: flex;
            width: calc(100% - 90px);
            @media (max-width: 991px) {
                width: calc(100% - 70px);
            }
            @media (max-width: 767px) {
                width: calc(100% - 58px);
            }
        }
        .calendar_body {
            display: flex;
            flex-wrap: wrap;
        }
        .disable,
        .next_month {
            background: #f8f8f8;
            cursor: not-allowed;
            .day {
                color: #92929d !important;
            }
            * {
                cursor: not-allowed;
            }
        }
        .calendar_day {
            width: 14.286%;
            padding-top: 15px;
            display: flex;
            flex-direction: column;
            min-height: 170px;
            overflow-y: hidden;
            border-top: 1px solid #ddd;
            border-right: 1px solid #ddd;
            position: relative;
            @media (max-width: 767px) {
                padding-top: 10px;
                min-height: unset;
            }
            &:nth-of-type(7),
            &:nth-of-type(14),
            &:nth-of-type(21),
            &:nth-of-type(28),
            &:nth-of-type(35) {
                border-right: none;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            .day {
                font-size: 14px;
                color: $brandDark;
                border-radius: 50%;
                width: 26px;
                height: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 0 10px 10px;
                line-height: 1;
                @media (max-width: 767px) {
                    font-size: 10px;
                    width: 17px;
                    height: 17px;
                    margin: 0 0 10px 5px;
                }
                &.today {
                    background: $brandDark;
                    color: #fff;
                }
            }
        }
        .time_slot {
            width: 95px;
            height: 141.5px;
            @media (max-width: 991px) {
                width: 70px;
            }
            @media (max-width: 767px) {
                width: 58px;
            }
            p {
                font-size: 14px;
                color: #171725;
                font-weight: 500;
                line-height: 1;
                margin-top: -6px;
                @media (max-width: 767px) {
                    font-size: 10px;
                }
            }
            span {
                color: #92929d;
                padding-left: 5px;
                @media (max-width: 767px) {
                    padding-left: 2px;
                }
            }
        }
        .slick-slider {
            .slick-arrow {
                &::before {
                    color: #000;
                }
            }
        }
        .day_slots_main {
            display: flex;
        }
        .btn_more {
            background: $brandPrimary;
            color: #fff;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 40px;
            height: 40px;
            line-height: 1;
            font-size: 12px;
            @media (max-width: 767px) {
                width: 30px;
                height: 30px;
                font-size: 8px;
            }
        }
        &.week_wrapper {
            border: none;
            .day_slots_main {
                width: calc(100% - 95px);
                @media (max-width: 991px) {
                    width: calc(100% - 70px);
                }
                @media (max-width: 767px) {
                    width: calc(100% - 58px);
                }
            }
            .calendar_head {
                display: flex;
                flex-direction: column;
                align-items: center;
                .date {
                    color: #171725;
                    line-height: 1;
                    border-radius: 50%;
                    width: 26px;
                    height: 26px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media (max-width: 767px) {
                        width: 20px;
                        height: 20px;
                    }
                }
                &.active {
                    .date {
                        background: $brandDark;
                        color: #fff;
                    }
                }
            }
            .calendar_body {
                flex-wrap: nowrap;
            }
            .cal_day_slots {
                width: 14.286%;
                .day_slot {
                    position: relative;
                }
                &:first-of-type {
                    .day_slot {
                        position: relative;
                        &::after {
                            content: "";
                            background: rgba($color: #707070, $alpha: 0.2);
                            width: 30px;
                            height: 1px;
                            position: absolute;
                            top: -1px;
                            left: -30px;
                        }
                    }
                }
            }
            .day_slot {
                border-top: 1px solid rgba($color: #707070, $alpha: 0.2);
                height: 136px;
                @media (max-width: 767px) {
                    .week_more {
                        font-size: 8px;
                        line-height: 1;
                    }
                }
            }
            &.adhoc_calendar {
                .calendar_head {
                    border-left: 1px solid rgba($color: #707070, $alpha: 0.2);
                }
                .day_slot {
                    border-left: 1px solid rgba($color: #707070, $alpha: 0.2);
                    &.disabled {
                        background-color: #f1f1f1;
                    }
                }
            }
        }
        &.day_wrapper {
            border: none;
            margin-top: 40px;
            @media (max-width: 767px) {
                .time_slot {
                    width: 30px;
                }
            }
            .slick-slider {
                width: calc(100% - 95px);
            }
            .cal_event_detail {
                width: 184px;
                margin-right: 10px;
                @media (max-width: 991px) {
                    flex-direction: row;
                }
            }
            .day_slots_main {
                flex-direction: column;
                width: calc(100% - 95px);
                overflow-x: scroll;
                @media (max-width: 991px) {
                    width: calc(100% - 70px);
                }
                @media (max-width: 767px) {
                    width: calc(100% - 30px);
                }
                &::-webkit-scrollbar {
                    height: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $brandDark;
                    border-radius: 10px;
                }
            }
            .cal_day_slots {
                width: 100%;
                height: 90px;
                border-top: 1px solid rgba($color: #707070, $alpha: 0.2);
                position: relative;
                display: flex;
                //width: 2000px;
                &::after {
                    content: "";
                    background: rgba($color: #707070, $alpha: 0.2);
                    width: 30px;
                    height: 1px;
                    position: absolute;
                    top: -1px;
                    left: -30px;
                }
            }
            .time_slot {
                height: 95px;
                p {
                    margin-top: -5.5px;
                }
            }
        }
        .slick-slide {
            padding: 0 5px;
        }
    }
}

// Event Details Modal
.event_modal {
    .modal-dialog {
        max-width: 625px;
    }
    .modal-content {
        background: #fff !important;
        box-shadow: 0 9px 26px rgba($color: #000000, $alpha: 0.08);
    }
    .modal-header {
        position: relative;
        background: #fff !important;
        border-bottom: 1px solid rgba($color: #e0e3e7, $alpha: 0.4) !important;
        padding-top: 34px !important;
        .modal-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            button {
                width: auto;
                img {
                    width: auto;
                    height: 20px;
                }
            }
        }
        .btn-close {
            position: absolute;
            right: 8px;
            top: 6px;
        }
    }
    .modal-body {
        padding-bottom: 20px;
        h4 {
            font-weight: 600;
            color: $brandDark;
            margin-bottom: 11px;
        }
        p {
            font-size: 14px;
            font-weight: 700;
            color: $brandDark;
            margin-bottom: 6px;
            span {
                color: #626262;
                font-weight: 500;
            }
        }
    }
    .modal-footer {
        padding-top: 15px;
        padding-bottom: 15px;
        border-color: rgba($color: #e0e3e7, $alpha: 0.4) !important;
        .row {
            margin-right: -15px;
            margin-left: -15px;
        }
        .author {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
            p {
                font-size: 14px;
                color: $brandDark;
                margin-left: 8px;
            }
        }
        .slot {
            margin-top: 10px;
            border: 1px solid $brandPrimary;
            border-radius: 5px;
            background: $brandPrimary;
            color: #fff;
            font-weight: 400 !important;
            font-size: 15px;
            text-align: center;
            padding: 9px 2px;
        }
    }
}

.clr_picker {
    label {
        color: #626262;
        font-size: 16px;
        margin: 0;
    }
    .clr_dropdown {
        margin-left: 20px;
        border-radius: 5px;
        border: 1px solid #707070;
        padding: 7px;
        width: 100%;
        max-width: 63px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
            border: none !important;
            padding: 0 !important;
            border-radius: 5px !important;
            width: 30px !important;
            height: 30px !important;
        }
    }
}

.modal {
    @media (max-width: 767px) {
        // padding: 0 !important;
        .modal-dialog {
            margin: 0 auto;
        }
    }
    .modal-title {
        font-size: 20px;
        font-weight: 700 !important;
        color: $brandDark;
    }
    .modal-header {
        background-color: transparent !important;
    }
    // Embed modal
    .clr_settings {
        padding: 21px 15px 25px 0;
        @media (max-width: 767px) {
            padding-left: 15px;
        }
        .clr_field_width {
            max-width: 300px;
            margin-bottom: 20px;
        }
    }
    .embed_other_modal {
        .rd_modalvieew {
            max-width: 100%;
            max-height: unset;
            overflow: unset;
        }
        .clr_settings {
            .inner {
                &>p {
                    margin-bottom: 30px;
                }
            }
        }
        .rd_emebedparinmodl {
            height: 100%;
            @media (min-width: 768px) {
                margin-left: 0;
            }
            textarea {
                height: auto !important;
            }
        }
    }
    .embed_code_modal,
    .embed_other_modal {
        max-width: 975px;
    }
    .embed_other_modal {
        .modal-body {
            @media (max-width: 767px) {
                padding-left: 0;
            }
        }
        .rd_dashboardmodalfooterpart {
            @media (max-width: 767px) {
                flex-direction: column-reverse;
                .rd_dashboardmodalfooterpartb {
                    margin-bottom: 15px;
                    button {
                        width: 47%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .embed_code_modal {
        .modal-header {
            padding: 19px 40px;
            align-items: center;
            border-bottom: 1px solid $brandDisable;
            border-radius: 0;
            @media (max-width: 767px) {
                padding: 20px 15px;
            }
        }
        .modal-title {
            font-size: 18px !important;
            text-align: left !important;
        }
        .modal-body {
            padding: 0 0 0 40px;
            @media (max-width: 767px) {
                padding-left: 0;
            }
            h4 {
                font-size: 16px;
                font-weight: 700;
                color: $brandDark;
            }
            p {
                font-size: 16px;
                font-weight: 400 !important;
                color: #626262;
                margin: 10px 0 30px;
            }
            .rd_modalvieewdashboard {
                max-width: 100%;
                margin: 0;
                overflow-x: hidden;
            }
            .code {
                padding: 0;
                .rd_modalboxcontent {
                    padding: 0;
                }
                .rd_emebedparinmodl {
                    margin: 0;
                    padding: 21px 40px 25px;
                    @media (max-width: 767px) {
                        padding: 22px 15px;
                    }
                    textarea {
                        height: 240px !important;
                        margin: 0 !important;
                    }
                }
            }
        }
    }
    // promo Popup
    .addPromo_modal {
        max-width: 720px;
    }
    // view more popup
    .more_eve_popup {
        max-width: 216px;
        .modal-header {
            border-bottom: 1px solid $brandDisable;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        .modal-title {
            font-size: 14px;
            font-weight: 500 !important;
        }
        .btn-close {
            background-size: 10px;
        }
        .eve_slot {
            width: 100%;
            border: none;
            text-align: left;
            border-left-width: 3px;
            border-left-style: solid;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            font-size: 14px;
            padding: 6px 10px;
            margin-bottom: 6px;
        }
    }
    .hocConfirmPopup {
        max-width: 550px;
        .modal-title {
            font-size: 30px;
            font-weight: 600 !important;
            p {
                font-size: 14px;
                font-weight: 400 !important;
                color: #626262;
            }
        }
        .modal-header,
        .modal-body {
            padding: 20px 40px;
        }
        .modal-body {
            padding-top: 0;
            padding-bottom: 40px;
        }
        .copy_url {
            button {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                height: 100%;
                box-shadow: none !important;
            }
        }
        .or {
            text-align: center;
            border-top: 1px solid rgba($color: #707070, $alpha: 0.2);
            margin: 25px 0;
            span {
                display: block;
                color: $brandDark;
                background-color: #fff;
                margin: -14px auto 0;
                padding: 0 13px;
                width: max-content;
            }
        }
        .copy_times {
            text-align: center;
            img {
                max-width: 46px;
            }
            h3 {
                font-weight: 600 !important;
                color: $brandDark;
                margin: 8px 0;
            }
            p {
                font-size: 14px;
                color: #626262;
                margin-bottom: 24px;
            }
            button {
                background: transparent !important;
                color: $brandPrimary !important;
                max-width: 233px;
            }
        }
    }
}

// Payment Settings
[class*="pay-method-"] {
    margin-bottom: 50px;
    label {
        font-size: 16px;
        color: $brandDark;
        margin-bottom: 10px;
        &.form-check-label {
            margin-bottom: 0;
        }
    }
}

.pay-method-paypal {
    .toggle_field {
        .form-check {
            width: max-content;
            max-width: unset;
            label {
                margin-right: 50px;
            }
            @media (max-width: 767px) {
                width: 100%;
                label {
                    margin-right: 0;
                }
            }
        }
    }
}

.input-group-prepend,
.input-group-append {
    &>select {
        padding: 0 !important;
        text-align: center;
    }
}

.rd_profilerd_erpart {
    h3 {
        font-weight: 700;
        color: $brandDark;
    }
}

h3.custom_title {
    font-weight: 700;
    color: $brandDark;
    border-bottom: 1px solid rgba($color: #707070, $alpha: 0.1);
    padding-bottom: 20px;
    margin-bottom: 20px;
}

h3.page_title {
    font-weight: 700;
    color: $brandDark;
}

.cus_field {
    margin-bottom: 22px;
    label {
        font-size: 16px;
        color: $brandDark;
    }
}

label.form-label,
label.form-check-label {
    font-size: 16px;
    color: $brandDark;
    margin-bottom: 10px;
    @media (max-width: 767px) {
        font-size: 14px;
    }
}

.clr_field {
    margin-bottom: 22px;
    .form-check-label+div> {
        div+div {
            bottom: 0 !important;
            right: 0 !important;
        }
    }
}

.cus_uploader {
    .input-group {
        input {
            width: calc(100% - 110px) !important;
        }
        button {
            width: 110px;
        }
    }
}

.jodit-status-bar {
    display: none !important;
}

.btn.btn-icon {
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-select-selector {
    border-radius: 5px !important;
    min-height: 54px;
    .ant-select-selection-item {
        padding-top: 7px !important;
    }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #e0e0e0 !important;
}

.rd_vacationflex2 .info_txt,
.rd_vacationflex3 .info_txt,
.info_txt {
    margin: 5px 0 0;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: rgba($color: #b0b0b0, $alpha: 0.9) !important;
}

.user_card {
    a {
        max-width: 100%;
    }
}

.section_title {
    font-weight: 700;
    color: $brandDark;
}

.cus_tab {
    display: flex;
    list-style: none;
    @media (max-width: 767px) {
        justify-content: space-between;
    }
    li {
        margin-right: 35px;
        @media (max-width: 767px) {
            margin: 0;
        }
        button {
            font-size: 16px;
            color: $brandDark;
            border: 2px solid transparent;
            border-radius: 0;
            padding: 0 8px 14px !important;
            @media (max-width: 767px) {
                font-size: 14px;
            }
            &.active {
                border-bottom: 2px solid $brandPrimary;
                font-weight: 700;
            }
        }
        &:last-of-type {
            margin-left: 0;
        }
    }
}

.availibility_btn {
    button {
        max-width: 340px;
        @media (max-width: 767px) {
            max-width: 100%;
        }
    }
}

.staff_avail {
    @media (max-width: 767px) {
        [class*="col-lg-"] {
            padding: 0;
            &:last-of-type {
                margin-top: 20px;
            }
        }
    }
}

.template_wrapper {
    margin-right: 10px;
    // overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
}

button.avail_templates {
    padding: 12px 20px !important;
    font-size: 16px;
    color: $brandDark;
    border-radius: 5px;
    background: #fff;
    line-height: 1;
    max-width: 225px;
    min-width: 225px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 63px;
    &>span {
        max-width: 140px;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
    }
    p {
        font-size: 10px;
        color: $brandDark !important;
        font-weight: 300 !important;
        text-align: left !important;
    }
    img {
        max-width: 14px;
        margin-right: 12px;
    }
    .dropdown {
        display: none;
    }
    .elipsis {
        max-width: 5px;
        margin-right: 0;
    }
    &:last-of-type {
        margin-right: 0px;
    }
    &.active {
        justify-content: flex-start;
        color: $brandPrimary;
        border: 1px solid $brandPrimary;
        background: rgba($color: $brandPrimary, $alpha: 0.1);
        .dropdown {
            display: inline-block;
        }
    }
    &.more_btn {
        position: relative;
        padding: 20px 12px !important;
        height: 100%;
        min-width: auto;
        .elipsis {
            width: 5px;
        }
        .noti_num {
            position: absolute;
            font-size: 12px;
            line-height: 1;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            padding-bottom: 3px;
            background: $brandPrimary;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            top: -10px;
            right: -10px;
        }
    }
}

.more_drop {
    .dropdown-menu {
        inset: 0 11px auto auto !important;
        min-width: 300px !important;
        &::before {
            top: 18px !important;
            left: unset !important;
            right: -6px;
            transform: rotate(136deg) !important;
        }
        .dropdown-item {
            border-bottom: 1px solid #e0e0e0;
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
}

.availbility-container {
    .rd_realdatathingsheditem {
        max-width: 830px;
        margin: 0 auto 20px;
    }
}

.cmplt_prof_msg {
    background: rgba($color: $brandPrimary, $alpha: 0.2);
    border-radius: 5px;
    border: 1px solid $brandPrimary;
    max-width: 656px;
    margin: 0 auto 15px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    img {
        max-width: 20px;
        margin-right: 8px;
    }
    p {
        color: $brandPrimary;
        text-align: center;
    }
    &.subscription_msg {
        max-width: 100%;
        padding: 13px 20px;
        @media (max-width: 50px) {
            padding: 10px 5px;
        }
        img {
            max-width: 26px;
            margin-right: 15px;
            @media (max-width: 767px) {
                max-width: 20px;
                margin-right: 8px;
            }
            @media (max-width: 350px) {
                max-width: 15px;
            }
        }
        p {
            font-size: 20px;
            color: $brandDark;
            span {
                color: $brandPrimary;
            }
            @media (max-width: 991px) {
                font-size: 16px;
            }
            @media (max-width: 767px) {
                font-size: 12px;
            }
        }
    }
}

.rd_eventSummary {
    background-image: url(../icons/event_summary.svg);
    margin-left: 8px;
}

// Subscription Cards
.subscription_card {
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
    padding: 15px 15px;
    @media (max-width: 991px) {
        margin-bottom: 20px;
    }
    &.active {
        border-color: #00c875;
    }
    .cd_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $brandDisable;
        h3 {
            display: flex;
            align-items: center;
            color: $brandDark;
            font-weight: 700;
            @media (max-width: 991px) {
                font-size: 16px;
            }
            label {
                margin: 0 5px;
                color: rgba($color: #626262, $alpha: 0.5);
                font-size: 14px;
                font-weight: 400;
                @media (max-width: 767px) {
                    font-size: 12px;
                }
            }
            span {
                @media (max-width: 767px) {
                    margin: 4px 0 0;
                }
                svg {
                    vertical-align: inherit;
                    stroke: #b0b0b0;
                    @media (max-width: 767px) {
                        width: 12px;
                    }
                }
            }
        }
        .form-check-input {
            width: 34px;
            height: 19px;
            @media (max-width: 767px) {
                width: 30px;
                height: 16px;
            }
        }
    }
    label {
        margin: 0;
        @media (max-width: 991px) {
            font-size: 14px;
        }
    }
    p {
        font-size: 16px;
        font-weight: 400 !important;
        color: #626262;
        @media (max-width: 767px) {
            font-size: 14px;
        }
        span {
            font-weight: 600 !important;
            color: $brandDark;
        }
    }
    h2 {
        font-size: 30px;
        font-weight: 700;
        color: $brandPrimary;
        margin: 15px 0 30px;
        @media (max-width: 767px) {
            font-size: 25px;
            margin-bottom: 20px;
        }
    }
    button.btn {
        max-width: 160px;
        font-size: 18px;
        @media (max-width: 1199px) {
            max-width: 100%;
            margin-top: 10px;
        }
    }
}

.subscriptionpageaddtabtoppart {
    h6 {
        color: $brandDark;
        font-size: 20px;
        font-weight: 700;
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
}

.rd_imagethincontgbtnt2pixel button {
    @media (max-width: 767px) {
        font-size: 14px;
    }
}

.billing_info {
    background: #fff;
    box-shadow: 0px 3px 6px 0px rgba($color: #8386a3, $alpha: 6%);
    border-radius: 5px;
    padding: 15px 30px 25px;
    h3 {
        font-weight: 700;
        color: $brandDark;
        margin-bottom: 24px;
    }
    p {
        font-size: 18px;
        font-weight: 400 !important;
        color: #626262;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        margin-bottom: 18px;
        span {
            color: $brandDark;
        }
    }
}

.modal {
    .billing_modal {
        max-width: 720px;
        h3 {
            font-weight: 700;
            color: $brandDark;
            margin-bottom: 30px;
        }
        .rd_inputselectheadermodalsetting {
            padding: 13px 30px;
            height: auto;
            border-radius: 5px 5px 0 0;
            background: #f4f4f4;
            border-bottom: 1px solid #e0e0e0;
            h3 {
                margin: 0;
            }
        }
        .box_content {
            padding: 25px 30px;
        }
        .next_portion {
            margin-top: 20px;
        }
        input {
            width: 100%;
        }
        .ant-select-selection-placeholder {
            line-height: 48px;
        }
    }
}

.rd_vacationfilterpartema {
    .rd_inpunumtegitem {
        input {
            background: #fff !important;
            color: $brandDark !important;
        }
        .rd_dropdownbtnplus {
            button {
                border-top-right-radius: 5px !important;
                border-bottom-right-radius: 5px !important;
            }
        }
    }
    .select__control {
        height: auto;
    }
    input.select__input {}
}

// Promo Screen
.promo_screen {
    .rd_coupenthingitewm {
        padding: 0;
        @media (max-width: 991px) {
            width: 100%;
            margin-right: 0;
        }
    }
    @media (max-width: 767px) {
        .rd_conpbtpartth {
            flex-direction: column;
        }
        .rd_conpbtpartthitem {
            width: calc(100% - 30px);
            margin: 0 15px 20px;
        }
    }
    .rd_activeprdesa {
        border: none;
        background-color: #f2f2f2;
        .box_content {
            background-color: transparent;
        }
    }
}

// Custom COlor Picker
.cus_clrpick {
    display: flex;
    align-items: center;
    border-radius: 5px;
    background: #f4f4f4;
    padding: 8px 8px;
    position: relative;
    .clr_show {
        border-radius: 5px;
        width: 60px;
        height: 40px;
        margin-right: 10px;
        position: relative;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        &.active {
            &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 1px;
                width: 15px;
                height: 30px;
                border: solid #ffffff;
                border-width: 0px 3px 3px 0px;
                transform: rotate(45deg);
                margin: auto;
            }
        }
        .checkMark {
            max-width: 24px;
        }
    }
    .clr_dropdown {
        position: absolute;
        top: calc(100% + 10px);
        z-index: 1060;
        width: 285px;
        right: 20%;
        background-color: #fff;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 9px 26px rgba($color: #000000, $alpha: 0.08);
        @media (max-width: 767px) {
            left: 0;
            right: unset;
        }
    }
    .selected_clr {
        width: 100%;
        font-size: 20px;
        padding: 30px 0;
        text-align: center;
    }
    .clr_options {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px 0 20px 10px;
    }
    .selectable_clr {
        width: 36px;
        height: 36px;
        border-radius: 5px;
        margin: 10px 9px 0 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 18px;
        }
    }
    .other_opt {
        padding: 0 10px 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        input {
            width: 100%;
            text-align: center;
        }
        button {
            margin-top: 13px;
            width: 48%;
        }
    }
}

.event-form-group select.form-select {
    appearance: unset !important;
    -webkit-appearance: unset !important;
}

.event-card {
    .name {
        display: flex;
        align-items: center;
        color: #0c4767 !important;
        margin-bottom: 16px;
        font-weight: 700;
        img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            &.prof_img {
                border: 1px solid #fe9920;
                border-radius: 50%;
            }
        }
    }
    .event_summary {
        border-top: 1px solid $brandDisable;
        margin-top: 30px;
        padding-top: 30px;
        &>p {
            font-size: 14px;
            font-weight: 400 !important;
        }
        .inner {
            margin-top: 8px;
            padding: 10px 15px;
            border: 1px solid $brandDisable;
            border-radius: 5px;
            .name {
                font-size: 14px;
                font-weight: 400;
                &.host {
                    margin-bottom: 20px;
                    color: rgba($color: #626262, $alpha: 0.8) !important;
                    img {
                        width: 28px;
                        height: 28px;
                        max-width: unset;
                    }
                }
                img {
                    width: 100%;
                    max-width: 19px;
                    height: auto;
                }
                .small_head {
                    color: rgba($color: #171725, $alpha: 0.8);
                }
                p {
                    color: #818181;
                    display: flex;
                    align-items: center;
                    margin-top: 8px;
                    font-weight: 400 !important;
                    @media (max-width: 767px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    span {
                        border-right: 1px solid #818181;
                        padding-right: 8px;
                        margin-right: 8px;
                        line-height: 1;
                        &:last-of-type {
                            border-right: none;
                            border-bottom: none;
                        }
                        @media (max-width: 767px) {
                            width: 100%;
                            border-right: 0;
                            border-bottom: 1px solid #818181;
                            padding-right: 0;
                            margin-right: 0;
                            padding-bottom: 8px;
                            margin-bottom: 8px;
                        }
                    }
                }
                // &:last-of-type {
                //     margin-bottom: 0;
                // }
            }
        }
    }
}

.create-event-container .filter_bar .adhoc_btns button {
    min-width: 100px;
}

.adhoc_btns {
    position: unset !important;
}

.adhoc_btns .btn_close {
    position: absolute;
    right: 16px;
    width: max-content;
    height: max-content;
    top: 8px;
}

.octo_clr {
    label {
        margin: 0 15px 0 0;
    }
}

.select_dropdown {
    &>div:first-of-type {
        border-radius: 5px;
        padding: 0px 10px !important;
        color: #818181;
        &>div>div {
            color: #818181;
        }
    }
}

.booking_arrow {
    img {
        max-width: 15px;
    }
    p {
        font-weight: 400 !important;
        color: $brandDark;
    }
    h1 {
        margin-top: 12px;
        text-transform: capitalize;
    }
}

.action_title {
    background-color: $brandLight;
    border: 1px solid $brandDisable;
    border-radius: 5px 5px 0 0;
    padding: 12px 15px;
    h3 {
        color: $brandDark;
        font-weight: 500;
        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }
}

.right_div {
    .action_title {
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start !important;
        }
    }
}

.cus_style {
    border: none !important;
    box-shadow: 0px 3px 6px 0px rgba($color: #8386a3, $alpha: 6%);
}

.react-calendar {
    .react-calendar__tile {
        background: transparent !important;
        display: flex;
        justify-content: center;
        position: relative;
        &.highlight {
            &::after {
                content: "";
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: $brandPrimary;
                left: 0;
                right: 0;
                bottom: 8px;
                margin: auto;
            }
        }
        abbr {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 767px) {
                width: 26px;
                height: 26px;
            }
        }
        &:disabled,
        &.react-calendar__month-view__days__day--neighboringMonth,
        &.rc-disabled-day {
            background-color: transparent;
            pointer-events: none;
            abbr {
                color: rgba($color: #626262, $alpha: 0.3);
            }
        }
        &.react-calendar__tile--active {
            background: transparent;
            abbr {
                border: 1px solid $brandPrimary;
                color: $brandPrimary;
            }
        }
        &.react-calendar__tile--now {
            abbr {
                background-color: $brandPrimary !important;
                color: #fff;
                border-radius: 50%;
                padding: 8px 8px;
            }
        }
    }
}

.time_slots_wrapper {
    overflow-y: scroll;
    max-height: 384px;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba($color: $brandDark, $alpha: 0.2);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: $brandPrimary;
        border-radius: 10px;
    }
}

.confirm_button {
    height: auto !important;
    background: #fff;
    padding: 20px;
    border-radius: 0 0 5px 5px;
    min-height: 94px;
    border-top: 1px solid $brandDisable;
}

.meeting-data {
    border: none !important;
    margin-left: 0 !important;
    .sechulde_s {
        margin-right: 5px !important;
    }
    .event_label {
        font-size: 14px;
        color: rgba($color: $brandDark, $alpha: 0.5);
        margin-right: 10px;
    }
    .sechulde_d {
        color: $brandDark;
        span {
            color: $brandPrimary;
            font-weight: 700;
        }
    }
    @media (max-width: 767px) {
        .col-auto {
            margin-bottom: 12px !important;
        }
    }
}

.event_desc {
    // padding: 0 15px;
    .head {
        padding: 13px 18px;
        border: 1px solid $brandDisable;
        background-color: $brandLight;
    }
    h3 {
        color: $brandDark;
        font-weight: 500;
    }
    .desc {
        background-color: #fff;
        p {
            font-weight: 400 !important;
            color: rgba($color: #626262, $alpha: 0.8);
        }
    }
}

.copy_link {
    input {
        &:disabled {
            background-color: white !important;
        }
    }
    .input-group-append {
        button {
            background-color: $brandLight;
            color: rgba($color: #626262, $alpha: 0.8);
            border-color: $brandDisable;
        }
    }
}

// all booking page
.eve_card {
    .img {
        position: relative;
        min-height: 190px;
        max-height: 190px;
        text-align: center;
        .eve_name {
            position: absolute;
            top: 10px;
            left: 10px;
            background-color: $brandDark;
            color: #fff;
            border-radius: 5px;
            padding: 3px 6px;
            line-height: 1;
            opacity: 0;
            z-index: -1;
            transition: 0.3s all ease;
        }
        .octo_icon1 {
            width: 59%;
        }
        .octo_icon2 {
            width: 75%;
        }
        .octo_icon3 {
            width: 57%;
        }
        img {
            max-height: 190px;
            object-fit: cover;
        }
    }
    .eve_status {
        min-width: 47px;
        background-color: $brandDark;
        color: #fff;
        border-radius: 3px;
        text-align: center;
        font-size: 12px;
        padding: 2px 3px;
    }
    &:hover {
        .eve_name {
            opacity: 1 !important;
            z-index: 0;
            transition: 0.3s all ease !important;
        }
    }
    .body {
        padding: 10px 15px 15px;
        .eve_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        h3 {
            color: $brandDark;
            font-weight: 500;
            @media (max-width: 1799px) {
                white-space: nowrap;
                max-width: 245px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .eve_desc {
        margin: 8px 0 20px;
        p {
            font-weight: 400 !important;
            color: rgba($color: #626262, $alpha: 0.8);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            min-height: 42px;
        }
    }
    .eve_info {
        .format {
            font-size: 14px;
            color: rgba($color: $brandDark, $alpha: 0.5);
            margin-bottom: 10px;
            .sechulde_s {
                margin-right: 8px;
            }
            .sechulde_d {
                margin: 0;
                color: $brandDark;
            }
        }
    }
    .act_btns {
        margin-top: 15px;
        .col-7 {
            max-width: 57%;
        }
        .col-5 {
            max-width: 38%;
        }
        .btn-grey {
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background-color: $brandLight !important;
                color: #626262 !important;
            }
            height: 100%;
            img {
                max-width: 16px;
                margin-right: 12px;
            }
        }
    }
}

// Ad hoc Screen
.create-event-container {
    .filter_bar {
        .btn-app,
        .btn-outline {
            min-width: 146px;
            margin-right: 16px;
        }
    }
    .adhoc_second {
        h2 {
            font-size: 30px;
            text-align: center;
            font-weight: 600;
            color: $brandDark;
            margin-bottom: 40px;
        }
        .view_detail {
            margin-bottom: 10px;
            p {
                font-size: 14px;
                font-weight: 400 !important;
                color: rgba($color: #171725, $alpha: 0.8);
                &:last-of-type {
                    color: rgba($color: #579cfb, $alpha: 1);
                }
            }
        }
    }
}

.jodit-react-container {
    p,
    .jodit-placeholder {
        color: $brandDark;
    }
}

.multiselect-container {
    .chip {
        border-radius: 5px;
        background: $brandDark;
    }
}

.ant-select-multiple {
    .ant-select-selection-item {
        height: 35px !important;
        .ant-select-selection-item-remove {
            margin-top: -2px !important;
        }
    }
    .ant-select-selection-search {
        input {
            padding: 0 !important;
        }
    }
}

.ant-select-tree {
    input[aria-label="for screen reader"] {
        display: none !important;
    }
}

// Availability Calendar Popup
.cal_day_popup {
    .cal_day_popup_wrapper {
        padding: 0 29px;
    }
    .modal-title {
        font-size: 18px;
        font-weight: 400 !important;
    }
    .calendar-wrapper {
        border: 1px solid #dadada;
        border-bottom: 0;
        border-radius: 5px 5px 0 0;
        padding-bottom: 15px;
        .select_date {
            font-size: 14px;
            color: $brandDark;
            border-bottom: 1px solid #dadada;
            padding: 7px 11px;
            font-weight: 400;
            font-family: "Sofia Pro" !important;
        }
    }
    .rdp {
        margin: 0;
    }
    .rdp-caption {
        padding: 15px 0;
        width: 92%;
        margin: auto;
    }
    .rdp-caption_label {
        font-weight: 400;
        color: $brandDark;
        position: absolute;
        width: max-content;
        left: 0;
        right: 0;
        margin: auto;
    }
    .rdp-nav {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .modal-header {
        padding-top: 25px !important;
    }
    .modal-footer {
        padding: 5px 0 30px;
    }
    .rdp-month,
    .rdp-table,
    .rdp-head {
        width: 100%;
        max-width: 100%;
    }
    .rdp-tbody {
        .rdp-cell {
            button {
                width: 100%;
                max-width: 100%;
                background: transparent !important;
                border: none !important;
                span {
                    width: 34px;
                    height: 34px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                &.rdp-day_selected {
                    span {
                        border: 1px solid $brandPrimary;
                        color: $brandPrimary;
                        border-radius: 50%;
                    }
                }
                &.rdp-day_today {
                    span {
                        background: $brandPrimary;
                        color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    .time-interval-wrapper {
        border-top-width: 0;
        padding: 24px 24px;
        border-radius: 0 0 5px 5px;
        .icons {
            span {
                display: inline-block;
                margin-right: 10px;
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
    &.cal_popup2 {
        .time-interval-wrapper {
            border-top-width: 1px;
            border-radius: 5px;
        }
    }
}

.fonticon-container {
    &:hover {
        .cust_svg {
            path,
            line,
            polyline {
                stroke: $brandPrimary;
            }
        }
        .cust_svg.rect {
            rect {
                stroke: $brandPrimary;
            }
        }
    }
}

.op-0 {
    opacity: 0;
}

.big_table {
    table {
        overflow-x: scroll;
        display: block;
        white-space: nowrap;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: $brandPrimary;
            border-radius: 10px;
        }
    }
}

.bookig_staff_sec {
    h3 {
        font-size: 16px;
        font-weight: 500;
        color: $brandDark;
        margin: 16px 0 10px;
    }
    .staff_output {
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid $brandDisable;
        padding: 12px 12px;
        // white-space: nowrap;
        // overflow-x: scroll;
    }
    .staff_pill {
        display: inline-block;
        color: $brandDark;
        text-transform: capitalize;
        border-radius: 5px;
        background-color: rgba($color: $brandDark, $alpha: 0.1);
        margin-right: 8px;
        padding: 3px 8px;
        p {
            margin-right: 10px;
            display: inline-block;
            font-size: 12px;
        }
        button {
            padding: 0 !important;
            display: inline-block;
            width: max-content;
            line-height: 1;
            vertical-align: unset;
        }
        img {
            max-width: 9px;
        }
    }
    .staff_slect {
        margin-top: 15px;
        .head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid $brandDisable;
            border-radius: 5px 5px 0 0;
            padding: 6px 20px;
            h3 {
                margin: 0;
                font-weight: 400;
            }
        }
        .search_inp {
            position: relative;
            width: 100%;
            max-width: 360px;
            img {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 14px;
                margin: auto;
            }
        }
    }
    .staff_select_main {
        background-color: #fff;
        border-radius: 0 0 5px 5px;
        padding: 10px;
        display: flex;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgba($color: $brandDark, $alpha: 0.2);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $brandPrimary;
            border-radius: 10px;
        }
        .slide_inner {
            width: auto;
            min-width: 99px;
            margin-right: 12px;
            &:last-of-type {
                margin-right: 0;
            }
            label {
                border-radius: 5px;
                border: 1px solid #e6ecef;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 10px 8px;
                position: relative;
                p {
                    max-width: 80px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &::before {
                    content: "";
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    border-radius: 50%;
                    border: 2px solid #e6ecef;
                    left: 6px;
                    top: 6px;
                }
                img {
                    max-width: 100%;
                    width: 55px;
                    height: 55px;
                    border-radius: 50%;
                }
                p {
                    font-size: 14px;
                    margin-top: 6px;
                }
            }
            input {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                cursor: pointer;
                opacity: 0;
                z-index: 1;
                &:checked {
                    &+label {
                        border-color: $brandPrimary !important;
                        &::before {
                            border-color: $brandPrimary;
                        }
                        &::after {
                            content: "";
                            background: $brandPrimary;
                            position: absolute;
                            border-radius: 50%;
                            left: 10px;
                            top: 10px;
                            width: 5px;
                            height: 5px;
                        }
                    }
                }
            }
        }
    }
}

.cus_timezone {
    display: flex;
    img {
        max-width: 26px;
        @media (max-width: 767px) {
            max-width: 18px;
        }
    }
    select {
        max-width: 180px !important;
        background: transparent !important;
        border: none !important;
        color: $brandDark !important;
        outline: 0 !important;
        height: auto !important;
        padding: 2px 6px !important;
        min-height: auto;
        @media (max-width: 767px) {
            max-width: 100% !important;
        }
    }
}

@media (max-width: 767px) {
    #s_e_btn {
        padding-top: 20px !important;
    }
    .main_add #sub_head {
        font-size: 19px !important;
    }
}

.left_div {
    width: 22% !important;
    @media (max-width: 1199px) {
        width: 30% !important;
    }
    @media (max-width: 991px) {
        width: 37% !important;
    }
    @media (max-width: 767px) {
        width: 100% !important;
    }
}

.right_div {
    width: 78% !important;
    @media (max-width: 1199px) {
        width: 70% !important;
    }
    @media (max-width: 991px) {
        width: 61% !important;
    }
    @media (max-width: 767px) {
        width: 100% !important;
        margin-top: 30px;
    }
}

.booking_addon {
    background-color: #fff;
    padding: 20px 10px 20px 20px !important;
    margin-top: 5px;
    border-radius: 5px;
    .items_wrapper {
        max-height: 210px;
        overflow-y: scroll;
        padding-right: 15px;
        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgba($color: $brandDark, $alpha: 0.2);
            width: 2px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $brandPrimary;
            border-radius: 10px;
        }
    }
    h3 {
        font-weight: 700;
        color: $brandDark;
    }
    .item_main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
        label {
            margin-bottom: 0;
        }
        .right_cont {
            display: flex;
            align-items: center;
            @media (max-width: 767px) {
                justify-content: flex-end;
                width: 100%;
                margin-top: 10px;
            }
            .price {
                color: $brandPrimary;
                font-weight: 600;
            }
            p {
                margin: 0 10px;
                font-size: 12px;
                font-weight: 400 !important;
                color: $brandDark;
            }
        }
        .number-input-field {
            max-width: 98px;
            span {
                padding: 3.5px 6px;
            }
            input {
                padding: 0 !important;
                height: 30px !important;
                min-height: unset;
                border-radius: 0;
            }
        }
    }
}

// Booking Page elements
.booking_main_row {
    @media (max-width: 767px) {
        flex-direction: column-reverse !important;
        .right_div {
            margin-top: 0 !important;
        }
        .action_title {
            padding: 8px 15px;
            h3 {
                font-size: 16px;
                margin-bottom: 8px;
            }
        }
    }
}

//multi Select
.booking_multi {
    .ant-select-selection-item-content {
        white-space: nowrap;
        overflow-x: hidden;
        max-width: 85px;
    }
}

// Phone Input
.custom_phn_inp {
    input.form-control {
        width: 100%;
        padding: 16px 16px 16px 58px !important;
        border: 1px solid $brandDisable;
    }
    .selected-flag {
        &::before {
            border-radius: 0 !important;
            box-shadow: none !important;
            width: 1px !important;
            height: 29px !important;
            background: $brandDisable !important;
            border: none !important;
            left: unset !important;
            top: 0 !important;
            bottom: 0 !important;
            right: 0;
            margin: auto;
        }
    }
}

.instructions {
    .inst_main {
        margin-bottom: 20px;
    }
    h4 {
        font-size: 16px;
        color: $brandDark;
    }
    p {
        font-size: 14px;
        color: rgba($color: #626262, $alpha: 0.8);
        margin-top: 10px;
    }
}

// Booking Pgae 3rd
.booking_payment_cards {
    border-radius: 0 0 5px 5px;
    .head {
        background: $brandLight;
        border-radius: 5px 5px 0 0;
        border: 1px solid $brandDisable;
        padding: 12px 15px 15px;
        h3 {
            color: $brandDark;
            font-weight: 500 !important;
        }
    }
    .body {
        padding: 6px 20px 25px;
        &>.row {
            margin-top: 24px;
        }
        p {
            color: $brandDark;
            display: flex;
            justify-content: space-between;
            span {
                color: rgba($color: $brandDark, $alpha: 0.5);
            }
        }
        .price {
            p {
                font-weight: 600 !important;
            }
        }
        .total {
            border-top: 1px solid rgba($color: #707070, $alpha: 0.24);
            padding-top: 24px;
            p {
                color: $brandPrimary;
            }
        }
        .coupon_code {
            position: relative;
            button {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                height: max-content;
                margin: auto;
                max-width: 106px;
            }
        }
    }
}

// Booking Page 4th
.confirm-book {
    padding: 35px 25px;
    .book_icon {
        max-width: 46px;
    }
    .eve_detail {
        .col-md-6 {
            @media (min-width: 768px) {
                max-width: 48%;
            }
        }
        h4 {
            font-size: 16px;
            color: $brandDark;
        }
        p {
            font-weight: 400 !important;
            font-size: 14px;
            color: rgba($color: #626262, $alpha: 0.8);
            margin-top: 14px;
            span {
                color: $brandPrimary;
            }
        }
    }
    .emails {
        p {
            border-right: 1.5px solid $brandDisable;
            padding-right: 14px;
            margin-right: 14px;
            &:last-of-type {
                border-right: 0;
                margin-right: 0;
                padding-right: 0;
            }
        }
    }
    .subtitle {
        color: $brandDark;
        font-weight: 500;
        @media (max-width: 767px) {
            font-size: 18px;
        }
    }
}

.side_bar_foot_logo_img {
    img {
        max-width: 180px;
    }
}

.react-date-picker__calendar {
    top: 0 !important;
}

.btn_no_show {
    border-radius: 50px !important;
    max-width: 250px;
    svg {
        margin-right: 10px;
        vertical-align: 0px;
        path {
            fill: $brandDark;
        }
    }
    &:hover {
        svg {
            margin-right: 10px;
            path {
                fill: #fff;
            }
        }
    }
}

.asno_show_modal {
    .modal-body {
        padding: 24px;
        h2 {
            font-size: 30px;
            font-weight: 700;
            color: $brandDark;
            @media (max-width: 767px) {
                font-size: 24px;
            }
        }
        p {
            font-weight: 400 !important;
            font-size: 18px;
            color: $brandDark;
            margin: 15px 0;
        }
        a {
            font-size: 18px;
            text-align: center;
            display: block;
            color: $brandPrimary;
            img {
                max-width: 20px;
            }
        }
    }
    .modal-footer {
        justify-content: space-between;
        padding: 24px;
        button {
            width: 48% !important;
            @media (max-width: 767px) {
                margin-top: 10px;
                width: 100% !important;
            }
        }
    }
}

// Dashboard=
.page_title_dashboard {
    h2,
    h3 {
        font-weight: 700;
        color: $brandDark;
    }
    .dash_filter_bar {
        padding: 0;
        flex: 0 1;
        // margin-right: 24px;
    }
    .slct {
        width: 100%;
        max-width: 182px;
        @media (max-width: 767px) {
            max-width: 100%;
        }
        &>.mb-3 {
            margin-bottom: 0 !important;
        }
    }
    .cus_datepicker {
        max-width: 180px;
        @media (max-width: 350px) {
            max-width: 145px;
        }
    }
}

.que_row {
    label {
        word-break: break-all;
    }
}

.chart_container {
    padding: 12px 10px;
    @media (max-width: 991px) {
        padding: 12px 0;
    }
    .head {
        margin-bottom: 35px;
        @media (max-width: 767px) {
            margin-bottom: 15px;
        }
    }
    .tools {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .labels {
            margin: 0;
            @media (max-width: 992px) {
                & > .col-auto {
                    padding-left: 0 !important;
                }
            }
            @media (max-width: 767px) {
                margin: 5px 0;
            }
            p {
                font-weight: 400 !important;
                color: $brandDark;
                @media (max-width: 991px) {
                    font-size: 12px;
                }
                @media (max-width: 767px) {
                    font-size: 10px;
                }
                span {
                    width: 18px;
                    height: 18px;
                    border-radius: 3px;
                    display: inline-block;
                    vertical-align: -3.5px;
                    margin-right: 8px;
                    @media (max-width: 991px) {
                        width: 14px;
                        height: 14px;
                    }
                    @media (max-width: 767px) {
                        width: 10px;
                        height: 10px;
                        margin-right: 1px;
                        vertical-align: -1.5px;
                    }
                }
            }
            .sch {
                span {
                    background-color: $brandPrimary;
                }
            }
            .com {
                span {
                    background-color: #00c875;
                }
            }
            .nos {
                span {
                    background-color: #9270d5;
                }
            }
            .can {
                span {
                    background-color: #e8515a;
                }
            }
        }
        .chart_type {
            display: flex;
            button {
                padding: 6px 6.5px !important;
                line-height: 1;
                margin-right: 8px;
                @media (max-width: 767px) {
                    padding: 4px 4.5px !important;
                    display: flex;
                }
                &:last-of-type {
                    margin: 0;
                }
                &.active {
                    background-color: $brandPrimary;
                }
                img {
                    max-width: 21px;
                    @media (max-width: 991px) {
                        max-width: 16px;
                    }
                    @media (max-width: 767px) {
                        max-width: 12px;
                    }
                }
            }
        }
    }
}

.react-joyride__tooltip {
    .tour_guide {
        .steps {
            position: absolute;
            top: -31px;
            right: -10px;
            color: $brandDark;
            border: 1px solid $brandDark;
            border-radius: 50px;
            padding: 6px;
            line-height: 1;
        }
        .img {
            position: relative;
            width: 100%;
            max-width: 70px;
            margin-right: 15px;
        }
        p {
            color: #333333;
            font-weight: 400 !important;
            text-align: left;
        }
    }
    button[aria-label="Next"] {
        background: transparent !important;
        border: 1px solid $brandDark !important;
        color: $brandDark !important;
        &:hover {
            background: $brandDark !important;
            color: #fff !important;
        }
    }
    .buttons {
        position: absolute;
        bottom: 15px;
        left: 15px;
        button {
            padding: 8px !important;
            line-height: 1;
        }
    }
}

// MeetOcto Icon
.octoicon_cls1 {
    fill: #fe9920;
}

.octoicon_cls2,
.octoicon_cls5,
.octoicon_cls6 {
    fill: #0c4767;
}

.octoicon_cls3 {
    fill: #a86722;
}

.octoicon_cls4 {
    fill: #fff;
}

.octoicon_cls5 {
    stroke: #0c4767;
    stroke-miterlimit: 10;
    stroke-width: 5px;
}

.octoicon_cls6 {
    font-size: 25.97px;
    font-family: "Sofia Pro", sans-serif !important;
    font-weight: 700;
}

.octoicon_cls7 {
    letter-spacing: 0em;
}

.octoicon_cls8 {
    letter-spacing: 0em;
}

.octoicon_cls9 {
    letter-spacing: -0.01em;
}

.octoicon2_cls1 {
    fill: #fe9920;
}

.octoicon2_cls2,
.octoicon2_cls5 {
    fill: #0c4767;
}

.octoicon2_cls3 {
    fill: #fff;
}

.octoicon2_cls4 {
    fill: #965112;
    stroke: #965112;
    stroke-miterlimit: 10;
    stroke-width: 6px;
}

.octoicon2_cls5 {
    font-size: 27.61px;
    font-family: "Sofia Pro", sans-serif !important;
    font-weight: 700;
}

.octoicon2_cls6 {
    letter-spacing: 0em;
}

.octoicon2_cls7 {
    letter-spacing: -0.01em;
}

.octoicon3_cls1 {
    fill: #fe9920;
}

.octoicon3_cls2,
.octoicon3_cls7 {
    fill: #0c4767;
}

.octoicon3_cls3 {
    fill: #965112;
}

.octoicon3_cls4 {
    fill: #a86722;
}

.octoicon3_cls5 {
    fill: #773701;
}

.octoicon3_cls6 {
    fill: #fff;
}

.octoicon3_cls7 {
    font-size: 30.94px;
    font-family: "Sofia Pro", sans-serif !important;
    font-weight: 700;
}

.octoicon3_cls8 {
    letter-spacing: 0em;
}

.octoicon3_cls9 {
    letter-spacing: -0.01em;
}

.cus_icons {
    &:hover {
        svg {
            line,
            path,
            circle,
            polyline {
                stroke: $brandPrimary;
            }
        }
    }
    &.rect {
        &:hover {
            svg {
                g,
                rect {
                    stroke: $brandPrimary;
                }
            }
        }
    }
    &.line {
        &:hover {
            svg {
                line {
                    stroke: $brandPrimary;
                }
            }
        }
    }
}

.cal_int_popup {
    max-width: 534px !important;
    h2,
    h3 {
        font-weight: 700;
        color: $brandDark;
    }
    .modal-body,
    .modal-footer {
        padding: 20px 30px;
        &>* {
            margin: 0;
        }
    }
    .title {
        margin-bottom: 20px;
        max-width: 380px;
    }
    h3 {
        margin-bottom: 11px;
    }
    h4 {
        font-weight: 18px;
        font-weight: 600;
        color: $brandDark;
        margin-bottom: 20px;
    }
    .event-form-group {
        .event-form-check {
            margin-bottom: 0;
        }
        img {
            max-width: 32px;
            margin-right: 10px;
        }
        label {
            font-weight: 400;
            color: rgba($color: $brandDark, $alpha: 0.6) !important;
        }
        input:not(::checked) {
            label {
                color: rgba($color: $brandDark, $alpha: 0.6);
            }
        }
    }
    .modal-footer {
        background: #fafafa;
        border: none !important;
    }
    &.conflict_popup {
        .title {
            max-width: 100%;
        }
        .event-form-group.wdout_field {
            margin-bottom: 0 !important;
        }
        p {
            color: rgba($color: $brandDark, $alpha: 0.6);
            margin-bottom: 20px;
            font-weight: 400 !important;
        }
        label {
            color: $brandDark !important;
        }
    }
}

// Tooltip Managment
.tooltip_table {
    .ant-table-cell {
        color: rgba($color: $brandDark, $alpha: 0.7);
        &>p,
        &>a {
            color: rgba($color: $brandDark, $alpha: 0.7);
            max-width: 180px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        &>a {
            text-decoration: underline !important;
            font-weight: 500;
        }
        &>img {
            border-radius: 5px;
            min-width: 136px;
            max-width: 136px;
            max-height: 70px;
            object-fit: cover;
        }
    }
}

.modal {
    .tooltip_modal {
        max-width: 627px;
        .modal-title {
            font-size: 26px;
        }
        label {
            font-size: 16px;
            color: $brandDark;
        }
        .img_upload {
            label {
                position: relative;
                width: 100%;
                text-align: center;
                border-radius: 5px;
                border: 2px dashed $brandDisable;
                background-color: #fafafa;
                padding: 36px 8px;
                input {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100% !important;
                    cursor: pointer;
                }
                img {
                    max-width: 46px;
                }
                p {
                    font-weight: 400 !important;
                    color: rgba($color: #626262, $alpha: 0.5);
                    margin-top: 10px;
                    span {
                        color: $brandPrimary;
                    }
                }
            }
        }
        .modal-header,
        .modal-footer {
            padding: 30px 35px !important;
            border: none;
        }
        .modal-body {
            padding: 0 35px;
        }
        .modal-footer {
            justify-content: space-between;
            @media (min-width: 768px) {
                .col-md-6 {
                    max-width: 48%;
                }
            }
        }
    }
}

// Calendar Intregration Screen
section.integreted-calenders {
    .calender-party-details {
        width: calc(100% - 140px);
        align-items: center;
        @media (max-width: 767px) {
            width: 100%;
        }
        .status {
            margin-top: 15px;
            @media (max-width: 767px) {
                margin-top: 8px;
            }
        }
    }
    .cal_integ_other {
        border: 1px solid $brandDisable;
        border-radius: 5px;
        padding-top: 20px;
        .txt {
            h3 {
                color: $brandDark;
                font-weight: 700;
            }
            p {
                color: rgba($color: $brandDark, $alpha: 0.6);
                font-size: 18px;
                margin-top: 15px;
                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
    }
}

.quick_tips_popup {
    max-width: 400px !important;
    .img {
        width: 100%;
        max-width: 80px;
        margin-right: 14px;
    }
    p {
        font-weight: 400 !important;
        color: $brandDark;
    }
}

.rd_menuthingcont {
    .dropdown-item {
        color: $brandDark !important;
        &:hover {
            color: $brandPrimary !important;
        }
    }
}

.rd_sidemenusmall {
    a {
        &::after {
            display: none;
        }
    }
}

.ant-picker {
    width: 100%;
    border-radius: 5px !important;
    .ant-picker-input {
        &>input {
            padding: 0 !important;
            height: 44px !important;
            border: none;
        }
    }
}

.cus_num_field {
    .holder {
        width: auto;
        padding: 0 15px;
        background-color: #f4f4f4;
        border: 1px solid $brandDisable;
        border-radius: 5px 0 0 5px;
        display: flex;
        align-items: center;
        p {
            color: $brandDark;
        }
    }
    .inp {
        width: 100%;
    }
    input {
        border-radius: 0 5px 5px 0 !important;
        border-left: 0;
    }
    .picker_drop {
        position: absolute;
        z-index: 99;
        width: 168px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
        .cols {
            width: 33.33%;
            text-align: center;
            color: $brandDark;
        }
        .header {
            .cols {
                padding: 10px 5px 10px;
            }
        }
        .body {
            .cols {
                height: 194px;
                overflow-y: scroll;
                border-left: 1px solid #ddd;
                padding: 5px 5px 20px;
                &:first-of-type {
                    border-left: 0;
                }
                &::-webkit-scrollbar {
                    width: 5px;
                }
                &::-webkit-scrollbar-thumb {
                    background: $brandPrimary;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(12, 71, 103, 0.2);
                    border-radius: 10px;
                }
                span {
                    display: block;
                    color: rgba($color: $brandDark, $alpha: 0.6);
                    border-radius: 5px;
                    padding: 3px 0;
                    cursor: pointer;
                    &.active {
                        background: $brandDark;
                        color: #fff;
                    }
                }
            }
        }
        .foot {
            padding: 10px;
            border-top: 1px solid #ddd;
            button {
                padding: 2px 8px !important;
                width: auto !important;
                &:first-of-type {
                    color: $brandDark;
                }
            }
        }
    }
}

// Cancel event screen
.cancel_eve {
    @media (min-width: 768px) {
        .col-lg-4 {
            border-right: 1px solid rgba($color: $brandDark, $alpha: 0.2);
        }
    }
    .txt {
        .logo {
            max-width: 150px;
            margin-bottom: 10px;
        }
    }
    .txt,
    .field {
        padding: 25px 25px 0;
        @media (max-width: 1199px) {
            padding: 25px 13px;
        }
    }
    h4 {
        font-weight: 700;
        color: #626262;
        @media (max-width: 991px) {
            font-size: 16px;
        }
    }
    h2 {
        color: $brandDark;
        font-weight: 700;
        margin: 15px 0 25px;
        @media (max-width: 991px) {
            font-size: 22px;
        }
    }
    .info {
        p {
            display: flex;
            align-items: flex-start;
            color: $brandDark;
            font-weight: 400 !important;
            margin-bottom: 12px;
            @media (max-width: 991px) {
                font-size: 14px;
            }
            img {
                margin-right: 10px;
                max-width: 18px;
            }
            span {
                margin-top: -2px;
            }
            &.time_left {
                img {
                    max-width: 15px;
                    margin-left: 1.5px;
                    margin-right: 11.5px;
                }
            }
        }
    }
    .field {
        // max-width: 400px;
        h3 {
            color: $brandDark;
            font-weight: 700;
            margin-bottom: 20px;
        }
        label {
            color: #626262;
            font-size: 14px;
        }
        button {
            max-width: 170px;
        }
    }
    .confrim_wrapper {
        padding: 50px 15px;
        width: 100%;
        max-width: 550px;
        .info {
            padding: 10px 0;
            margin: 25px 0 20px;
            border-top: 1px solid rgba(12, 71, 103, 0.5);
            border-bottom: 1px solid rgba(12, 71, 103, 0.5);
        }
        .eve_name {
            display: flex;
            align-items: center;
            span {
                width: 18px;
                height: 18px;
                background-color: $brandPrimary;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
        .foot {
            .input-group {
                .btn {
                    width: 120px;
                }
                input {
                    width: calc(100% - 120px);
                }
            }
        }
    }
    .switcher {
        font-weight: 700;
        font-size: 18px;
    }
    .btn.troubleshoot {
        margin-top: 80px;
        width: auto;
    }
}

section.event_not_avail {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 152px);
    img {
        max-width: 500px;
    }
    h2 {
        color: $brandDark;
        font-weight: 700;
        margin-top: 22px;
    }
}

.cale_container {
    .nodate_avail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        .txt {
            background-color: #f4f4f4;
            text-align: center;
            border-radius: 10px;
            padding: 10px;
            width: auto;
            p {
                color: $brandPrimary;
            }
            button {
                color: $brandDark;
            }
        }
        .arrow {
            width: max-content;
            &.prev {
                margin-right: 8px;
            }
            &.next {
                margin-left: 8px;
            }
        }
    }
}

// Custom Tooltip
.cust_tooltip {
    background-color: #fff;
    max-width: 265px;
    border-radius: 10px;
    border: 1px solid $brandDisable;
    box-shadow: 0 6px 6px 0 rgba(131 134 163 / 6%);
    &.title_only {
        width: max-content;
    }
    img {
        border-radius: 9px 9px 0 0;
    }
    .body {
        padding: 20px;
        h3 {
            color: $brandDark;
            font-weight: 700;
        }
        p {
            color: rgba($color: $brandDark, $alpha: 0.7);
            font-weight: 400 !important;
            margin: 7px 0;
        }
        a {
            font-weight: 500;
            display: block;
            color: $brandPrimary;
            display: flex;
            align-items: center;
            img {
                max-width: 14px;
                margin-right: 5px;
                border-radius: 0;
                &.article {
                    max-width: 10px;
                    margin-right: 9px;
                }
            }
        }
    }
}

// Calenendar Connection
.modal {
    .cale_con_modal {
        max-width: 900px;
        .modal-body {
            padding: 30px;
            .desc {
                font-size: 15px;
                color: $brandDark;
                margin-top: 10px;
            }
            .descr {
                font-weight: 400 !important;
                color: rgba($color: $brandDark, $alpha: 0.7);
            }
            .msg {
                text-align: center;
                font-weight: 400 !important;
                font-size: 14px;
                a {
                    text-decoration: underline !important;
                }
            }
        }
        .head {
            display: flex;
            align-items: center;
            justify-content: center;
            h3 {
                color: $brandDark;
            }
            .img {
                width: 68px;
                height: 68px;
                padding: 10px;
                border: 1px solid $brandDisable;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            hr {
                width: 90px;
                margin: 0 7px;
                border-color: $brandDisable;
                @media (max-width: 767px) {
                    width: 65px;
                }
            }
        }
        &.exhange_plugin {
            max-width: 406px;
            .head {
                justify-content: flex-start;
                .descr {
                    margin-top: 15px;
                }
                .img {
                    width: 58px;
                    height: 58px;
                }
            }
        }
        &.exchange_mod {
            .desc {
                font-size: 18px;
                max-width: 652px;
                margin: 10px auto 0;
                @media (max-width: 767px) {
                    font-size: 15px;
                }
            }
            .img.ex {
                padding: 13px;
            }
        }
    }
}

// Dashboard responsive
.dashboard_pie_chart {
    display: flex;
    flex-direction: column;
    .rd_vacationfilterpart {
        height: 100%;
    }
    @media (max-width: 767px) {
        canvas {
            margin: auto;
        }
    }
    // @media (min-width: 1300px) {
    //     min-height: 595px;
    // }
    // @media (min-width: 1480px) {
    //     min-height: 469.7px;
    // }
}

.mob_sett {
    border-right: 1px solid rgb(244, 244, 244);
    padding-right: 0px;
    margin-right: 10px;
    @media (max-width: 992px) {
        padding-right: 6px;
    }
}

.mob_bottom_bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 15px 0px rgba($color: $brandDark, $alpha: 9%);
    z-index: 19;
    padding: 0 15px;
    [class*="bar-link-"] {
        text-align: center;
        a {
            display: block;
            padding: 10px 5px;
        }
        @media (min-width: 768px) {
            svg {
                transform: scale(1.3);
            }
        }
        p {
            font-size: 10px;
            color: $brandDark;
            font-weight: 400 !important;
            margin: 5px 0 0;
            @media (min-width: 768px) {
                font-size: 15px;
            }
        }
        a.active {
            svg {
                path {
                    fill: $brandPrimary;
                }
                rect,
                line {
                    stroke: $brandPrimary;
                }
                &.upgrade {
                    path {
                        fill: none;
                        stroke: $brandPrimary;
                    }
                }
            }
            p {
                color: $brandPrimary;
            }
        }
    }
    .bar-link-drop {
        margin-top: -23px;
        margin-right: -8px;
        .btn {
            background: $brandPrimary !important;
            border: none;
            outline: 0;
            padding: 0 0 !important;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .dropdown-menu {
            width: 265px !important;
            flex-wrap: wrap;
            justify-content: space-between;
            background: transparent;
            border: none;
            box-shadow: none;
            left: -109px !important;
            transform: translate(1px, -30px) !important;
            padding: 0;
            @media (min-width: 768px) {
                width: 285px !important;
                left: -121px !important;
            }
            &.show {
                display: flex;
            }
            &::before {
                content: none;
            }
            a {
                border-radius: 4px;
                padding: 4px 6px;
                background-color: #fff;
                box-shadow: 0px 2px 7px 0px rgba($color: $brandDark, $alpha: 15%);
                font-size: 14px;
                color: $brandDark;
                display: flex;
                align-items: center;
                @media (min-width: 768px) {
                    font-size: 15px;
                }
            }
            .first_item {
                width: 100%;
                text-align: center;
                margin-bottom: 10px;
                a {
                    display: inline-block;
                }
            }
        }
    }
}

.table_paginaton {
    @media (min-width: 768px) {
        margin-top: -58px;
    }
    @media (max-width: 767px) {
        margin-top: 10px;
    }
}

.table_no_pagi {
    margin-top: 20px;
}

.vacation_table {
    @media (max-width: 991px) {
        table {
            tbody {
                td {
                    &:nth-last-of-type(2) {
                        text-align: center;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        .ant-table-content {
            overflow-x: scroll;
        }
        table {
            width: 615px !important;
        }
    }
}

@media (max-width: 767px) {
    .ant-pagination {
        justify-content: center !important;
    }
}

@media (max-width: 991px) {
    .crm_customer_table {
        table {
            width: 745px !important;
        }
    }
    .rd_hourlyavalmodalitem {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .rd_hourlyavalmodalitemblock1 {
        .rd_formdatepicdatash {
            min-width: 126px;
        }
        .time-dropdown {
            margin: 0 15px !important;
        }
    }
}

@media (max-width: 767px) {
    .rd_hourlyavalmodalitemblock1 {
        .time-dropdown {
            select {
                padding: 9px 4px !important;
                font-size: 13px !important;
            }
        }
    }
}

.market_tools {
    @media (max-width: 767px) {
        h3.page_title {
            font-size: 18px;
        }
    }
    .rd_seotoppart1contflitem1,
    .rd_seotoppart1contflitem2 {
        @media (max-width: 991px) {
            flex: unset;
            width: 100%;
            margin: 0;
            padding: 20px;
        }
    }
    .rd_pixelretargetingthning {
        .rd_flexpoarttpixelreta {
            @media (max-width: 991px) {
                flex-wrap: wrap;
                margin-left: 0;
            }
        }
        .rd_flexpoarttpixelretaitem {
            @media (max-width: 991px) {
                flex: unset;
                width: 100%;
                margin-top: 15px;
                &:first-of-type {
                    margin-top: 0;
                }
            }
        }
    }
    .rd_flexpoarttpixelretafull {
        @media (max-width: 991px) {
            margin-left: 0;
        }
        .rd_flexpoarttpixelretaitem {
            flex: auto;
            width: 100%;
        }
    }
}

.octo_link_sec {
    .cus_field {
        .toggle_field {
            label {
                margin-right: 0 !important;
            }
        }
    }
    .octo_clr {
        .d-flex {
            justify-content: space-between;
        }
    }
}

.table_flow_wrapper {
    overflow-x: auto;
}

// Pricing TableMOdal
.pricing_table_modal {
    @media (max-width: 991px) {
        max-width: 100% !important;
        .table_flow_wrapper {
            // white-space: nowrap;
        }
    }
}

.ant-tooltip {
    .ant-tooltip-inner {
        color: $brandDark;
        min-height: 20px;
        padding: 4px 8px;
    }
}

@media (max-width: 767px) {
    .event_urll {
        flex-wrap: nowrap !important;
        .input-group-text {
            width: 140px;
            overflow-x: scroll;
        }
        input {
            width: calc(100% - 140px);
        }
    }
}

// subscription Pricing Table
.pricing-mobile {
    @media (min-width: 768px) {
        display: none;
    }
    .pricing_col {
        border: 2px solid $brandLight;
        text-align: center;
        margin-top: 40px;
        border-radius: 10px;
        &.best {
            border-color: $brandPrimary;
        }
        .head {
            background-color: #fcfcfc;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            .best-plan {
                font-size: 14px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                color: #ffffff;
                background-color: $brandPrimary;
                text-transform: uppercase;
                font-weight: 600;
                padding: 4px 2px;
            }
            h3 {
                font-size: 18px;
                font-weight: 700;
                color: $brandDark;
                margin-top: 20px;
            }
            h2 {
                font-size: 50px;
                color: $brandPrimary;
                span {
                    color: #626262;
                    font-size: 20px;
                }
            }
            .btn {
                max-width: 200px;
                margin: 20px 0;
            }
        }
        ul {
            list-style: none;
            li {
                font-size: 20px;
                font-weight: 500;
                color: $brandDark;
                padding: 18px 8px;
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                text-align: center;
                .plan-tick-icons {
                    width: 20px;
                    height: 20px;
                    margin-left: 10px;
                }
                span {
                    width: calc(100% - 30px);
                    text-align: center;
                    &.full {
                        width: 100%;
                    }
                }
                &:nth-child(odd) {
                    background: #fcfcfc;
                }
                &.feature {
                    background: $brandLight;
                }
            }
        }
    }
}

.rd_croperthing {
    max-width: 440px;
    padding: 0 20px;
    position: relative;
    p {
        position: absolute;
        top: -3px;
        right: 50px;
        @media (max-width: 767px) {
            display: none;
        }
    }
}

.ReactCrop__child-wrapper {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
        // min-height: 312px;
    }
    img {
        max-width: 200px !important;
        @media (max-width: 767px) {
            max-width: 290px !important;
        }
    }
    canvas {
        border: none !important;
    }
}

.text-brandprimary {
    color: $brandDark !important;
}

.reassign-popup .slot {
    font-size: 18px;
    font-weight: 700;
    border: 1px solid $brandDark;
    border-radius: 5px;
    padding: 8px 15px 10px;
    line-height: 1;
    color: $brandDark;
}

.upcming_bar {
    @media (max-width: 767px) {
        flex-direction: column;
        h3 {
            margin-bottom: 15px;
        }
    }
    @media (max-width: 350px) {
        .btn {
            font-size: 12px !important;
            padding: 14px 10px !important;
        }
    }
}

.new-share-modal h2 {
    color: $brandDark;
}

.new-share-modal h3 {
    color: $brandDark;
}

.new-share-modal img {
    width: 8%;
    height: auto;
}

.new-share-modal p {
    color: #b3b3b3;
}

.venue_not_connect {
    color: $brandDark;
    a {
        color: $brandPrimary;
    }
    @media (max-width: 992px) {
        font-size: 14px;
    }
    @media (max-width: 767px) {
        margin-top: 15px;
    }
}

.featuergoto h3 {
    color: $brandDark;
    font-weight: 700;
}

.featuergoto ul {
    padding-left: 16px;
}

.featuergoto li,
.featuergoto p,
.GOTO_mod .text p,
.calender p,
.team P {
    color: #626262;
    margin-bottom: 10px;
    font-weight: 400 !important;
}

.featuergoto li::marker {
    color: $brandPrimary;
}

.cale_con_modal.width_mod {
    max-width: 437px;
}

.cale_con_modal.GOTO_mod .head .img {
    border-radius: 0;
    border: none;
    width: 115px;
}

.cale_con_modal.setlocation_mod .modal-body .desc {
    font-weight: 800;
    font-size: 25px;
}

.set-location li::marker {
    color: $brandPrimary;
}

.location_mod h3 {
    font-weight: 600;
    font-size: 25px;
    color: $brandDark;
}

.set-location ul {
    padding-left: 20px;
}

.set-location li {
    margin-top: 10px;
    font-size: 18px;
    @media (max-width: 767px) {
        font-size: 13px;
    }
}

.calender {
    border: 1px solid $brandDisable;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
}

.subscrip {
    border: 1px solid $brandDisable;
    border-radius: 0 0 8px 8px;
    padding: 10px;
}

.zapier-head p {
    font-size: 16px;
}

.tittle {
    background-color: $brandDisable;
    padding: 10px;
    border-radius: 8px 8px 0 0;
}

.tittle h3 {
    font-weight: 700;
    font-size: 25px;
}

.zoom-info span {
    color: $brandDark;
}

.meet-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 1px solid $brandDisable;
    padding: 20px;
    img {
        width: 75px;
    }
    .zoom-icon img {
        width: 50px;
    }
}

.zoom-feature-int {
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 6px 6px 0 var(--main-shadow-color);
    padding: 20px;
}

.back-btn {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}

.zoom-info ul {
    padding-left: 25px;
}

.zoom-info li {
    margin-bottom: 10px;
}

.zoom-feature-int h3 {
    font-weight: 600;
    color: $brandDark;
}

.meet-info.goto-meet {
    border: none;
    background-color: rgba($color: $brandPrimary, $alpha: 0.08);
}

.goto-meet img {
    width: 68px;
    height: 68px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    background-color: #fff;
}

@media (max-width: 991px) {
    .team P,
    .calender p {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .exchangemail h3 {
        font-size: 15px;
    }
    .upgrade-professional h3 {
        font-size: 15px;
    }
}

.modal .cale_con_modal .head .exchange-icon {
    padding: 0;
    border: none;
    max-width: 68px;
}

.modal-dialog.upgrade-prof {
    max-width: 520px;
}

.upgrade-professional h3 {
    font-weight: 600;
    color: $brandDark;
}

.upgrade-professional ul {
    padding-left: 20px;
}

.upgrade-professional li {
    padding: 10px;
}

// All Integrations Screen
.integ-banner {
    background: linear-gradient(to right, #0a3d58, #0a2737);
    padding: 25px 70px 25px 30px;
    border-radius: 5px;
    margin-bottom: 30px;
    @media (max-width: 991px) {
        padding: 25px 30px;
    }
    @media (max-width: 767px) {
        padding: 25px 20px 30px;
    }
    h1 {
        color: #fff;
        font-size: 36px;
        @media (max-width: 1199px) {
            font-size: 27px;
        }
        @media (max-width: 991px) {
            font-size: 30px;
        }
        @media (max-width: 767px) {
            font-size: 24px;
            margin-bottom: 30px;
        }
    }
}

.int-sidebar {
    background: #fff;
    border-radius: 5px;
    height: 100%;
    padding: 15px 15px;
    button,
    a {
        margin-bottom: 10px;
        display: block;
        text-align: left;
        color: $brandDark;
        font-weight: 600;
        font-size: 16px;
        padding: 0 !important;
        &.active {
            color: $brandPrimary;
        }
    }
}

.int-content {
    h2 {
        color: $brandDark;
        font-weight: 700;
    }
    .int-col {
        background-color: #fff;
        border-radius: 5px;
        margin-top: 20px;
        padding: 12px 15px;
        display: flex;
        align-items: center;
        min-height: 160px;
        .img {
            width: 18%;
        }
        .text {
            width: 82%;
            padding-left: 12px;
        }
        h3 {
            font-size: 16px;
            font-weight: 600;
            color: $brandDark;
            margin-bottom: 4px;
        }
        p {
            font-size: 13px;
            line-height: 1.3;
            font-weight: 400 !important;
        }
    }
    @media (min-width: 1440px) {
        .int-col {
            min-height: 145px;
        }
        .int-col p {
            font-size: 12px;
        }
    }
    @media (max-width: 1199px) {
        .int-col h3 {
            font-size: 14px !important;
        }
        .int-col {
            min-height: 120px;
        }
    }
    @media (max-width: 767px) {
        margin-top: 30px;
        .int-col {
            min-height: auto;
        }
    }
}

section.integreted-calenders .integration-set .calender-party-details img {
    max-width: unset;
    width: auto;
    max-height: 45px;
    object-fit: contain;
}

section.integreted-calenders .integration-set .calender-party-details .status {
    margin-top: 10px;
}

section.integreted-calenders .integration-set .calender-party-details .calender-name span {
    color: #626262 !important;
}

.box_content.goto {
    background-color: transparent;
    padding: 0;
}

.box_content.goto .form-switch {
    max-width: 305px;
}

.goto-det {
    margin-bottom: 20px;
}

.goto-det p {
    font-weight: 400 !important;
    color: #b0b0b0;
}

.goto-det a {
    text-decoration: underline !important;
    text-underline-offset: 3.5px;
    color: $brandDark;
}

@media (max-width: 767px) {
    .box_content.goto .form-switch {
        max-width: 280px;
    }
    .goto-det p {
        margin-bottom: 10px;
    }
}

.text-brand {
    color: $brandPrimary;
}

.integ-header {
    display: flex;
    align-items: center;
    .invite-user {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0 10px;
    }
    .invite-btn {
        font-size: 16px !important;
        font-weight: 500 !important;
        border: 1px solid #fe9920 !important;
        background-color: #fe9920 !important;
        color: #ffffff !important;
        border-radius: 5px !important;
        padding: 6px 13px !important;
        text-align: center;
        transition: 0.3s all ease;
    }
    .invite-btn .align-item {
        display: flex;
        align-items: center;
    }
    .user-pic img {
        border-radius: 50%;
    }
    .custom-dropdown .btn-secondary {
        border: 0;
        border-color: unset !important;
        background-color: unset !important;
    }
    .custom-dropdown .btn-secondary:hover {
        box-shadow: unset;
    }
    .custom-dropdown button {
        display: flex;
        align-items: center;
    }
    .custom-dropdown .dropdown-menu.show {
        left: -140px !important;
        min-width: 230px;
    }
    .custom-dropdown .dropdown .dropdown-menu::before {
        display: none;
    }
    .custom-dropdown .dropdown-menu .dropdown-item {
        color: #0c4767 !important;
    }
    .scroller {
        overflow-y: auto;
        max-height: calc(100vh - 110px);
    }
    .scroller::-webkit-scrollbar {
        width: 7px;
        min-height: 20px;
    }
    .scroller::-webkit-scrollbar-track {
        margin: 22px 15px 0 0;
        background-color: #fff;
        border-radius: 10px;
    }
    .scroller::-webkit-scrollbar-thumb {
        background-color: #fe9920;
        border-radius: 10px;
    }
    .integ-open-sidebar button {
        background: unset;
        border: 0;
    }
    @media (max-width: 1399px) {
        .custom-dropdown .imageaccountnav {
            width: 50px;
            height: 48px;
        }
    }
    @media (max-width: 767px) {
        .invite-user {
            padding: 0 !important;
        }
    }
}

.integ-sidebar {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    @media (min-width: 992px) {
        width: 25%;
    }
    @media (min-width: 1200px) {
        width: 16%;
    }
    .head {
        padding: 30px 15px 12px;
    }
    .head-logo {
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .head-logo img {
        height: 24px;
        width: auto;
    }
    .side-colappse {
        border: 0;
        background: unset;
    }
    .create-btn a {
        margin-bottom: 0;
        text-align: center;
        color: #fff;
    }
    .body {
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .body.scrole {
        overflow-y: auto;
        height: calc(100vh - 135px);
    }
    .scrole::-webkit-scrollbar {
        width: 7px;
        min-height: 20px;
    }
    .scrole::-webkit-scrollbar-track {
        margin: 22px 15px 0 0;
        background-color: #fff;
        border-radius: 10px;
    }
    .scrole::-webkit-scrollbar-thumb {
        background-color: #fe9920;
        border-radius: 10px;
    }
    a {
        margin-bottom: 20px;
        display: block;
        text-align: left;
        color: #0c4767;
        font-weight: 400;
        font-size: 15px;
        padding: 0 !important;
    }
    a.active {
        color: #ffffff !important;
        background:#fe9920 ;
        padding: 10px !important;
    }
    .create-btn {
        font-size: 16px !important;
        font-weight: 500 !important;
        width: 100%;
        border: 1px solid #fe9920 !important;
        background-color: #fe9920 !important;
        color: #ffffff !important;
        border-radius: 5px !important;
        padding: 6px 13px !important;
        text-align: center;
        transition: 0.3s all ease;
    }
    .upgrading {
        padding: 10px;
        border-radius: 8px;
        background-color: rgba($color: #fe9920, $alpha: 0.2);
        border: 1px solid #fe9920;
        margin-bottom: 15px;
    }
    .upgrading .icon {
        margin-bottom: 8px;
    }
    .upgrading .text h2 {
        font-size: 12px;
    }
    .upgrading .text p {
        font-size: 12px;
    }
    .integ-sidebar-dropdown .btn-secondary {
        border: 0;
        border-color: unset !important;
        background-color: unset !important;
    }
    .integ-sidebar-dropdown .btn-secondary:hover {
        box-shadow: unset;
    }
    .integ-sidebar-dropdown .dropdown-menu .dropdown-item {
        color: #0c4767 !important;
    }
    .side-help-option {
        display: flex;
        align-items: center;
    }
    .side-help-option .dropdown .btn{
        padding: 0 10px !important;
    }
    .side-help-option .dropdown-menu a{
        margin-bottom: 0;
    }
}

.link {
    position: absolute;
    top: 14%;
    right: 12%;
}

@media (max-width: 1199px) {
    .link {
        top: 17%;
        right: 9%;
    }
}

@media (max-width: 767px) {
    .link {
        top: 21%;
        right: 7%;
    }
}

.title {
    margin: 20px 0;
}

.main_time_div{
    .ant-collapse-header{
        background: transparent;
        border-bottom: none !important;
    }
    .ant-collapse-content-box p{
        color: #0C4767;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
    }
    .ant-collapse-content-box p span{
    font-weight: 400;
    }
    .ant-collapse{
        background: transparent;
        border: none;
        flex: 1;
        overflow-y: auto;
        padding: 10px;
        max-height: 440px;
    }
        .ant-collapse::-webkit-scrollbar {
        width: 6px;
    }

        .ant-collapse::-webkit-scrollbar-track {
        background: #ddd;
        border-radius: 4px;
    }

        .ant-collapse::-webkit-scrollbar-thumb {
        background: #FE9920;
        border-radius: 4px;
    }
    .ant-collapse-item{
    border: 1px dotted #0c4767 !important;
    margin-bottom: 20px;
    }
        .ant-collapse-item:last-of-type{
            margin-bottom: 10px;
        }
    .ant-collapse-item:hover{
       border: 1px dotted #FE9920 !important;
    }
    .custom-panel-header .booked-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #0c4767;;
    }
   .ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
      vertical-align: 1px !important;
    }
    .custom-panel-header .details-link {
        display: flex;
        align-items: center;
      font-size: 15px;
      font-weight: 700;
      color: #0C4767;;
    }
    
    .custom-status {
      color: red;
      font-size: 15px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .button-container {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 5px;
    }
    .button-container button {
      padding: 3px 8px;
      font-size: 12px;
      color: #0c4767;
      border:1px dotted #0c4767;
      background: transparent;
      cursor: pointer;
      margin-top: 4px;
    }
    .button-container button:hover{
        border: 1px dotted #FE9920;
    }
    .info-text span{
      font-size: 14px;
      font-weight: 400;
      margin: 10px 0;
    }
    .info-text {
        font-weight: 700;
        font-size: 16px;
        color: #0c4767;;
    }
    .info-text a {
      color: #0C4767;
      font-size: 15px;
      text-decoration: none;
    }
    .ant-collapse-content{
        border-top: unset;
    }
    .booking-time-style{
        padding: 0; 
        padding-left: 0; 
        padding-right: 0; 
        margin-left: 0; 

    }

    .ant-collapse-expand-icon {
        display: none !important;
    }

    .details-link .cust-icon svg {
        color: #000 !important;
    }

    .details-link .cust-icon {
        vertical-align: 1px;
    }

    .ant-collapse-item.ant-collapse-item-active .cust-icon svg {
        transform: rotate(90deg);
    }

        .check-btn{
            position: relative;
            margin-bottom: 20px;
        }
        .check-btn:last-of-type{
            margin-bottom: 0;
        }
         .check-btn input {
             position: absolute;
             left: 0;
             top: 0;
             width: 100%;
             height: 100%;
             opacity: 0;
         }
         .check-btn label {
             background: transparent;
             border: 1px solid #FE9920;
             border-radius: 4px;
             display: block;
             text-align: center;
             padding: 15px 10px;
             font-size: 16px;
             font-weight: 400;
             color: #FE9920 !important;
             cursor: pointer;
         }
    
         /* Add this rule to change the color of text and border when input is checked */
         .check-btn input[type="radio"]:checked+label {
             color: #fff !important;
             background-color: #FE9920;
         }
    

           
}

.debug-btn{
    margin-top: 20px;
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    
}
.debug-btn .btn.btn-app{
 padding: 5px 13px !important;
 border-radius: 20px !important;
}
.debug-btn svg{
    width: 14px;
}

.debug-btn .tooltip-section{
    background: none !important;
    padding: 0 !important;
    margin-top: 15px;
}

.debug-btn .cust_tooltip h3{
    font-size: 16px;
}
.debug-btn .cust_tooltip p {
    font-size: 12px;
}
.debug-btn .cust_tooltip span svg{
   width: 13px;
}





@media (max-width:1199px) {
    .debug-btn .tooltip-section {
        left: -167px;
    }
}

@media (max-width:767px) {
.debug-btn .tooltip-section {
        left: -167px;
    }
}


.top{
    .ant-collapse-expand-icon{
        display: none;
    }
    .ant-collapse-header{
        padding: 0 !important;
        background: #fff;
    }
    .ant-collapse{
        border: 0;
    }
    .ant-collapse-item {
        border-bottom: 0 !important;
    }
    .details-link{
        display: flex;
        align-items: center !important;
    }
    .ant-collapse-content{
        border-top: 0;
    }
    .ant-collapse-item .cust-icon{
        margin-left: 50px;
        margin-bottom: 5px;
    }
    .ant-collapse-item .cust-icon svg{
        transform: rotate(90deg);
    }
    .ant-collapse-content-box{
        padding: 0 !important;
    }
    .custom-panel-content{
        padding-left: 33px;
        padding-top: 15px;
        background: #fff;
    }
    .integration-side-bar a{
        margin-bottom: 0;
    }
}

.time-status{
    margin: 20px 0;
    .tittle{
        background: transparent;
    }
    .tittle h2{
        font-size: 20px;
        font-weight: 700;
        color: #0C4767;
    }
    .select-timing{
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
    .select-timing .form-select{
        height: 54px !important;
    }
    .select-timing .form-control{
        width: 10%;
        
    }
    .select-timing p{
        font-weight: 600;
        font-size: 18px;
        color: #0C4767;
    }
    .add-remider-btn{
        background: transparent !important;
        color: var(--main-dark-blue) !important;
        width: auto;
        border: 0 !important;
    }
    .add-remider-btn:hover{
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
    }
    .status-toggle h2{
        font-size: 20px;
        font-weight: 700;
        color: #0C4767;
        margin-bottom: 10px;
    }
    .sms-box{
        textarea.form-control{
            font-size: 20px !important;
            font-weight: 400;
            color: #0C4767;
            border-radius: 20px !important;
        }
    }
}

.body.cust-main-sidebar{
    padding: 0 15px 20px;
    .itemcomconsub,
    .itemcomcon {
        padding: 10px 10px 10px 60px !important;
    }
    .scrollbar-container{
        overflow: unset !important;
        height: auto;
    }
    .sidemenu {
        box-shadow: unset !important;
        margin: 0 !important;
        padding: 0 !important;
        overflow-y: unset !important;
    }
    .ul-listitemmenu .text-right{
        display: none !important;
    }
    .sidemenu{
        position: unset !important;
        margin-bottom: 10px !important;
    }
}