.help-center {
  padding: 40px 0px;
}
.helps {margin-top: 30px}
.helps h1 {
  font-size: 20px;
  font-weight: bold;
  color: #0c4767;
}
.help {
  margin: 15px 0px;
  padding: 25px;
  border-radius: 10px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  text-align: center;
}
.help img {
  padding-bottom: 25px;
}
.help h1 {
  font-size: 20px;
  color: #0c4767;
}
.help p {
  font-size: 16px;
  color: #626262;
  font-weight: 500;
}
