.social-proof {
  padding: 90px 0px;
}
.proof {
  margin: 15px 0px;
  padding: 40px 25px;
  border-radius: 10px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  text-align: center;
}
.proof .comment{
  padding: 25px 0px;
}
.proof p {
  color: #626262;
}
.proof h1 {
  color: #0c4767;
  font-size: 20px;
  padding-top: 10px;
}
